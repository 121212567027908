import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Settings from '@material-ui/icons/Settings';
import { Switch, FormControlLabel, CircularProgress } from '@material-ui/core';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import { settingsActions } from '../../Actions';
import { connect } from 'react-redux';

const styles = {
  ...extendedFormsStyle,
  selectLabel: {
    fontSize: '14px',
    //textTransform: "uppercase",
    color: '#AAA !important',
    top: '8px',
  },
};

class AmministrazioneSito extends React.Component {
  constructor(props, context) {
    super(props, context);
  }
  componentDidMount = () => {
    this.props.dispatch(settingsActions.getMaintenance(true, this.props.history));
  };

  onChange = async (event, value) => {
    this.props.dispatch(settingsActions.setMaintenance(value, this.props.history));
  };

  render() {
    const { classes, settings, tema } = this.props;
    const { underMaintenance, loading } = settings;
    let cardColor = tema.theme_color;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color={cardColor} icon>
              <CardIcon color={cardColor}>
                <Settings />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Amministrazione Sito</h4>
            </CardHeader>
            <CardBody>
              {loading ? (
                <CircularProgress size={30} />
              ) : (
                  <FormControlLabel
                    style={{ marginTop: '18px' }}
                    control={
                      <Switch
                        color="primary"
                        checked={underMaintenance}
                        onChange={this.onChange}
                      />
                    }
                    classes={{
                      label: classes.selectLabel,
                    }}
                    label={
                      underMaintenance
                        ? 'Disattiva modalità manutenzione'
                        : 'Attiva modalità manutenzione'
                    }
                  />
                )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    ); //chiusura return
  }
}

function mapStateToProps(state) {
  const { settings, tema } = state;
  return {
    settings,
    tema
  };
}

const connectedSettings = connect(mapStateToProps)(
  withStyles(styles)(AmministrazioneSito)
);
export default connectedSettings;
