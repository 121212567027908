import React from 'react';
import { connect } from 'react-redux';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import { searchActions } from '../../../Actions';

const CercaInAmbito = ({ ambito, dispatch }) => {
    const onChange = (event) => {
        const { checked } = event.target;
        dispatch(searchActions.setAmbito(ambito.name, checked));
    };

    const translateAmbito = () => {
        const { name } = ambito;
        if (name === 'Created') return 'I miei documenti';
        else if (name === 'Attentioned') return 'Attenzionati';
        else if (name === 'Rejected') return 'Rifiutati';
        else if (name === 'Signed') return 'Validati';
        else if (name === 'Delegati') return 'Delegati';
        else if (name === 'Incoming') return 'In Arrivo';
        else if (name === 'Deleted') return 'Cancellati';

        return '';
    };

    if (ambito.name !== '')
        return (
            <FormControlLabel
                label={`Cerca in "${translateAmbito()}"`}
                labelPlacement="start"
                style={{ width: '50%', flexDirection: 'row', marginLeft: '3px' }}
                control={
                    <Checkbox
                        color="primary"
                        style={{ padding: '0 5px 0 0' }}
                        checked={ambito.value}
                        onChange={onChange}
                    />
                }
            />
        );
    else return null;
};

const mapStateToProps = (state) => {
    const { ambito } = state.search;
    return { ambito };
};

export default connect(mapStateToProps)(CercaInAmbito);
