//import config from 'config';
import fetchTimeout from 'fetch-timeout';
import { authHeader } from '../Helpers';
import { saveAs } from 'file-saver';
import { utils } from '../Helpers/utils.js';

var wMount;
var wMountSearch;
var wMountWkf;
const wTimeoutInterval = 30000;
const conciliumTimeout = 10000;
let emailConcilium;
let pswConcilium;
let urlServiceConcilium;
if (process.env.NODE_ENV === 'production') {
    wMount = process.env.REACT_APP_SERVICES_URL;
    wMountSearch = process.env.REACT_APP_SERVICES_URL;
    wMountWkf = process.env.REACT_APP_SERVICES_URL;
    emailConcilium = process.env.REACT_APP_CONCILIUM_EMAIL;
    pswConcilium = process.env.REACT_APP_CONCILIUM_PSW;
    urlServiceConcilium = process.env.REACT_APP_CONCILIUM_URL;
} else {
    wMount = process.env.REACT_APP_DOC_URL;
    wMountSearch = process.env.REACT_APP_SEARCH_URL;
    wMountWkf = process.env.REACT_APP_WKF_URL;
    emailConcilium = process.env.REACT_APP_CONCILIUM_EMAIL;
    pswConcilium = process.env.REACT_APP_CONCILIUM_PSW;
    urlServiceConcilium = process.env.REACT_APP_CONCILIUM_URL;
}
export { wMount };
export const documentiService = {
    getAll,
    getDocumentoById,
    getDocForExternal,
    getTaskList,
    save,
    addNota,
    addNotaExt,
    deleteNota,
    deleteById,
    eseguiAzione,
    upload,
    download,
    downloadExt,
    downloadFromQRCode,
    impostaAttenzionato,
    getTaskCount,
    deleteFile,
    search,
    updateMetadata,
    sendSollecito,
    copiaDocumento,
    updateSendProtocollo,
    impostaVerificato,
    getSessionId,
    getMeetings,
    getMeetingDetails,
    inviaOTP,
    verificaOtp,
    eseguiAzioneExt,
    uploadFirmaDigitale,
    updateInsertCoord,
    otpRequest,
    countValidatedDoc
};

function updateInsertCoord(coordinate) {
    let wMethod;
    if (Object.keys(coordinate).length > 1) wMethod = coordinate.id_coordinata ? 'PUT' : 'POST';
    else wMethod = 'DELETE';

    let url = wMount + '/api/v1/documents/gestioneFirma/coordinate';

    const requestOptions = {
        method: wMethod,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ coordinate }),
    };

    return fetch(url, requestOptions).then(handleResponse);
}

function uploadFirmaDigitale(documento, hash_code) {
    let wMethod = 'POST';
    let url = wMountWkf + '/api/v1/workflow/upload/';

    let data = new FormData();
    data.append('fileToUpload', documento.file);
    data.append('documento', JSON.stringify(documento.documento));
    data.append('hash', hash_code);

    const requestOptions = {
        method: wMethod,
        headers: { ...authHeader() },
        body: data,
    };

    return fetch(url, requestOptions).then(handleResponse);
}

function inviaOTP(hash_code) {
    let url = wMountWkf + '/api/v1/workflow/validazione/otp';
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ hash_code }),
    };
    return fetch(url, requestOptions).then(handleResponse);
}

function verificaOtp(otp) {
    let url = wMountWkf + '/api/v1/workflow/validazione/otp/' + otp;

    const requestOptions = {
        method: 'GET',
    };
    return fetch(url, requestOptions).then(handleResponse);
}

function otpRequest(credentials) {
    let url = wMountWkf + '/api/v1/workflow/richiesta/otp';
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(credentials),
    };
    return fetchTimeout(url, requestOptions, wTimeoutInterval, 'Servizio non disponibile (timeout)')
        .then(handleResponse)
        .catch(function (err) {
            const error = err;
            return Promise.reject(error);
        });
}

function getTaskList(idStato, delegante, isVerificati) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    let wUrl = wMount + '/api/v1/documents/dashboard';

    if (idStato && idStato !== 4 && idStato !== 5) wUrl += '?stato=' + idStato;
    else if (idStato === 0) wUrl += '?attenzionati=1&livello=medio';
    else if (idStato === 4) wUrl += '?attenzionati=1&livello=basso';
    else if (idStato === 5) wUrl += '?attenzionati=1&livello=alto';
    //else if (idStato === null) wUrl += '?verificati=' + isVerificati;
    else if (!idStato) wUrl += '?verificati=' + isVerificati; //CAMUSI 23022021

    if (idStato && isVerificati) wUrl += '&verificati=' + isVerificati;

    if (delegante && delegante.id_delegante && delegante.tipo_delega) {
        wUrl += '&id_delegante=' + delegante.id_delegante + '&tipo_delega=' + delegante.tipo_delega;
    }

    return fetchTimeout(wUrl, requestOptions, wTimeoutInterval, 'Servizio non disponibile (timeout)')
        .then(handleResponse)
        .catch(function (err) {
            const error = err;
            return Promise.reject(error);
        });
}

function getTaskCount(idStatoFlusso) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    let wUrl = wMount + '/api/v1/documents/op/count?stato=' + idStatoFlusso;

    //Attenzionati
    if (idStatoFlusso === 0) {
        wUrl = wMount + '/api/v1/documents/op/count?attenzionati=true';
    }

    //In Arrivo
    if (idStatoFlusso === 3) {
        wUrl = wMount + '/api/v1/documents/op/count?in_arrivo=true';
    }

    return fetchTimeout(wUrl, requestOptions, wTimeoutInterval, 'Servizio non disponibile (timeout)')
        .then(handleResponse)
        .catch(function (err) {
            const error = err;
            return Promise.reject(error);
        });
}

function countValidatedDoc() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    let wUrl = wMount + '/api/v1/documents/dashboard/countValidatedDoc';

    return fetchTimeout(wUrl, requestOptions, wTimeoutInterval, 'Servizio non disponibile (timeout)')
        .then(handleResponse)
        .catch(function (err) {
            const error = err;
            return Promise.reject(error);
        });
}

function search(filters, idDelegante, ruoli) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ filters, ruoli }),
    };

    localStorage.setItem('filters', JSON.stringify(filters));
    let url = wMountSearch + '/api/v1/search';
    if (idDelegante) {
        url += '?id_delegante=' + idDelegante;
    }

    return fetchTimeout(url, requestOptions, wTimeoutInterval, 'Servizio non disponibile (timeout)')
        .then(handleResponse)
        .catch(function (err) {
            const error = err;
            return Promise.reject(error);
        });
}

function getAll(filter, idDelegante, ruoli) {
    let wFilter = JSON.parse(localStorage.getItem('filters'));

    if (typeof filter === 'undefined' && wFilter === null) {
        let filters = {
            query: {
                must: [],
                range: {},
            },
            ambito: '',
            from: 0,
            size: 100,
            sort: [
                {
                    data_ult_modif: { order: 'desc' },
                },
            ],
        };

        wFilter = filters;
    } else if (typeof filter !== 'undefined') {
        wFilter = filter;
    }

    return this.search(wFilter, idDelegante, ruoli);
}

function getDocumentoById(idDocumento, idDelegante) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        data: { id: idDocumento },
    };

    let url = wMount + '/api/v1/documents/' + idDocumento;
    if (idDelegante) {
        url += '?id_delegante=' + idDelegante;
    }
    //return fetch(`http://localhost:4000/documenti/get`, requestOptions).then(handleResponse);

    return fetch(url, requestOptions).then(handleResponse);
}

function getDocForExternal(inputParams) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(inputParams),
    };

    let url = wMount + '/api/v1/documents/external';

    return fetch(url, requestOptions).then(handleResponse);
}

function deleteFile(uuid, idDoc) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    let url = wMount + '/api/v1/documents/file/' + uuid + '?idDoc=' + idDoc;

    return fetch(url, requestOptions).then(handleResponse);
}

function updateSendProtocollo(uuid, send_protocollo) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ send_protocollo: send_protocollo }),
    };

    let url = wMount + '/api/v1/documents/file/' + uuid;

    return fetch(url, requestOptions).then(handleResponse);
}

function deleteById(idDocumento) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        data: { id: idDocumento },
    };

    let url = wMount + '/api/v1/documents/' + idDocumento;

    return fetch(url, requestOptions).then(handleResponse);
}

function deleteNota(nota) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    let url = wMount + '/api/v1/documents/' + nota.id_documento + '/note/' + nota.id_nota;

    return fetch(url, requestOptions).then(handleResponse);
}

function upload(documento) {
    let wMethod = 'POST';
    let url = wMount + '/api/v1/documents/upload/' + documento.id;

    let data = new FormData();
    data.append('documento', documento.file);

    const requestOptions = {
        method: wMethod,
        headers: { ...authHeader() },
        body: data,
    };

    return fetch(url, requestOptions).then(handleResponse);
}

function download(idDocumento, documento) {
    let wMethod = 'GET';
    let url = wMount + '/api/v1/documents/download/' + idDocumento;
    const requestOptions = {
        method: wMethod,
        headers: { ...authHeader() },
        responseType: 'blob',
    };
    return fetch(url, requestOptions).then((response) => handleDownload(response, documento.file_name));
}

function downloadExt(hash_code, uuid) {
    let wMethod = 'GET';
    let url = wMount + '/api/v1/documents/download/ext/file?hash_code=' + hash_code + '&uuid=' + uuid;

    const requestOptions = {
        method: wMethod,
        headers: { 'Content-Type': 'application/json' },
        responseType: 'blob',
    };

    return fetch(url, requestOptions).then((response) => handleDownload(response, uuid));
}

async function downloadFromQRCode(idDocumento, username, password) {
    let wMethod = 'POST';
    let url = wMount + '/api/v1/qrcode/download';

    const requestOptions = {
        method: wMethod,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ idDocumento, username, password }),
        responseType: 'blob',
    };

    return fetch(url, requestOptions).then((response) => handleDownload(response));
}

async function handleDownload(response, fileName) {
    return response.blob().then(async (blob) => {
        if (blob.type === 'application/json') {
            let err = JSON.parse(await blob.text());
            return Promise.reject(err.Result.Message);
        } else {
            const data = blob;
            saveAs(new Blob([data], { type: 'application/pdf' }), fileName);
        }
    });
}

function save(documento, coordinate) {
    let wMethod = 'POST';
    let url = wMount + '/api/v1/documents/';
    if (typeof documento.id_documento !== 'undefined') {
        wMethod = 'PUT';
        url += documento.id_documento;
    }

    const requestOptions = {
        method: wMethod,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ documento, coordinate }),
    };

    return fetch(url, requestOptions).then(handleResponse);
}

function updateMetadata(idDocumento, metadata) {
    let wMethod = 'PUT';
    let url = wMount + '/api/v1/documents/metadata/' + idDocumento;

    const requestOptions = {
        method: wMethod,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(metadata),
    };

    return fetch(url, requestOptions).then(handleResponse);
}

function sendSollecito(id_documento, id_utente, id_uo, messaggio, id_contatto) {
    let wMethod = 'POST';
    let url = wMount + '/api/v1/documents/sollecito/' + id_documento;
    let body = {};
    body['id_utente'] = id_utente;
    body['id_uo'] = id_uo;
    body['messaggio'] = messaggio;
    body['id_contatto'] = id_contatto;

    const requestOptions = {
        method: wMethod,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    };

    return fetch(url, requestOptions).then(handleResponse);
}

function copiaDocumento(id_documento) {
    let wMethod = 'POST';
    let url = wMount + '/api/v1/documents/op/copy/' + id_documento;

    const requestOptions = {
        method: wMethod,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(url, requestOptions).then(handleResponse);
}

function eseguiAzione(azione) {
    let wMethod = 'POST';
    let wUrl = wMountWkf + '/api/v1/workflow/eseguiAzione';

    const requestOptions = {
        method: wMethod,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(azione),
    };

    return fetchTimeout(wUrl, requestOptions, wTimeoutInterval, 'Servizio non disponibile (timeout)')
        .then(handleResponse)
        .catch(function (err) {
            const error = err;
            return Promise.reject(error);
        });
}

function eseguiAzioneExt(file, hash_code) {
    let wMethod = 'POST';
    let wUrl = wMountWkf + '/api/v1/workflow/ext/eseguiAzione';
    file.hash = hash_code;
    const requestOptions = {
        method: wMethod,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(file),
    };

    return fetchTimeout(wUrl, requestOptions, wTimeoutInterval, 'Servizio non disponibile (timeout)')
        .then(handleResponse)
        .catch(function (err) {
            const error = err;
            return Promise.reject(error);
        });
}

function addNota(nota) {
    let wMethod = 'POST';

    let id_documento = nota.id_documento;
    let wUrl = wMount + '/api/v1/documents/' + id_documento + '/note';

    nota['utente_ult_modif'] = 'demo';

    const requestOptions = {
        method: wMethod,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(nota),
    };

    return fetchTimeout(wUrl, requestOptions, wTimeoutInterval, 'Servizio non disponibile (timeout)')
        .then(handleResponse)
        .catch(function (err) {
            const error = err;
            return Promise.reject(error);
        });
}

function addNotaExt(nota) {
    let wMethod = 'POST';

    let id_documento = nota.id_documento;
    let wUrl = wMount + '/api/v1/documents/' + id_documento + '/ext/note';

    nota['utente_ult_modif'] = 'demo';

    const requestOptions = {
        method: wMethod,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(nota),
    };

    return fetchTimeout(wUrl, requestOptions, wTimeoutInterval, 'Servizio non disponibile (timeout)')
        .then(handleResponse)
        .catch(function (err) {
            const error = err;
            return Promise.reject(error);
        });
}

function impostaAttenzionato(documento) {
    let wMethod = 'POST';
    let url = wMount + '/api/v1/documents/' + documento.id + '/attenzionato';

    if (documento.livello === 'medio' || documento.livello === 'alto') {
        wMethod = 'PUT';
    }

    if (!documento.attenzionato) {
        wMethod = 'DELETE';
    }

    const requestOptions = {
        method: wMethod,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(documento),
    };

    return fetch(url, requestOptions).then(handleResponse);
}

function impostaVerificato(documento) {
    let wMethod = 'POST';
    let url = wMount + '/api/v1/documents/' + documento.id_documento + '/verifica';

    if (documento.stato && documento.stato === 'N') {
        wMethod = 'PUT';
    }

    if (!documento.verificato) {
        wMethod = 'DELETE';
    }

    const requestOptions = {
        method: wMethod,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(documento),
    };

    return fetch(url, requestOptions).then(handleResponse);
}

function getSessionId() {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({ email: emailConcilium, password: pswConcilium }),
    };
    let url = urlServiceConcilium + '/it/cws/auth/login/';
    return fetchTimeout(url, requestOptions, conciliumTimeout, 'Servizio non disponibile (timeout)')
        .then(handleConciliumResponse)
        .catch(function (err) {
            const error = err;
            return Promise.reject(error);
        });
}

function getMeetings(sessionId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    let url = urlServiceConcilium + '/it/cws/meeting/list?session_id=' + sessionId;
    return fetch(url, requestOptions).then(handleConciliumResponse);
}

function getMeetingDetails(sessionId, idRiunione) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    if (sessionId) {
        let url = urlServiceConcilium + '/it/cws/meeting/get?session_id=' + sessionId + '&id=' + idRiunione;

        return fetch(url, requestOptions).then(handleConciliumResponse);
    } else {
        return this.getSessionId()
            .then((sessionIdNew) => {
                let url =
                    urlServiceConcilium +
                    '/it/cws/meeting/get?session_id=' +
                    sessionIdNew.session.session_id +
                    '&id=' +
                    idRiunione;

                return fetch(url, requestOptions).then(handleConciliumResponse);
            })
            .catch(function (err) {
                const error = err;
                return Promise.reject(error);
            });
    }
}

function handleResponse(response) {
    return response.text().then((text) => {
        var data;
        if (text && utils.isJson(text)) data = JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                return Promise.reject('Operazione non autorizzata, riaccedere al sistema');
            } else {
                return Promise.reject('Sistema momentaneamente non disponibile');
            }
        } else {
            if (data.Result.Code !== 'E100') {
                //if (data.Result.Code === "E110") {
                //    history.push('/login');
                //}
                const error = data?.Result?.Message;
                return Promise.reject(error);
            } else if (typeof data.Data.errors !== 'undefined' && data.Data.errors.length !== 0) {
                let error = '';
                for (var i = 0; i < data.Data.errors.length; i++) {
                    error = data.Data.errors[i].error + '(' + data.Data.errors[i].id_documento + ')\n';
                }
                return Promise.reject(error);
            }
        }

        return data.Data;
    });
}

function handleConciliumResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            let error;
            if (response.status === 401) {
                error = 'Autorizzazione negata, verificare le credenziali impostate';
            } else {
                error = data && data.error ? data.error : response.statusText;
            }
            return Promise.reject(error);
        }
        return data;
    });
}
