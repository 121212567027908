import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import SelectSearch from 'Custom/SelectSearch.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { utilsActions, userActions } from '../../Actions';

const styles = () => ({
    ...extendedFormsStyle,
    selectLabel: {
        fontSize: '14px',
        color: '#AAA !important',
        top: '8px',
    },
});

class SelectUtenti extends React.Component {
    state = {
        id_utente: '',
        id_current_uo: undefined,
    };

    componentDidUpdate() {
        const { id_unita_organizzativa, disabled, utils } = this.props;
        const { id_current_uo } = this.state;

        if (!disabled && !utils.loading && id_current_uo !== id_unita_organizzativa) {
            this.setState({ id_current_uo: id_unita_organizzativa });
            this.props.dispatch(utilsActions.getUtentiByUnitaOrganizzativa(id_unita_organizzativa));
        }
    }

    handleSelect = (event) => {
        const { value, label } = event.target;
        this.setState({ id_utente: value });
        if (value && value !== '') {
            this.props.dispatch(userActions.getUtenteById(value));
        }

        this.props.onChange({
            id_utente: value,
            nome_utente: label,
        });
    };

    render() {
        const { classes, utils, id_unita_organizzativa, users } = this.props;

        let optionUtenti = [];
        if (utils.utenti !== undefined && id_unita_organizzativa !== undefined) {
            optionUtenti = utils.utenti.map((prop) => {
                if (prop.dismessa === false) {
                    return {
                        value: prop.id_utente,
                        label: prop.utente,
                    };
                } else return undefined;
            });
        } else {
            if (users.items !== undefined) {
                optionUtenti = users.items.map((prop) => {
                    return {
                        value: prop.id_utente,
                        label: prop.utente,
                    };
                });
            }
        }

        let wSelectedUtenti =
            this.state.id_utente !== ''
                ? this.state.id_utente
                : this.props.id_utente !== ''
                ? this.props.id_utente
                : undefined;

        if (this.props.init === true) {
            wSelectedUtenti = '';
        }

        return (
            <form className={classes.root} autoComplete="off">
                <FormControl fullWidth disabled={this.props.disabled} className={classes.selectFormControl}>
                    <SelectSearch
                        label={'Utente'}
                        placeholder={"Selezionare l'utente"}
                        options={optionUtenti}
                        isClearable={true}
                        id={'id_utente'}
                        isSearchable={true}
                        value={wSelectedUtenti}
                        onChange={this.handleSelect}
                    />
                </FormControl>
            </form>
        );
    }
}

SelectUtenti.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { utils, users } = state;
    return {
        utils,
        users,
    };
}

const connectedSelectUnitaOrganizzative = connect(mapStateToProps)(withStyles(styles)(SelectUtenti));
export default connectedSelectUnitaOrganizzative;
