import { searchConstants } from '../Constants';

const initialState = {
    error: undefined,
    trovati: undefined,
    showRisultati: false,
    pagination: { pageIndex: 0, size: 10 },
    ambito: { name: '', value: false },
    sort: [{ data_creazione: { order: 'desc' } }],
    tipoRicerca: 'or',
    filters: {},
    risultati: [],
    aggregati: [],
    fetching: false,
};

export function search(state = initialState, action) {
    const { name, value, pageIndex, size, risultati, trovati, aggregati, tipoRicerca, sort, error } = action;
    const { filters, ambito, pagination } = state;

    switch (action.type) {
        case searchConstants.SET_SHOW_RISULTATI:
            return { ...state, showRisultati: value };
        case searchConstants.SET_AMBITO:
            return { ...state, ambito: { name, value } };
        case searchConstants.SET_FILTER:
            return { ...state, filters: { ...filters, [name]: value } };
        case searchConstants.SET_SORTING:
            return { ...state, sort: [{ [sort.id]: sort.desc ? 'desc' : 'asc' }] };
        case searchConstants.SET_TIPO_RICERCA:
            return { ...state, tipoRicerca };
        case searchConstants.SET_PAGINATION:
            return {
                ...state,
                pagination: { pageIndex: pageIndex ?? pagination.pageIndex, size: size ?? pagination.size },
            };
        case searchConstants.SEARCH_REQUEST:
            return { ...state, fetching: true, error: undefined };
        case searchConstants.SEARCH_SUCCESS:
            if (Object.keys(aggregati).length > 0) return { ...state, fetching: false, risultati, trovati, aggregati };
            else return { ...state, fetching: false, risultati, trovati };
        case searchConstants.SEARCH_FAILURE:
            return { ...state, fetching: false, error };
        case searchConstants.RESET_PAGE_INDEX:
            return { ...state, pagination: { pageIndex: 0, size: state.pagination.size } };
        case searchConstants.RESET_RAGGRUPPAMENTI:
            const newFilters = {};
            for (const [key, value] of Object.entries(filters)) {
                if (!Array.isArray(value) || key === 'id_soggetto_originatore' || key === 'id_tipo_documento') newFilters[key] = value;
            }
            return { ...state, filters: newFilters };
        case searchConstants.RESET_SEARCH:
            return { ...initialState, ambito: { name: ambito.name, value: false }, showRisultati: false };
        default:
            return state;
    }
}
