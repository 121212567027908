import React from "react";
import ReactTable from "react-table";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import swal from "@sweetalert/with-react";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

// @material-ui/core
import { Tooltip, withStyles, Button as CoreButton, Slide } from "@material-ui/core";

// @material-ui/icons
import { Search, FolderOpen, FileCopy, AccountBalance, InfoOutlined } from "@material-ui/icons";
import Document from "@material-ui/icons/InsertDriveFile";
import Delete from "@material-ui/icons/DeleteForever";
import Play from "@material-ui/icons/PlayCircleOutline";
import Euro from "@material-ui/icons/EuroSymbolOutlined";
import File from "@material-ui/icons/ImportContacts";
import Download from "@material-ui/icons/CloudDownload";
import Abort from "@material-ui/icons/Cancel";
import Abort2 from "@material-ui/icons/ThumbDown";
import { FiMoreVertical } from "react-icons/fi";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import { ModelViewHelper, Constants, Authorize } from "Helpers/model-view.js";
import { documentiActions, searchActions } from "../../../Actions";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

const styles = {
    ...extendedFormsStyle,
    btn_confirm: {
        "background-color": "#4caf50 !important",
    },
    tcsign_action: {
        width: "20px",
        minWidth: "20px",
    },
    tcsign_spesa: {
        width: "20px",
        minWidth: "20px",
        cursor: "default",
    },
    actionBar: {
        width: "auto",
        height: "64px",
        padding: "0 8px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        boxShadow: "1px 1px 2px #AAA,-1px -1px 2px #AAA,1px -1px 2px #AAA,-1px 1px 2px #AAA",
        borderRadius: "3px",
        backgroundColor: "white",
    },
};

class TabellaRisultati extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            idUtente: JSON.parse(localStorage.getItem("user")).id_utente,
            alert: undefined,
            sweetAlertTitle: undefined,
            sweetAlertConfirm: undefined,
            showMoreActions: null,
        };

        this.actionsBarRef = React.createRef();
    }

    toggleActions = (index) => {
        const { showMoreActions } = this.state;
        this.setState({
            showMoreActions: index !== showMoreActions ? index : null,
        });
    };

    componentDidMount = () => {
        document.addEventListener("click", this.handleClickOutside);
    };

    componentWillUnmount = () => {
        document.removeEventListener("click", this.handleClickOutside);
    };

    handleClickOutside = (event) => {
        if (
            this.actionsBarRef &&
            !this.actionsBarRef.current?.contains(event.target) &&
            event.target.id !== "moreActionsIcon"
        ) {
            this.setState({
                showMoreActions: null,
            });
        }
    };

    selectRow = (e, index) => {
        this.setState({
            selected: index,
        });
    };

    handleClickOpenFileNote(note_singolo_doc) {
        let elencoNote = "",
            singolaNota = "";
        var lung = note_singolo_doc.length;

        for (var i = 0; i < lung; i++) {
            var dataCreazione = note_singolo_doc[i].data_creazione;
            var dataCreazioneFormattata = moment(dataCreazione).format("DD/MM/YYYY HH:mm");
            singolaNota =
                "Autore: " +
                note_singolo_doc[i].utente +
                "\n Data e ora: " +
                dataCreazioneFormattata +
                "\n Testo: " +
                note_singolo_doc[i].descrizione +
                "\n \n";
            elencoNote = elencoNote + singolaNota;
        }

        swal({
            animation: "slide-from-top",
            title: "Note del documento",
            overflow: "hidden",
            text: elencoNote,
            input: "text",
            type: "textarea",
            style: { display: "block", marginTop: "-100px" },
            height: "100px",
            inputType: "textarea",
            confirmButtonText: "Cool",
            showConfirmButton: false,
            showCancelButton: true,
        });
    }

    handleClickStartProcess = (idDocumento) => {
        this.setState({
            alert: true,
            sweetAlertTitle: "Avviare il percorso di firma?",
            sweetAlertConfirm: () => {
                this.hideAlert();
                let azione = {
                    id_azione: 1,
                    id_documento: idDocumento,
                };
                this.props.dispatch(documentiActions.eseguiAzione(azione));
            },
        });
    };

    handleClickDeleteDocumento = (idDocumento) => {
        this.setState({
            alert: true,
            sweetAlertTitle: "Vuoi cancellare il documento?",
            sweetAlertConfirm: () => {
                this.hideAlert();
                this.props.dispatch(documentiActions.deleteById(idDocumento));
            },
        });
    };

    handleClickCopiaDocumento = (idDocumento) => {
        var _this = this;
        this.setState({
            alert: true,
            sweetAlertTitle: "Vuoi copiare il documento?",
            sweetAlertConfirm: () => {
                this.hideAlert();
                this.props.dispatch(documentiActions.copiaDocumento(idDocumento));

                setTimeout(function () {
                    if (_this.props.documenti?.copyDoc)
                        _this.props.handleClickOpenDocumento(_this.props.documenti.copyDoc);
                }, 2000);
            },
        });
    };

    handleClickActionRefuse = (documento) => {
        this.setState({
            alert: true,
            sweetAlertTitle: "Vuoi annullare il percorso del documento?",
            sweetAlertConfirm: () => {
                this.hideAlert();
                let azione = {
                    id_azione: 6,
                    id_documento: documento.id,
                };
                this.props.dispatch(documentiActions.eseguiAzione(azione));
            },
        });
    };

    hideAlert() {
        this.setState({
            alert: undefined,
            sweetAlertTitle: undefined,
            sweetAlertConfirm: undefined,
        });
    }

    handleFileDownload = (id, documento) => {
        this.props.dispatch(documentiActions.download(id, documento));
    };

    handleClickActionForceRefuse = (documento) => {
        this.setState({
            confirm: (
                <SweetAlert
                    input
                    inputType="textarea"
                    showCancel
                    allowEscape
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Prego inserire una nota per il rifiuto del documento."
                    onConfirm={(e) => {
                        let rifiutaDocumento = {
                            id_azione: 5,
                            id_documento: [documento.id],
                            nota_rifiuto: e,
                        };
                        this.hideConfirm();
                        this.props.resultFuncClass.closeFileViewer();
                        this.props.dispatch(documentiActions.rifiutaDocumento(rifiutaDocumento));
                    }}
                    onCancel={() => this.hideConfirm()}
                    validationMsg="Digitare il commento nel campo di testo prima di confermare."
                    confirmBtnText="Salva"
                    cancelBtnText="Chiudi"
                    confirmBtnCssClass={this.props.classes.btn_confirm}
                    cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
                />
            ),
        });
    };

    hideConfirm = () => {
        this.setState({
            confirm: undefined,
        });
    };

    handlePageChange = (pageIndex) => {
        const { dispatch, operaiRule } = this.props;
        dispatch(searchActions.setPagination(pageIndex, undefined, operaiRule));
    };

    handlePageSizeChange = (pageSize, pageIndex) => {
        const { dispatch, operaiRule } = this.props;
        dispatch(searchActions.setPagination(pageIndex, pageSize, operaiRule));
    };

    handleSortChange = (sortArr) => {
        const { dispatch, operaiRule } = this.props;
        const sort = JSON.parse(JSON.stringify(sortArr[0]));
        if (sort.id === "id") sort.id = "id_documento";
        if (["descrizione", "desc_tipo_documento"].includes(sort.id)) sort.id = `${sort.id}.keyword`;
        dispatch(searchActions.setSorting(sort, operaiRule));
    };

    render() {
        const { selected, idUtente, alert, confirm } = this.state;
        const { sweetAlertTitle, sweetAlertConfirm, showMoreActions } = this.state;
        const { classes, readOnly, trovati, risultati, fetching, pageSize, pageIndex, tema, user } = this.props;
        const { handleClickOpenDocumento, handleClickOpenRaccoglitore, handleClickOpenFile } = this.props;
        const { inBozza, rifiutato, sospeso } = Constants.documento.stato;
        const { ruoli } = user;
        let cardColor = tema.theme_color;
        let operatoreRaiRule = false;

        if (ruoli.filter((rl) => rl.cod_ruolo === "OPERAI").length > 0) operatoreRaiRule = true;

        let columns = [
            {
                Header: "",
                accessor: "spesa_icon",
                maxWidth: 30,
                sortable: false,
                filterable: false,
            },
            {
                Header: "",
                accessor: "note_icon",
                maxWidth: 30,
                sortable: false,
                filterable: false,
            },
            {
                Header: "",
                accessor: "proposta_icon",
                maxWidth: 30,
                sortable: false,
                filterable: false,
            },
            {
                Header: "ID",
                accessor: "id",
                maxWidth: 50,
                filterable: false,
            },
            {
                Header: "Oggetto",
                accessor: "descrizione",
                filterable: false,
            },
            {
                Header: "Origine",
                accessor: "soggetto_originatore",
                maxWidth: 170,
            },
            {
                Header: "Tipologia",
                accessor: "desc_tipo_documento",
                maxWidth: 130,
                filterable: false,
            },
            {
                Header: "Data documento",
                accessor: "data_documento",
                maxWidth: 160,
                filterable: false,
            },
            {
                Header: "Azioni",
                accessor: "actions",
                sortable: false,
                filterable: false,
                maxWidth: 100,
                Cell: (row) => {
                    const { children } = row.value.props;

                    const defaultActions = children.filter((child, index) => index === 1 || index === 2);

                    return (
                        <>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {defaultActions}
                                <Tooltip title="Altre azioni" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                                    <CoreButton
                                        style={{ minWidth: 0, padding: 0 }}
                                        onClick={() => this.toggleActions(row.viewIndex)}
                                    >
                                        <FiMoreVertical id="moreActionsIcon" size="20px" />
                                    </CoreButton>
                                </Tooltip>
                            </div>
                            {showMoreActions === row.viewIndex && (
                                <div
                                    ref={this.actionsBarRef}
                                    style={{ position: "absolute", right: "47.5px", top: "1px" }}
                                >
                                    <Slide
                                        direction="left"
                                        in={showMoreActions === row.viewIndex}
                                        mountOnEnter
                                        unmountOnExit
                                    >
                                        <div className={classes.actionBar}>{children}</div>
                                    </Slide>
                                </div>
                            )}
                        </>
                    );
                },
            },
        ];

        if (!operatoreRaiRule) {
            const lastIndex = columns.length - 1;
            columns.splice(lastIndex, 0, {
                Header: "Stato",
                accessor: "desc_stato_documento",
                maxWidth: 130,
                filterMethod: (filter, row) => {
                    if (filter.value === "all") return true;

                    return row[filter.id] === filter.value;
                },
                Cell: (row) => (
                    <span
                        style={{
                            color: row.value === "Rifiutato" ? "#ff0000" : "rgba(0, 0, 0, 0.87)",
                            fontWeight: row.value === "Rifiutato" ? "bold" : "300",
                            transition: "all .3s ease",
                        }}
                    >
                        {row.value}
                    </span>
                ),
                Filter: ({ filter, onChange }) => (
                    <select
                        onChange={(event) => onChange(event.target.value)}
                        style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBottom: "2px",
                            color: "#555",
                        }}
                        value={filter ? filter.value : "all"}
                    >
                        <option value="all">Tutti</option>
                        <option value="In bozza">In bozza</option>
                        <option value="In lavorazione">In lavorazione</option>
                        <option value="Rifiutato">Rifiutato</option>
                        <option value="Completato">Completato</option>
                        <option value="Cancellato">Cancellato</option>
                    </select>
                ),
            });
            columns.splice(lastIndex, 0, {
                Header: "Data creazione",
                accessor: "data_creazione",
                maxWidth: 200,
            });
        }

        return (
            <GridItem xs={12}>
                {confirm}
                {alert && (
                    <SweetAlert
                        info
                        showCancel
                        confirmBtnText="Ok"
                        confirmBtnCssClass={this.props.classes.btn_confirm}
                        cancelBtnText="Annulla"
                        cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
                        title={sweetAlertTitle}
                        onConfirm={sweetAlertConfirm}
                        onCancel={() => {
                            this.hideAlert();
                        }}
                    ></SweetAlert>
                )}
                <Card>
                    <CardHeader color={cardColor} icon>
                        <CardIcon color={cardColor}>
                            <Document />
                        </CardIcon>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <h4 style={{ color: "#3C4858", fontWeight: 300 }}>Documenti</h4>
                            <p style={{ color: "#3C4858", margin: 0, fontWeight: "bold" }}>
                                {trovati ?? "..."} Risultati trovati
                            </p>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <ReactTable
                            getTrProps={(state, rowInfo) => {
                                if (rowInfo?.row) {
                                    return {
                                        onClick: (e) => this.selectRow(e, rowInfo.index),
                                        style: {
                                            overflow: "hidden",
                                            position: "relative",
                                            background: rowInfo.index === selected ? "#e8ffdf" : null,
                                            border: rowInfo.index === selected ? "1px solid #59ff59" : null,
                                        },
                                    };
                                } else return {};
                            }}
                            data={risultati.map((doc) => {
                                let wReturn = ModelViewHelper.document_ModelToView(doc);
                                const { tag_posizionato_est, tag_posizionato_int, file_name } = wReturn;

                                //checks per attivare il button Avvia Processo
                                let wButtonAvviaDisabled = true;

                                const estANDint = tag_posizionato_est && tag_posizionato_int;
                                const estANDintNULL = tag_posizionato_est && tag_posizionato_int === null;
                                const estNULL = tag_posizionato_est === null;
                                const intORintNULL = tag_posizionato_int || tag_posizionato_int === null;

                                const tagPosizionatiCheck = estANDint || estANDintNULL || (estNULL && intORintNULL);

                                if (
                                    [inBozza, rifiutato, sospeso].includes(doc.id_stato_documento) &&
                                    file_name &&
                                    tagPosizionatiCheck
                                )
                                    wButtonAvviaDisabled = false;

                                const otherPropsBtn = [
                                    {
                                        prop: "spesa_icon",
                                        show: wReturn.impegno_spesa,
                                        title: "Presenza impegno di spesa",
                                        btnColor: "rose",
                                        btnDisabled: false,
                                        onClick: null,
                                        icon: <Euro />,
                                    },
                                    {
                                        prop: "proposta_icon",
                                        show: wReturn.proposta_inviata === "inviata",
                                        title: "Proposta inviata a concilium",
                                        btnColor: "info",
                                        btnDisabled: false,
                                        onClick: null,
                                        icon: <AccountBalance />,
                                    },
                                    {
                                        prop: "note_icon",
                                        show: wReturn?.note?.length > 0,
                                        title: "Note del documento",
                                        btnColor: "success",
                                        btnDisabled: false,
                                        onClick: () => this.handleClickOpenFileNote(wReturn.note),
                                        icon: <InfoOutlined />,
                                    },
                                ];

                                const actionsBtnList = [
                                    {
                                        show: readOnly && Authorize.checkAuthorizeFunc("FNCAFF"),
                                        title: "Avvia il processo di validazione",
                                        btnColor: "info",
                                        btnDisabled: wButtonAvviaDisabled,
                                        onClick: () => this.handleClickStartProcess(wReturn.id),
                                        icon: <Play />,
                                    },
                                    {
                                        show: Authorize.checkAuthorizeFunc("FNCVWD"),
                                        title: "Visualizza il documento",
                                        btnColor: "success",
                                        btnDisabled: file_name ? false : true,
                                        onClick: () => handleClickOpenFile(wReturn),
                                        icon: <File />,
                                    },
                                    {
                                        show: Authorize.checkAuthorizeFunc("FNCDWL"),
                                        title: "Scarica il documento",
                                        btnColor: "rose",
                                        btnDisabled: file_name ? false : true,
                                        onClick: () => this.handleFileDownload(wReturn.uuid, wReturn),
                                        icon: <Download />,
                                    },

                                    {
                                        show: Authorize.checkAuthorizeFunc("FNCDDC"),
                                        title: "Apri la scheda del documento",
                                        btnColor: "success",
                                        btnDisabled: false,
                                        onClick: () => handleClickOpenDocumento(wReturn.id, wReturn.uuid),
                                        icon: <Search />,
                                    },
                                    {
                                        show: readOnly && Authorize.checkAuthorizeFunc("FNCCDC"),
                                        title: "Copia documento",
                                        btnColor: "primary",
                                        btnDisabled: false,
                                        onClick: () => this.handleClickCopiaDocumento(wReturn.id),
                                        icon: <FileCopy />,
                                    },
                                    {
                                        show: Authorize.checkAuthorizeFunc("FNCAPF"),
                                        title: "Apri il perc. firma del documento",
                                        btnColor: "warning",
                                        btnDisabled: false,
                                        onClick: () => handleClickOpenRaccoglitore(wReturn.id_raccoglitore),
                                        icon: <FolderOpen />,
                                    },
                                    {
                                        show:
                                            Authorize.checkAuthorizeFunc("FNCRDC") &&
                                            wReturn.id_stato_documento &&
                                            [4, 9].includes(wReturn.id_stato_documento) &&
                                            wReturn.id_utente_ult_firmatario === idUtente,
                                        title: "Rifiuta il documento",
                                        btnColor: "danger",
                                        btnDisabled: false,
                                        onClick: () => this.handleClickActionForceRefuse(wReturn),
                                        icon: <Abort2 />,
                                    },
                                    {
                                        show: readOnly && Authorize.checkAuthorizeFunc("FNCEPR"),
                                        title: "Annulla processo",
                                        btnColor: "danger",
                                        btnDisabled: doc.id_stato_documento === rifiutato && file_name ? false : true,
                                        onClick: () => this.handleClickActionRefuse(wReturn),
                                        icon: <Abort />,
                                    },
                                    {
                                        show: readOnly && Authorize.checkAuthorizeFunc("FNCEDC"),
                                        title: "Elimina il documento",
                                        btnColor: "danger",
                                        btnDisabled: [inBozza, sospeso].includes(doc.id_stato_documento) ? false : true,
                                        onClick: () => this.handleClickDeleteDocumento(wReturn.id),
                                        icon: <Delete />,
                                    },
                                ];

                                wReturn.actions = (
                                    <div className="actions-center">
                                        {actionsBtnList.map((btn) => {
                                            const { title, show, btnColor, btnDisabled, onClick, icon } = btn;
                                            return (
                                                show && (
                                                    <Tooltip
                                                        key={uuidv4()}
                                                        id="tooltip-top"
                                                        title={title}
                                                        placement="bottom"
                                                        classes={{ tooltip: classes.tooltip }}
                                                    >
                                                        <Button
                                                            justIcon
                                                            round
                                                            simple
                                                            disabled={btnDisabled}
                                                            onClick={onClick}
                                                            color={btnColor}
                                                            className={classes.tcsign_action}
                                                        >
                                                            {icon}
                                                        </Button>
                                                    </Tooltip>
                                                )
                                            );
                                        })}
                                    </div>
                                );

                                {
                                    otherPropsBtn.map((btn) => {
                                        const { title, show, btnColor, btnDisabled, onClick, icon, prop } = btn;
                                        wReturn[prop] = show ? (
                                            <Tooltip
                                                id="tooltip-top"
                                                title={title}
                                                placement="bottom"
                                                classes={{ tooltip: classes.tooltip }}
                                            >
                                                <Button
                                                    justIcon
                                                    round
                                                    simple
                                                    disabled={btnDisabled}
                                                    onClick={onClick}
                                                    color={btnColor}
                                                    className={classes[onClick ? "tcsign_action" : "tcsign_spesa"]}
                                                >
                                                    {icon}
                                                </Button>
                                            </Tooltip>
                                        ) : (
                                            ""
                                        );
                                    });
                                }
                                return wReturn;
                            })}
                            filterable
                            columns={columns}
                            defaultPageSize={pageSize}
                            pages={Math.ceil(trovati / pageSize)}
                            page={pageIndex}
                            showPaginationTop={false}
                            showPaginationBottom
                            loading={fetching}
                            onPageChange={this.handlePageChange}
                            onPageSizeChange={this.handlePageSizeChange}
                            onSortedChange={(sorting) => this.handleSortChange(sorting)}
                            className="-striped -highlight"
                            striped
                            hover
                            manual
                            condensed
                            insertRow
                            deleteRow
                            search
                            previousText="Precedente"
                            nextText="Successiva"
                            loadingText="In caricamento..."
                            noDataText="Nessun documento trovato"
                            pageText="Pagina"
                            ofText="di"
                            rowsText="righe"
                        />
                    </CardBody>
                </Card>
            </GridItem>
        );
    }
}

function mapStateToProps(state) {
    const { documenti, search, tema, authentication } = state;
    const { fetching, trovati, risultati, pagination } = search;
    const { size, pageIndex } = pagination;

    return {
        pageSize: size,
        pageIndex,
        documenti,
        user: authentication?.user ?? {},
        trovati,
        risultati,
        fetching,
        tema,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(TabellaRisultati));
