import React from "react";

import { connect } from "react-redux";

import Button from "components/CustomButtons/Button.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";

import Close from "@material-ui/icons/Close";
import SignDocument from "@material-ui/icons/BorderColor";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import { DialogTitle, Typography } from "@material-ui/core";

import FormValidationHelper from "../../Helpers/form-validation.js";

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

const styles = {
    ...extendedFormsStyle,
    ...regularFormsStyle,
    modalDialogBox: {
        style: {
            height: "300px",
            width: "500px",
            justifyContent: "space-around",
        },
    },
    modalDialogFooter: {
        justifyContent: "center",
    },
    modalDialogButton: {
        marginRight: "12px",
    },
    materialDialog: {
        "& .MaterialDialog-paper": {
            height: "500px",
        },
    },
};

class VistoModalForm extends Dialog {
    constructor(props, context) {
        super(props, context);
    }

    handleClose() {
        this.props.onClose();
    }

    handleSubmit() {
        this.props.onSubmit();
        this.handleClose();
    }

    change(event, stateName, type, stateNameEqualTo, maxValue) {
        const { id, value } = event.target;

        this.setState({ [id]: value });
        switch (type) {
            case "required":
                this.setState({
                    [stateName + "State"]: FormValidationHelper.validateField("required", event.target.value, 1),
                });
                break;
            default:
                break;
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Dialog
                open={this.props.open}
                TransitionComponent={Transition}
                style={{ zIndex: 99999 }}
                keepMounted
                aria-labelledby="notice-modal-slide-title"
                aria-describedby="notice-modal-slide-description"
                PaperProps={styles.modalDialogBox}
            >
                <DialogTitle>
                    <Typography variant="h4" align="center">
                        {" "}
                        Vuoi validare il documento?
                    </Typography>
                </DialogTitle>

                <DialogActions style={styles.modalDialogFooter}>
                    <Button style={styles.modalDialogButton} color="success" onClick={() => this.handleSubmit()}>
                        <SignDocument /> Valida
                    </Button>
                    <Button color="info" onClick={() => this.handleClose()}>
                        <Close /> Chiudi
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    const { alert, authentication, raccoglitori, utils } = state;

    return {
        authentication,
        alert,
        raccoglitori,
        utils,
    };
}

const connectedVistoModalForm = connect(mapStateToProps)(withStyles(styles)(VistoModalForm));
export default connectedVistoModalForm;
