export const utilsConstants = {
    GET_UNITA_ORGANIZZATIVE_REQUEST: 'UTILS_GET_UNITA_ORGANIZZATIVE_REQUEST',
    GET_UNITA_ORGANIZZATIVE_SUCCESS: 'UTILS_GET_UNITA_ORGANIZZATIVE_SUCCESS',
    GET_UNITA_ORGANIZZATIVE_FAILURE: 'UTILS_GET_UNITA_ORGANIZZATIVE_FAILURE',

    GET_TIPO_DOCUMENTO_REQUEST: 'UTILS_GET_TIPO_DOCUMENTO_REQUEST',
    GET_TIPO_DOCUMENTO_SUCCESS: 'UTILS_GET_TIPO_DOCUMENTO_SUCCESS',
    GET_TIPO_DOCUMENTO_FAILURE: 'UTILS_GET_TIPO_DOCUMENTO_FAILURE',

    GET_TIPO_RACCOGLITORE_REQUEST: 'UTILS_GET_TIPO_RACCOGLITORE_REQUEST',
    GET_TIPO_RACCOGLITORE_SUCCESS: 'UTILS_GET_TIPO_RACCOGLITORE_SUCCESS',
    GET_TIPO_RACCOGLITORE_FAILURE: 'UTILS_GET_TIPO_RACCOGLITORE_FAILURE',

    GET_STATO_DOCUMENTO_REQUEST: 'UTILS_GET_STATO_DOCUMENTO_REQUEST',
    GET_STATO_DOCUMENTO_SUCCESS: 'UTILS_GET_STATO_DOCUMENTO_SUCCESS',
    GET_STATO_DOCUMENTO_FAILURE: 'UTILS_GET_STATO_DOCUMENTO_FAILURE',

    GET_UTENTI_BY_UO_REQUEST: 'UTILS_GET_UTENTI_BY_UO_REQUEST',
    GET_UTENTI_BY_UO_SUCCESS: 'UTILS_GET_UTENTI_BY_UO_SUCCESS',
    GET_UTENTI_BY_UO_FAILURE: 'UTILS_GET_UTENTI_BY_UO_FAILURE',

    GET_UTENTI_RUOLO_REQUEST: 'GET_UTENTI_RUOLO_REQUEST',
    GET_UTENTI_RUOLO_SUCCESS: 'GET_UTENTI_RUOLO_SUCCESS',
    GET_UTENTI_RUOLO_FAILURE: 'GET_UTENTI_RUOLO_FAILURE',

    GET_RUOLI_DEFAULT_SUCCESS: 'GET_RUOLI_DEFAULT_SUCCESS',//CAMUSI

    GET_ALL_FUNCTION_REQUEST: 'GET_ALL_FUNCTION_REQUEST',
    GET_ALL_FUNCTION_SUCCESS: 'GET_ALL_FUNCTION_SUCCESS',
    GET_ALL_FUNCTION_FAILURE: 'GET_ALL_FUNCTION_FAILURE',

    SAVE_SIGNER_REQUEST: 'SAVE_SIGNER_REQUEST',
    SAVE_SIGNER_SUCCESS: 'SAVE_SIGNER_SUCCESS',
    SAVE_SIGNER_FAILURE: 'SAVE_SIGNER_FAILURE',

    GET_CONTATTI_REQUEST: 'GET_CONTATTI_REQUEST',
    GET_CONTATTI_SUCCESS: 'GET_CONTATTI_SUCCESS',
    GET_CONTATTI_FAILURE: 'GET_CONTATTI_FAILURE',

    SEL_SAVE_CONTACT: 'SEL_SAVE_CONTACT',
    UNSEL_SAVE_CONTACT: 'UNSEL_SAVE_CONTACT',
    SEL_SINGLE_UO: 'SEL_SINGLE_UO',
    UNSEL_SINGLE_UO: 'UNSEL_SINGLE_UO',
    UNSEL_CONTACTS: 'UNSEL_CONTACTS',

    GET_FILE_REQUEST: 'GET_FILE_REQUEST',
    GET_FILE_SUCCESS: 'GET_FILE_SUCCESS',
    GET_FILE_FAILURE: 'GET_FILE_FAILURE',

    SET_RELOADED_SUCCESS: 'SET_RELOADED_SUCCESS',
    
    GET_SOGGETTI_ORIGINATORI_REQUEST: 'GET_SOGGETTI_ORIGINATORI_REQUEST',
    GET_SOGGETTI_ORIGINATORI_SUCCESS: 'GET_SOGGETTI_ORIGINATORI_SUCCESS',
    GET_SOGGETTI_ORIGINATORI_FAILURE: 'GET_SOGGETTI_ORIGINATORI_FAILURE',
}
