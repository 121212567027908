import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { connect } from 'react-redux';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Collapse from '@material-ui/core/Collapse';
import Person from '@material-ui/icons/Person';
// core components
import HeaderLinks from 'components/Header/HeaderLinks.jsx';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import sidebarStyle from 'assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx';
import { Authorize } from '../../Helpers';

//import avatar from "assets/img/faces/avatar.jpg";

var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
    componentDidMount() {
        if (navigator.platform.indexOf('Win') > -1) {
            ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
                suppressScrollX: true,
                suppressScrollY: false,
            });
        }
    }
    componentWillUnmount() {
        if (navigator.platform.indexOf('Win') > -1) {
            ps.destroy();
        }
    }
    render() {
        const { className, user, headerLinks, links } = this.props;
        return (
            <div className={className} ref="sidebarWrapper">
                {user}
                {headerLinks}
                {links}
            </div>
        );
    }
}

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openAvatar: false,
            miniActive: true,
            utente: '',
        };
        this.activeRoute.bind(this);
    }
    componentDidMount() {
        const { nome, cognome } = this.props.user;
        this.setState({
            utente: nome + ' ' + cognome,
        });
    }
    // verifies if routeName is the one active (in browser input)
    activeRoute(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
    }
    openCollapse(collapse) {
        var st = {};
        st[collapse] = !this.state[collapse];
        this.setState(st);
    }

    getThemeCss(themes, key, type) {
        let result;
        if (themes && themes.length > 0) {
            themes.forEach(el => {
                if (el.chiave === key && el.type_file === type) {
                    result = el.valore;
                }
            });
        }
        return result;
    }

    render() {
        const { user, documenti, tema } = this.props;
        const { classes, color, logo, image, logoText, routes, bgColor, rtlActive } = this.props;
        const { utente } = this.state;

        let logoCustom = tema.tenant_theme ? this.getThemeCss(tema.tenant_theme, "tcsignsidebar_css", "css") : 'logoCustom';
        if (typeof user === 'undefined') {
            this.props.history.push('/login');
            return '';
        }

        const itemText =
            classes.itemText +
            ' ' +
            cx({
                [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
                [classes.itemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.itemTextRTL]: rtlActive,
            });
        const userWrapperClass =
            classes.user +
            ' ' +
            cx({
                [classes.whiteAfter]: bgColor === 'white',
            });
        const caret =
            classes.caret +
            ' ' +
            cx({
                [classes.caretRTL]: rtlActive,
            });

        var SideBarUser = (
            <div className={userWrapperClass}>
                <List className={classes.list}>
                    <ListItem className={classes.item + ' ' + classes.userItem}>
                        <NavLink
                            to={'#'}
                            className={classes.itemLink + ' ' + classes.userCollapseButton}
                            onClick={() => this.openCollapse('openAvatar')}>
                            <ListItemText
                                primary={utente}
                                secondary={
                                    <b
                                        className={
                                            caret +
                                            ' ' +
                                            classes.userCaret +
                                            ' ' +
                                            (this.state.openAvatar ? classes.caretActive : '')
                                        }
                                    />
                                }
                                disableTypography={true}
                                className={itemText + ' ' + classes.userItemText}
                            />
                        </NavLink>
                        <Collapse in={this.state.openAvatar} unmountOnExit>
                            <List className={classes.list + ' ' + classes.collapseList}>
                                {routes.map((prop, key) => {
                                    if (!prop.user) {
                                        return null;
                                    }
                                    const navLinkClasses =
                                        classes.itemLink +
                                        ' ' +
                                        cx({
                                            [' ' + classes[color]]: this.activeRoute(prop.path),
                                        });
                                    const itemText =
                                        classes.itemText +
                                        ' ' +
                                        cx({
                                            [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
                                            [classes.itemTextMiniRTL]:
                                                rtlActive && this.props.miniActive && this.state.miniActive,
                                            [classes.itemTextRTL]: rtlActive,
                                        });
                                    const itemIcon =
                                        classes.itemIcon +
                                        ' ' +
                                        cx({
                                            [classes.itemIconRTL]: rtlActive,
                                        });
                                    return (
                                        <ListItem key={key} className={classes.item}>
                                            <NavLink to={prop.path} className={navLinkClasses}>
                                                <ListItemIcon className={itemIcon}>
                                                    <prop.icon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={prop.name}
                                                    disableTypography={true}
                                                    className={itemText}
                                                />
                                            </NavLink>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Collapse>
                    </ListItem>
                </List>
            </div>
        );
        var links = (
            <List className={classes.list}>
                {routes.map((prop, key) => {
                    if (prop.redirect || prop.user) {
                        return null;
                    }
                    if (prop.codMenu) {
                        if (!Authorize.checkAuthorizeFunc(prop.codMenu)) {
                            return null;
                        }
                    }
                    if (prop.collapse) {
                        const navLinkClasses =
                            classes.itemLink +
                            ' ' +
                            cx({
                                [' ' + classes.collapseActive]: this.activeRoute(prop.path),
                            });
                        const itemText =
                            classes.itemText +
                            ' ' +
                            cx({
                                [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
                                [classes.itemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
                                [classes.itemTextRTL]: rtlActive,
                            });
                        const collapseItemText =
                            classes.collapseItemText +
                            ' ' +
                            cx({
                                [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
                                [classes.collapseItemTextMiniRTL]:
                                    rtlActive && this.props.miniActive && this.state.miniActive,
                                [classes.collapseItemTextRTL]: rtlActive,
                            });
                        const itemIcon =
                            classes.itemIcon +
                            ' ' +
                            cx({
                                [classes.itemIconRTL]: rtlActive,
                            });
                        const caret =
                            classes.caret +
                            ' ' +
                            cx({
                                [classes.caretRTL]: rtlActive,
                            });
                        return (
                            <ListItem key={key} className={classes.item}>
                                <NavLink
                                    to={'#'}
                                    className={navLinkClasses}
                                    onClick={() => this.openCollapse(prop.state)}>
                                    <ListItemIcon className={itemIcon}>
                                        <prop.icon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={prop.name}
                                        secondary={
                                            <b
                                                className={
                                                    caret + ' ' + (this.state[prop.state] ? classes.caretActive : '')
                                                }
                                            />
                                        }
                                        disableTypography={true}
                                        className={itemText}
                                    />
                                </NavLink>
                                <Collapse in={this.state[prop.state]} unmountOnExit>
                                    <List className={classes.list + ' ' + classes.collapseList}>
                                        {prop.views.map((prop, key) => {
                                            if (prop.codMenu) {
                                                if (!Authorize.checkAuthorizeFunc(prop.codMenu)) {
                                                    return null;
                                                }
                                            }

                                            if (prop.redirect) {
                                                return null;
                                            }
                                            const navLinkClasses =
                                                classes.collapseItemLink +
                                                ' ' +
                                                cx({
                                                    [' ' + classes[color]]: this.activeRoute(prop.path),
                                                });
                                            const collapseItemMini =
                                                classes.collapseItemMini +
                                                ' ' +
                                                cx({
                                                    [classes.collapseItemMiniRTL]: rtlActive,
                                                });
                                            return (
                                                <ListItem key={key} className={classes.collapseItem}>
                                                    <NavLink to={prop.path} className={navLinkClasses}>
                                                        <span className={collapseItemMini}>{prop.mini}</span>
                                                        <ListItemText
                                                            primary={prop.name}
                                                            disableTypography={true}
                                                            className={collapseItemText}
                                                        />
                                                    </NavLink>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </Collapse>
                            </ListItem>
                        );
                    }
                    const navLinkClasses =
                        classes.itemLink +
                        ' ' +
                        cx({
                            [' ' + classes[color]]: this.activeRoute(prop.path),
                        });
                    const itemText =
                        classes.itemText +
                        ' ' +
                        cx({
                            [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
                            [classes.itemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
                            [classes.itemTextRTL]: rtlActive,
                        });
                    const itemIcon =
                        classes.itemIcon +
                        ' ' +
                        cx({
                            [classes.itemIconRTL]: rtlActive,
                        });
                    return (
                        <ListItem key={key} className={classes.item}>
                            <NavLink to={prop.path} className={navLinkClasses}>
                                <ListItemIcon className={itemIcon}>
                                    <prop.icon />
                                </ListItemIcon>
                                <ListItemText primary={prop.name} disableTypography={true} className={itemText} />
                            </NavLink>
                        </ListItem>
                    );
                })}
            </List>
        );

        const logoNormal =
            classes.logoNormal +
            ' ' +
            cx({
                [classes.logoNormalSidebarMini]: this.props.miniActive && this.state.miniActive,
                [classes.logoNormalSidebarMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.logoNormalRTL]: rtlActive,
            });
        const logoMini =
            classes.logoMini +
            ' ' +
            cx({
                [classes.logoMiniRTL]: rtlActive,
            });
        const logoClasses = logoCustom + ' ' +
            classes.logo +
            ' ' +
            cx({
                [classes.whiteAfter]: bgColor === 'white',
            });
        var brand = (
            <div className={logoClasses}>
                <a href="https://web.uniroma2.it/" className={logoMini}>
                    <img src={logo} alt="logo" className={classes.img} />
                </a>
                <a href="https://web.uniroma2.it/" className={logoNormal}>
                    {logoText}
                </a>
            </div>
        );
        const drawerPaper =
            'sidebarCustom ' +
            classes.drawerPaper +
            ' ' +
            cx({
                [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
                [classes.drawerPaperRTL]: rtlActive,
            });
        const sidebarWrapper =
            classes.sidebarWrapper +
            ' ' +
            cx({
                [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
                [classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
            });
        return (
            <div ref="mainPanel">
                <Hidden mdUp>
                    <Drawer
                        variant="temporary"
                        anchor={rtlActive ? 'left' : 'right'}
                        open={this.props.open}
                        classes={{
                            paper: drawerPaper + ' ' + classes[bgColor + 'Background'],
                        }}
                        onClose={this.props.handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}>
                        {brand}
                        <SidebarWrapper
                            className={sidebarWrapper}
                            user={SideBarUser}
                            headerLinks={<HeaderLinks rtlActive={rtlActive} />}
                            links={links}
                        />
                        {image !== undefined ? (
                            <div className={classes.background} style={{ backgroundImage: 'url(' + image + ')' }} />
                        ) : null}
                    </Drawer>
                </Hidden>
                <Hidden smDown>
                    <Drawer
                        onMouseOver={() => this.setState({ miniActive: false })}
                        onMouseOut={() => this.setState({ miniActive: true })}
                        anchor={rtlActive ? 'right' : 'left'}
                        variant="permanent"
                        open
                        classes={{
                            paper: drawerPaper + ' ' + classes[bgColor + 'Background'],
                        }}>
                        {brand}
                        <SidebarWrapper className={sidebarWrapper} user={SideBarUser} links={links} />
                        {image !== undefined ? (
                            <div className={classes.background} style={{ backgroundImage: 'url(' + image + ')' }} />
                        ) : null}
                    </Drawer>
                </Hidden>
                <Snackbar
                    icon={Person}
                    place="bl"
                    color="warning"
                    open={documenti.deleganteSelected.id_delegante ? true : false}
                    message={'Stai operando per conto di ' + documenti.deleganteSelected.nome_delegante}
                />
            </div>
        );
    }
}

Sidebar.defaultProps = {
    bgColor: 'blue',
};

Sidebar.propTypes = {
    classes: PropTypes.object.isRequired,
    bgColor: PropTypes.oneOf(['white', 'black', 'blue']),
    rtlActive: PropTypes.bool,
    color: PropTypes.oneOf(['white', 'red', 'orange', 'green', 'blue', 'purple', 'rose']),
    logo: PropTypes.string,
    logoText: PropTypes.string,
    image: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
};

function mapStateToProps(state) {
    const { users, authentication, documenti, tema } = state;
    return {
        users,
        tema,
        documenti,
        user: authentication?.user ?? {},
    };
}

const connectedHomePage = connect(mapStateToProps)(withRouter(Sidebar));
export default withStyles(sidebarStyle)(connectedHomePage);
