import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { Tooltip, Checkbox, Switch, List, ListItem, Paper, withStyles } from "@material-ui/core";

// icons
import { Check, Refresh, Flag, Search, ViewList } from "@material-ui/icons";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import SignDocument from "@material-ui/icons/BorderColor";
import CheckDocument from "@material-ui/icons/AssignmentTurnedIn";
import Download from "@material-ui/icons/CloudDownload";
import File from "@material-ui/icons/ImportContacts";
import Euro from "@material-ui/icons/EuroSymbolOutlined";
import DelegatoIcon from "@material-ui/icons/SupervisorAccount";
import OutFlag from "@material-ui/icons/OutlinedFlag";
import InArrivo from "@material-ui/icons/Timeline";
import Abort from "@material-ui/icons/ThumbDown";
import Verify from "@material-ui/icons/HowToReg";
import OutVerify from "@material-ui/icons/HowToRegOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { raccoglitoriActions, documentiActions, utilsActions, alertActions, userActions } from "../../Actions";
import DocumentoModalForm from "../../Custom/Documento/DocumentoModalForm.jsx";
import FirmaModalForm from "../../Custom/Documento/FirmaModalForm.jsx";
import VistoModalForm from "../../Custom/Documento/VistoModalForm.jsx";
import FileModalForm from "../../Custom/Documento/FileModalForm.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import _ from "lodash";
import { css } from "emotion";
import { ClipLoader } from "react-spinners";

//assets
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { ModelViewHelper, Authorize } from "Helpers/model-view.js";
import SelectSearch from "Custom/SelectSearch.jsx";
import swal from "@sweetalert/with-react";
import moment from "moment";

const overlay = css`
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 9999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(18, 37, 19, 0.35); /* Black w/ opacity */
`;

const override = css`
    display: block!important;
    
    border-color: red;
    position: fixed;
    z-index: 9999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

const dashboardList = css`
        padding: 0 !important;
        text-align: center;
`;

const styles = {
    ...dashboardStyle,
    ...regularFormsStyle,
    tcsign_action: {
        //"padding": "0px!important",
        //"margin": "0px!important",
        width: "20px",
        minWidth: "20px",
    },
    tcsign_spesa: {
        //"padding": "0px!important",
        //"margin": "0px!important",
        width: "20px",
        minWidth: "20px",
        cursor: "default",
    },
    tcsign_delegante: {
        padding: "20px!important",
        marginBottom: "50px",
    },
    tcsign_verify_check: {
        fontSize: "14px !important",
        fontWeight: "500",
        color: "#00acc1",
    },
    switchBase: {
        "&$checked": {
            color: "green",
        },
    },
};

const GreenSwitch = withStyles({
    root: {
        width: 50,
    },
    switchBase: {
        width: 36,
        height: 36,
        "&$checked": {
            color: "green",
        },
    },
    colorSecondary: {
        "&$checked": {
            "& + $bar": {
                "background-color": "green",
            },
        },
    },
    icon: {
        width: 16,
        height: 16,
        marginRight: 6,
    },
    bar: {
        width: 26,
        height: 11,
        marginTop: -5.5,
    },
    checked: {},
})(Switch);

const VioletSwitch = withStyles({
    root: {
        width: 50,
    },
    switchBase: {
        width: 36,
        height: 36,
        "&$checked": {
            color: "#7f02a1",
        },
    },
    colorSecondary: {
        "&$checked": {
            "& + $bar": {
                "background-color": "#7f02a1",
            },
        },
    },
    icon: {
        width: 16,
        height: 16,
        marginRight: 6,
    },
    bar: {
        width: 26,
        height: 11,
        marginTop: -5.5,
    },
    checked: {},
})(Switch);

const BlueSwitch = withStyles({
    root: {
        width: 50,
    },
    switchBase: {
        width: 36,
        height: 36,
        "&$checked": {
            color: "#3f51b5",
        },
    },
    colorSecondary: {
        "&$checked": {
            "& + $bar": {
                "background-color": "#3f51b5",
            },
        },
    },
    icon: {
        width: 16,
        height: 16,
        marginRight: 6,
    },
    bar: {
        width: 26,
        height: 11,
        marginTop: -5.5,
    },
    checked: {},
})(Switch);

const OrangeSwitch = withStyles({
    root: {
        width: 50,
    },
    switchBase: {
        width: 36,
        height: 36,
        "&$checked": {
            color: "#e37209",
        },
    },
    colorSecondary: {
        "&$checked": {
            "& + $bar": {
                "background-color": "#b56d2a",
            },
        },
    },
    icon: {
        width: 16,
        height: 16,
        marginRight: 6,
    },
    bar: {
        width: 26,
        height: 11,
        marginTop: -5.5,
    },
    checked: {},
})(Switch);

const RoseSwitch = withStyles({
    root: {
        width: 50,
    },
    switchBase: {
        width: 36,
        height: 36,
        "&$checked": {
            color: "#d81b60",
        },
    },
    colorSecondary: {
        "&$checked": {
            "& + $bar": {
                "background-color": "#d81b60",
            },
        },
    },
    icon: {
        width: 16,
        height: 16,
        marginRight: 6,
    },
    bar: {
        width: 26,
        height: 11,
        marginTop: -5.5,
    },
    checked: {},
})(Switch);

const RedSwitch = withStyles({
    root: {
        width: 50,
    },
    switchBase: {
        width: 36,
        height: 36,
        "&$checked": {
            color: "#db1414",
        },
    },
    colorSecondary: {
        "&$checked": {
            "& + $bar": {
                "background-color": "#db1414",
            },
        },
    },
    icon: {
        width: 16,
        height: 16,
        marginRight: 6,
    },
    bar: {
        width: 26,
        height: 11,
        marginTop: -5.5,
    },
    checked: {},
})(Switch);

class Dashboard extends React.Component {
    state = {
        value: 0,
        selectedDocumento: undefined,
        documento: undefined,
        uuidDocumento: undefined,
        showFile: false,
        showDocumento: false,
        closeInfo: false,
        confirm: undefined,
        filters: [false, false, false, false, false, false, false],
        showModalFirma: false,
        showModalVisto: false,
        selectedDocuments: [],
        verifyCheck: false,
        currentFilter: null,
        noteModal: null,
        daModale: false,
        labelFirmaVisto: undefined,
        card_color: "success",
    };

    getTaskList() {
        //let wStato = this.state.filters;

        //for (var i = 0; i < wStato.length; i++) {
        //    if (wStato[i]) {
        //        this.props.dispatch(documentiActions.getTaskList(i));
        //        return;
        //    }
        //}

        this.props.dispatch(documentiActions.getTaskList());
        this.props.dispatch(documentiActions.emptyItems());
    }

    reloadPage() {
        this.getInfo();
    }

    getCount() {
        this.props.dispatch(documentiActions.getTaskCount(0));
        this.props.dispatch(documentiActions.getTaskCount(1));
        this.props.dispatch(documentiActions.getTaskCount(2));
        this.props.dispatch(documentiActions.getTaskCount(3));
    }

    componentDidMount() {
        localStorage.removeItem("filters");
        this.props.dispatch(utilsActions.setReloaded(true));
        if (localStorage.getItem("theme_color") && localStorage.getItem("theme_color") !== "undefined") {
            let card_color = JSON.parse(localStorage.getItem("theme_color"));
            if (card_color) this.setState({ card_color: card_color });
        }
        this.getInfo();
    }

    getInfo() {
        const { user } = this.props;
        this.getTaskList();
        this.getCount();
        this.props.dispatch(raccoglitoriActions.getAll(undefined, user.ruoli));
        this.props.dispatch(utilsActions.getUnitaOrganizzative());
        this.props.dispatch(utilsActions.getTipologiaDocumento());
        this.props.dispatch(utilsActions.getStatoDocumento());
    }

    hideInfo() {
        this.setState({
            closeInfo: true,
            confirm: "",
        });
    }

    handleClickFlagDocumento(idDocumento, isAttenzionato, livello) {
        let aggiungiFlag = true;
        let nuovoLivello = undefined;
        if (!isAttenzionato) {
            nuovoLivello = "basso";
        } else if (livello === "basso") {
            nuovoLivello = "medio";
        } else if (livello === "medio") {
            nuovoLivello = "alto";
        }
        if (isAttenzionato && livello === "alto") {
            aggiungiFlag = false;
        }
        this.props.dispatch(
            documentiActions.impostaAttenzionato({ id: idDocumento, attenzionato: aggiungiFlag, livello: nuovoLivello })
        );
        this.setState({ showFile: false });
    }

    handleClickVerificaDocumento(idDocumento, isVerificato, statoVerifica) {
        var id_delegante = this.props.documenti.deleganteSelected.id_delegante;
        let statoV = "Y";
        let aggiungiModifica = true;
        if (statoVerifica && statoVerifica === "Y") {
            statoV = "N";
        }
        if (isVerificato && statoVerifica && statoVerifica === "N") {
            aggiungiModifica = false;
        }
        var verify = {
            id_documento: idDocumento,
            id_utente_delegante: id_delegante,
            verificato: aggiungiModifica,
            stato: statoV,
        };
        if (statoVerifica && statoVerifica === "Y") {
            this.openNoteModal(verify);
        } else {
            this.dispatchVerifica(this, verify);
        }
    }

    dispatchVerifica(argThis, verify) {
        argThis.props.dispatch(documentiActions.impostaVerificato(verify));
        argThis.setState({ showFile: false });
    }

    openNoteModal(verif) {
        this.setState({
            noteModal: (
                <SweetAlert
                    input
                    inputType="textarea"
                    showCancel
                    allowEscape
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Aggiungi un commento"
                    onConfirm={(e) => this.handleAddNota(e, verif, this.dispatchVerifica)}
                    onCancel={() => this.closeNoteModal()}
                    validationMsg="Digitare il commento nel campo di testo prima di confermare."
                    confirmBtnText="Salva"
                    cancelBtnText="Chiudi"
                    confirmBtnCssClass={this.props.classes.tc_sign_btn_confirm}
                    cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
                />
            ),
        });
    }

    handleAddNota(e, v, callback) {
        this.setState({
            noteModal: null,
        });
        v.note = e;
        callback(this, v);
        return e;
    }

    closeNoteModal() {
        this.setState({
            noteModal: null,
        });
    }

    handleSubmitModalFirma(firma) {
        const { user } = this.props;
        var _this = this;
        if (!this.state.daModale) {
            let docList = this.excludeUncheckedDocuments(this.state.selectedDocuments);
            if (docList.length > 0) {
                let azione = {
                    id_azione: 2,
                    id_documento: docList,
                    username: firma.username,
                    password: firma.password,
                    otp: firma.otp,
                };
                this.setState({ selectedDocuments: [] });
                this.individuaFiltriAttivi(function (value) {
                    _this.props.dispatch(
                        documentiActions.eseguiAzione(azione, value, _this.state.verifyCheck, user.ruoli)
                    );
                });
                this.setState({ showFile: false });
                this.setState({ daModale: false });
            } else {
                this.props.dispatch(alertActions.error("Selezionare almeno un documento visualizzato"));
            }
        } else {
            let azione = {
                id_azione: 2,
                id_documento: this.state.selectedDocuments,
                username: firma.username,
                id_digital_signature: firma.id_digital_signature,
                otp: firma.otp,
            };
            this.setState({ selectedDocuments: [] });
            this.individuaFiltriAttivi(function (value) {
                _this.props.dispatch(documentiActions.eseguiAzione(azione, value, _this.state.verifyCheck, user.ruoli));
            });
            this.setState({ showFile: false });
        }
    }

    handleSubmitModalVistoConFirma(firma) {
        const { user } = this.props;
        var _this = this;
        if (!this.state.daModale) {
            let docList = this.excludeUncheckedDocuments(this.state.selectedDocuments);
            if (docList.length > 0) {
                let azione = {
                    id_azione: 7,
                    id_documento: docList,
                    username: firma.username,
                    id_digital_signature: firma.id_digital_signature,
                    otp: firma.otp,
                };
                this.setState({ selectedDocuments: [] });
                this.individuaFiltriAttivi(function (value) {
                    _this.props.dispatch(
                        documentiActions.eseguiAzione(azione, value, _this.state.verifyCheck, user.ruoli)
                    );
                });
                this.setState({ showFile: false });
                this.setState({ daModale: false });
            } else {
                this.props.dispatch(alertActions.error("Selezionare almeno un documento visualizzato"));
            }
        } else {
            let azione = {
                id_azione: 7,
                id_documento: this.state.selectedDocuments,
                username: firma.username,
                id_digital_signature: firma.id_digital_signature,
                otp: firma.otp,
            };
            this.setState({ selectedDocuments: [] });
            this.individuaFiltriAttivi(function (value) {
                _this.props.dispatch(documentiActions.eseguiAzione(azione, value, _this.state.verifyCheck, user.ruoli));
            });
            this.setState({ showFile: false });
        }
    }

    hideConfirm() {
        this.setState({
            confirm: undefined,
        });
    }

    closeFileViewer() {
        this.setState({
            showFile: false,
        });
    }

    fixBootstrapModal() {
        if (document && document.querySelector("[data-id=viewer]")) {
            var modalNode = document.querySelector("[data-id=viewer]").querySelector('[tabindex="-1"]');
            if (!modalNode) return;

            modalNode.removeAttribute("tabindex");
            modalNode.classList.add("js-swal-fixed");
        }
    }

    // call this before hiding SweetAlert (inside done callback):
    restoreBootstrapModal() {
        if (document && document.querySelector(".js-swal-fixed")) {
            var modalNode = document.querySelector(".js-swal-fixed");
            if (!modalNode) return;

            modalNode.setAttribute("tabindex", "-1");
            modalNode.classList.remove("js-swal-fixed");
        }
    }

    handleSubmitModalVisto(visto) {
        const { user } = this.props;
        var _this = this;
        if (!this.state.daModale) {
            let docList = this.excludeUncheckedDocuments(this.state.selectedDocuments);
            if (docList.length > 0) {
                let azione = {
                    id_azione: 3,
                    id_documento: docList,
                };
                this.setState({ selectedDocuments: [] });
                this.individuaFiltriAttivi(function (value) {
                    _this.props.dispatch(
                        documentiActions.eseguiAzione(azione, value, _this.state.verifyCheck, user.ruoli)
                    );
                });
                this.setState({ showFile: false });
                this.setState({ daModale: false });
            } else {
                this.props.dispatch(alertActions.error("Selezionare almeno un documento visualizzato"));
            }
        } else {
            let azione = {
                id_azione: 3,
                id_documento: this.state.selectedDocuments,
            };
            this.setState({ selectedDocuments: [] });
            this.individuaFiltriAttivi(function (value) {
                _this.props.dispatch(documentiActions.eseguiAzione(azione, value, _this.state.verifyCheck, user.ruoli));
            });

            this.setState({ showFile: false });
            this.setState({ daModale: false });
        }
    }

    excludeUncheckedDocuments(documents) {
        let newDocuments = [];
        documents.forEach((doc) => {
            if (this.documentRead(doc)) {
                newDocuments.push(doc);
            }
        });
        return newDocuments;
    }

    documentRead(documento) {
        let letto = false;
        this.props.documenti.tasks.forEach((x) => {
            if (x.id_documento === documento && x.letto === "S") {
                letto = true;
            }
        });
        return letto;
    }

    handleSelectDelegato(event) {
        console.log("Utente delegante selezionato ");
        console.log(event.target);
        this.props.dispatch(documentiActions.addDelegante(event.target.value, event.target.label, event.target.other));
        this.props.dispatch(documentiActions.getTaskList());
    }

    handleClickActionRefuse(documento) {
        const { user } = this.props;
        this.fixBootstrapModal();
        this.setState({
            confirm: (
                <SweetAlert
                    input
                    inputType="textarea"
                    showCancel
                    allowEscape
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Prego inserire una nota per il rifiuto del documento."
                    onConfirm={(e) => {
                        let rifiutaDocumento = {
                            id_azione: 4,
                            id_documento: [documento.id],
                            nota_rifiuto: e,
                        };
                        this.hideConfirm();
                        this.closeFileViewer();
                        this.props.dispatch(documentiActions.rifiutaDocumento(rifiutaDocumento, user.ruoli));
                    }}
                    onCancel={() => this.hideConfirm()}
                    validationMsg="Digitare il commento nel campo di testo prima di confermare."
                    confirmBtnText="Salva"
                    cancelBtnText="Chiudi"
                    confirmBtnCssClass={this.props.classes.tc_sign_btn_confirm}
                    cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
                />
            ),
        });
    }

    handleClickActionMetadata(documento) {
        //non commentare tale metodo altrimenti non è possibile scrivere impegno di spesa
        this.fixBootstrapModal();
        this.setState({
            confirm: (
                <SweetAlert
                    input
                    inputType="textarea"
                    showCancel
                    allowEscape
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Prego inserire l'impegno di spesa"
                    onConfirm={(e) => {
                        this.hideConfirm();
                        this.closeFileViewer();
                        let impegno_spesa = {
                            impegno_spesa: e ? e : null,
                        };
                        this.props.dispatch(documentiActions.updateMetadata(documento.id, impegno_spesa, true));
                    }}
                    onCancel={() => this.hideConfirm()}
                    validationMsg="Digitare l'impegno di spesa nel campo di testo prima di confermare."
                    confirmBtnText="Salva"
                    cancelBtnText="Chiudi"
                    confirmBtnCssClass={this.props.classes.tc_sign_btn_confirm}
                    cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
                />
            ),
        });
    }

    handleClickAction(documento, prov) {
        const { user } = this.props;
        //Operazione Firma/Visto
        let vistoFirma = {
            closeInfo: false,
            selectedDocuments: [documento.id],
            daModale: prov,
        };
        if (documento.id_stato_flusso === 1) {
            vistoFirma["showModalFirma"] = true;
            vistoFirma["labelFirmaVisto"] = "firma";
            vistoFirma["uuidDocumento"] = documento.uuid;
            if (!prov) this.props.dispatch(documentiActions.getDocumentoById(documento.id));
        } else if (documento.id_stato_flusso === 2) {
            vistoFirma["labelFirmaVisto"] = "visto";
            if (user?.providerFirma === "infocert" || user?.providerFirma === "noProvider")
                vistoFirma["showModalVisto"] = true;
            else vistoFirma["showModalFirma"] = true;
        }
        this.setState(vistoFirma);
    }

    handleCloseModalVisto() {
        this.setState({
            showModalVisto: false,
        });
    }

    handleCloseModalFirma() {
        this.setState({
            showModalFirma: false,
        });
    }

    handleCloseModalVistoConFirma(prov) {
        this.setState({
            showModalFirma: false,
        });
        if (!prov) this.props.dispatch(documentiActions.resetDocument());
    }

    handleClickOpenDocumento(idDocumento) {
        var x = [];
        x["showDocumento"] = true;
        x["selectedDocumento"] = idDocumento;
        this.setState(x);
    }

    handleClickOpenFile(doc) {
        const { documenti } = this.props;
        var x = [];
        x["showFile"] = true;
        x["uuidDocumento"] = doc.uuid;
        x["documento"] = doc;
        if (doc.id_riunione && doc.id_argomento) {
            this.props.dispatch(
                documentiActions.getMeetingDetails(documenti.sessionId, doc.id_riunione, doc.id_argomento)
            );
            //this.props.dispatch(documentiActions.getTopicDetails(doc.id_riunione, doc.id_argomento))
        }
        this.setState(x);
    }

    handleClickCloseFile() {
        var x = [];
        x["showFile"] = false;
        x["selectedDocumento"] = undefined;
        this.setState(x);
        let _this = this;
        this.individuaFiltriAttivi(function (value) {
            _this.props.dispatch(documentiActions.getTaskList(value, _this.state.verifyCheck));
        });
    }

    individuaFiltriAttivi(callback) {
        let index = null;
        for (let i = 0; i < this.state.filters.length; i++) {
            if (this.state.filters[i] === true) {
                index = i;
            }
        }
        callback(index);
    }

    handleClickCloseDocumento() {
        var x = [];
        x["showDocumento"] = false;
        x["selectedDocumento"] = undefined;
        this.setState(x);
    }

    toggleSelectAll() {
        if (this.state.selectedDocuments.length === 0) {
            const { selectedDocuments } = this.state;
            const newChecked = [...selectedDocuments];
            this.props.documenti.tasks.forEach((x) => {
                newChecked.push(x.id_documento);
                //this.handleClickSelectDocumento(x.id_documento);
            });
            this.setState({
                selectedDocuments: newChecked,
            });
        } else {
            this.setState({
                selectedDocuments: [],
            });
        }
    }

    handleClickSelectDocumento(idDocumento) {
        const { selectedDocuments } = this.state;
        console.log("DOCUMENTI SELEZIONATI : " + selectedDocuments);
        const currentIndex = selectedDocuments.indexOf(idDocumento);
        const newChecked = [...selectedDocuments];

        if (currentIndex === -1) {
            newChecked.push(idDocumento);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            selectedDocuments: newChecked,
        });
    }

    handleClickSignDocuments() {
        this.setState({
            closeInfo: false,
            showModalFirma: true,
            labelFirmaVisto: "visto/firma",
        });
    }

    handleClickCheckDocuments() {
        this.setState({
            closeInfo: false,
            showModalVisto: true,
        });
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };
    handleChangeIndex = (index) => {
        this.setState({ value: index });
    };

    handleFileDownload = (id, documento) => {
        this.props.dispatch(documentiActions.download(id, documento));
    };

    handleCloseAlert() {
        this.props.dispatch(alertActions.clear());
    }

    handleClickFilter = (event, index) => {
        const { checked } = event.target;

        var x = [];
        x["filters"] = [false, false, false, false, false, false, false];
        x["filters"][index] = checked;
        this.setState(x);

        if (index === 3) {
            this.props.history.push("/auth/incoming");
            return;
        }

        if (checked) {
            this.props.dispatch(documentiActions.getTaskList(index, this.state.verifyCheck));
            this.setState({ currentFilter: index });
        } else {
            this.props.dispatch(documentiActions.getTaskList(null, this.state.verifyCheck));
            this.setState({ currentFilter: null });
        }
    };

    handleFilterVerify = (event, index) => {
        const { checked } = event.target;

        var x = [];
        x["filters"] = [false, false, false, false, false, false, false];
        x["filters"][index] = checked;
        this.setState(x);
        //this.setState({verifyCheck:event.target.checked });

        this.props.dispatch(documentiActions.getTaskList(this.state.currentFilter, event.target.checked));
    };

    handleClickOpenFileNote(note_singolo_doc) {
        let elencoNote = "";
        let singolaNota = "";
        var lung = note_singolo_doc.length;

        for (var i = 0; i < lung; i++) {
            var dataCreazione = note_singolo_doc[i].data_creazione;
            var dataCreazioneFormattata = moment(dataCreazione).format("DD/MM/YYYY HH:mm");
            singolaNota =
                "Autore: " +
                note_singolo_doc[i].utente +
                "\n Data e ora: " +
                dataCreazioneFormattata +
                "\n Testo: " +
                note_singolo_doc[i].descrizione +
                "\n \n";
            elencoNote = elencoNote + singolaNota;
        }

        swal({
            animation: "slide-from-top",
            title: "Note del documento",
            overflow: "hidden",
            text: elencoNote,
            //timer: 2000,
            input: "text",
            //target: 'body',
            type: "textarea",
            //heightAuto: false,
            style: { display: "block", marginTop: "-100px" },
            //placeholder:x,
            heght: "100px",
            inputType: "textarea",
            confirmButtonText: "Cool",
            showConfirmButton: false,
            showCancelButton: true,
        });
    }

    render() {
        const { classes, documenti, alert, user, tema } = this.props;
        const { noteModal, filters, confirm, showFile, showModalFirma, showDocumento, uuidDocumento } = this.state;
        const { selectedDocuments, selectedDocumento, documento, labelFirmaVisto, daModale } = this.state;
        let cardColor = tema.theme_color;
        let countTask = documenti.taskCount;

        let listaTask = [];
        if (typeof documenti.tasks !== "undefined") {
            listaTask = documenti.tasks;
        }

        let alertMessage = "";
        if (alert && typeof alert.message !== "undefined") {
            if (alert.type === "alert-success") {
                alertMessage = (
                    <SweetAlert success title="Info" onConfirm={() => this.handleCloseAlert()}>
                        {alert.message}
                    </SweetAlert>
                );
            } else if (alert.type === "alert-danger") {
                alertMessage = (
                    <SweetAlert
                        danger
                        title="Attenzione"
                        onConfirm={() => {
                            this.handleCloseAlert();
                            if (alert.message === "Sessione scaduta, eseguire il login.") {
                                this.props.dispatch(userActions.logout());
                            }
                        }}
                    >
                        {alert.message}
                    </SweetAlert>
                );
            }
        }

        let loading;
        if (documenti?.loading) {
            loading = (
                <div className={overlay}>
                    <ClipLoader
                        className={override}
                        sizeUnit={"px"}
                        size={80}
                        color={"#3a783d"}
                        loading={documenti.loading}
                    />
                </div>
            );
        }

        let wToolbarSignCheck = "";
        if (Authorize.checkAuthorizeFunc("FNCFDC") || Authorize.checkAuthorizeFunc("FNCVDC")) {
            wToolbarSignCheck = (
                <div style={{ marginTop: "15px" }}>
                    {/*<Button
                color="info"
                size="sm"
                disabled={(this.state.selectedDocuments.length === 0)}
                style={{ float: "right" }}
                onClick={() => this.handleClickCheckDocuments()}
            >
                <CheckDocument />
            </Button>*/}
                    <Tooltip
                        id="tooltip-top"
                        title="Ricarica scrivania"
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                    >
                        <Button color="warning" size="sm" style={{ float: "right" }} onClick={() => this.reloadPage()}>
                            <Refresh />
                        </Button>
                    </Tooltip>
                </div>
            );
        } else {
            wToolbarSignCheck = (
                <div style={{ marginTop: "15px" }}>
                    <Button color="warning" size="sm" style={{ float: "right" }} onClick={() => this.reloadPage()}>
                        <Refresh />
                    </Button>
                </div>
            );
        }

        let wFormDocumento = showDocumento ? (
            <DocumentoModalForm
                title={!selectedDocumento ? "Nuovo documento" : "Id Documento: " + selectedDocumento}
                open={showDocumento}
                id={selectedDocumento}
                uuid={uuidDocumento}
                onClose={() => this.handleClickCloseDocumento()}
            />
        ) : null;

        let delegaOption = [];
        let utenteDelegatiRow;
        if (user && user.utente_deleganti && user.utente_deleganti.length > 0) {
            utenteDelegatiRow = _.filter(user.utente_deleganti, function (o) {
                return o.tipo_delega === "V" || o.tipo_delega === "E";
            });

            if (utenteDelegatiRow && utenteDelegatiRow.length > 0)
                for (var i = 0; i < utenteDelegatiRow.length; i++) {
                    var option = {};
                    var labelDelega = utenteDelegatiRow[i].tipo_delega === "E" ? " (ereditato per delega)" : "";
                    option["label"] = utenteDelegatiRow[i].utente + labelDelega;
                    option["value"] = utenteDelegatiRow[i].id_utente;
                    option["other"] = utenteDelegatiRow[i].tipo_delega;
                    delegaOption.push(option);
                }
        }

        let delega =
            delegaOption.length > 0 ? (
                <Paper className={classes.tcsign_delegante}>
                    <SelectSearch
                        label={"Verifica documenti per conto di"}
                        placeholder={"Selezionare utente"}
                        options={delegaOption}
                        isClearable={true}
                        id={"id_utente_delegante"}
                        isSearchable={true}
                        value={documenti.deleganteSelected.id_delegante}
                        onChange={(e) => this.handleSelectDelegato(e)}
                    />
                </Paper>
            ) : null;

        return (
            <div>
                {confirm}
                {loading}
                {alertMessage}
                {noteModal}
                {wFormDocumento}
                {showFile && (
                    <FileModalForm
                        open={showFile}
                        uuid={uuidDocumento}
                        documento={documento}
                        listType={"tasks"}
                        onClose={() => this.handleClickCloseFile()}
                        onSubmitSign={(prov) => this.handleClickAction(documento, prov)}
                        onSubmitCheck={(prov) => this.handleClickAction(documento, prov)}
                        onSubmitAttenziona={(idDocumento, flagAtt, liv) =>
                            this.handleClickFlagDocumento(idDocumento, flagAtt, liv)
                        }
                        onSubmitReject={() => this.handleClickActionRefuse(documento)}
                        onSubmitMetadata={() => this.handleClickActionMetadata(documento)}
                        onSubmitVerify={(idDocumento, flagVerify) =>
                            this.handleClickVerificaDocumento(idDocumento, flagVerify)
                        }
                    />
                )}
                {showModalFirma && (
                    <FirmaModalForm
                        daModale={daModale}
                        uuid={uuidDocumento}
                        docID={selectedDocuments[0]}
                        label={labelFirmaVisto}
                        onClose={(prov) => this.handleCloseModalVistoConFirma(prov)}
                        onSubmit={(value) => this.handleSubmitModalVistoConFirma(value)}
                    />
                )}
                {
                    <VistoModalForm
                        open={this.state.showModalVisto}
                        onClose={() => this.handleCloseModalVisto()}
                        onSubmit={(value) => this.handleSubmitModalVisto(value)}
                    />
                }

                {delega}
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color={cardColor} icon>
                                <CardIcon color={cardColor}>
                                    <ViewList />
                                </CardIcon>
                                <h3 style={{ display: "inline" }} className={classes.cardTitle}>
                                    La tua scrivania
                                </h3>
                                {wToolbarSignCheck}
                            </CardHeader>
                            <CardBody style={{ paddingTop: "0px" }}>
                                <ReactTable
                                    data={listaTask.map((prop, key) => {
                                        let wReturn = ModelViewHelper.document_ModelToView(prop);

                                        let wIcon = "";
                                        let colorAction = "success";
                                        if (wReturn.id_stato_flusso === 1) {
                                            wIcon = <SignDocument />;
                                        } else {
                                            colorAction = "info";
                                            wIcon = <SignDocument />;
                                        }

                                        let wFlag = <OutFlag />;
                                        let wFlagTitle = "Segna come da rivedere con livello basso";
                                        if (wReturn.attenzionato) {
                                            if (wReturn.livello_attenzione === "basso") {
                                                wFlag = <Flag style={{ color: "#43a047" }} />;
                                                wFlagTitle = "Segna come da rivedere con livello medio";
                                            } else if (wReturn.livello_attenzione === "medio") {
                                                wFlag = <Flag style={{ color: "#ff9800" }} />;
                                                wFlagTitle = "Segna come da rivedere con livello alto";
                                            } else {
                                                wFlag = <Flag style={{ color: "red" }} />;
                                                wFlagTitle = "Rimuovi da rivedere";
                                            }
                                        }

                                        let wVerify = <OutVerify />;
                                        let wVerifyTitle = "Segna come verificato";
                                        if (wReturn.verificato) {
                                            if (wReturn.stato_verifica === "Y") {
                                                wVerify = <Verify />;
                                                wVerifyTitle =
                                                    "Verificato con esito positivo da " +
                                                    wReturn.utente_verifica +
                                                    " \n il " +
                                                    wReturn.data_verifica;
                                            } else {
                                                wVerify = <Verify color="secondary" />;
                                                wVerifyTitle =
                                                    "Verificato con esito negativo da " +
                                                    wReturn.utente_verifica +
                                                    " \nil " +
                                                    wReturn.data_verifica +
                                                    " \nNote: " +
                                                    wReturn.noteVerif;
                                            }
                                        }

                                        let spesaIcon = "";

                                        if (wReturn.impegno_spesa)
                                            spesaIcon = (
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Presenza impegno di spesa"
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                >
                                                    <Button
                                                        justIcon
                                                        round
                                                        simple
                                                        color="rose"
                                                        className={classes.tcsign_spesa}
                                                    >
                                                        <Euro />
                                                    </Button>
                                                </Tooltip>
                                            );

                                        let flagAttenzionato = "";
                                        if (Authorize.checkAuthorizeFunc("FNCATD"))
                                            flagAttenzionato = (
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title={wFlagTitle}
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                >
                                                    <Button
                                                        justIcon
                                                        round
                                                        simple
                                                        onClick={() =>
                                                            this.handleClickFlagDocumento(
                                                                wReturn.id,
                                                                wReturn.attenzionato,
                                                                wReturn.livello_attenzione
                                                            )
                                                        }
                                                        color="success"
                                                        className={classes.tcsign_action}
                                                    >
                                                        {wFlag}
                                                    </Button>
                                                </Tooltip>
                                            );

                                        let delegaIcon = "";
                                        if (wReturn.delegato)
                                            delegaIcon = (
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="in delega"
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                >
                                                    <Button
                                                        justIcon
                                                        round
                                                        simple
                                                        color="warning"
                                                        className={classes.tcsign_spesa}
                                                    >
                                                        <DelegatoIcon />
                                                    </Button>
                                                </Tooltip>
                                            );

                                        let wViewFileNote = "";
                                        if (wReturn && wReturn.note && wReturn.note.length > 0) {
                                            wViewFileNote = (
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Note del Documento"
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                >
                                                    <Button
                                                        justIcon
                                                        round
                                                        simple
                                                        onClick={() => this.handleClickOpenFileNote(wReturn.note)}
                                                        color="success"
                                                        className={classes.tcsign_action}
                                                    >
                                                        <InfoOutlinedIcon />
                                                    </Button>
                                                </Tooltip>
                                            );
                                        }

                                        let selCheckbox = "";
                                        if (
                                            Authorize.checkAuthorizeFunc("FNCFDC") ||
                                            Authorize.checkAuthorizeFunc("FNCVDC")
                                        )
                                            selCheckbox = (
                                                <Checkbox
                                                    tabIndex={-1}
                                                    onClick={() => this.handleClickSelectDocumento(wReturn.id)}
                                                    checked={
                                                        selectedDocuments.indexOf(wReturn.id) !== -1 ? true : false
                                                    }
                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    classes={{
                                                        checked: classes.checked,
                                                    }}
                                                />
                                            );

                                        let flagVerificaDoc = "";

                                        if (documenti.deleganteSelected.id_delegante) {
                                            flagVerificaDoc = (
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title={wVerifyTitle}
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                    style={{ whiteSpace: "pre-line" }}
                                                >
                                                    <Button
                                                        justIcon
                                                        round
                                                        simple
                                                        onClick={() =>
                                                            this.handleClickVerificaDocumento(
                                                                wReturn.id,
                                                                wReturn.verificato,
                                                                wReturn.stato_verifica
                                                            )
                                                        }
                                                        color="info"
                                                        className={classes.tcsign_action}
                                                    >
                                                        {wVerify}
                                                    </Button>
                                                </Tooltip>
                                            );
                                        } else if (!documenti.deleganteSelected.id_delegante && wReturn.verificato) {
                                            flagVerificaDoc = (
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title={wVerifyTitle}
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                    style={{ whiteSpace: "pre-line" }}
                                                >
                                                    <Button
                                                        justIcon
                                                        round
                                                        simple
                                                        color="info"
                                                        className={classes.tcsign_action}
                                                    >
                                                        {wVerify}
                                                    </Button>
                                                </Tooltip>
                                            );
                                        }

                                        wReturn.sel = <div>{selCheckbox}</div>;

                                        wReturn.icon = (
                                            <div>
                                                {flagAttenzionato}
                                                {wViewFileNote}
                                                {flagVerificaDoc}
                                                {spesaIcon}
                                                {delegaIcon}
                                            </div>
                                        );

                                        let wViewFileButton = "";
                                        if (wReturn.file_name !== null) {
                                            wViewFileButton = (
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Visualizza il documento"
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                >
                                                    <Button
                                                        justIcon
                                                        round
                                                        simple
                                                        onClick={() => this.handleClickOpenFile(wReturn)}
                                                        color="primary"
                                                        className={classes.tcsign_action}
                                                    >
                                                        <File />
                                                    </Button>
                                                </Tooltip>
                                            );
                                        }

                                        let wDownloadButton = "";
                                        if (wReturn.file_name !== null) {
                                            wDownloadButton = (
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Scarica il documento"
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                >
                                                    <Button
                                                        justIcon
                                                        round
                                                        simple
                                                        onClick={() => this.handleFileDownload(wReturn.uuid, wReturn)}
                                                        color="rose"
                                                        className={classes.tcsign_action}
                                                    >
                                                        <Download />
                                                    </Button>
                                                </Tooltip>
                                            );
                                        }

                                        let wViewButton = (
                                            <Tooltip
                                                id="tooltip-top"
                                                title="Visualizza i dettagli"
                                                placement="bottom"
                                                classes={{ tooltip: classes.tooltip }}
                                            >
                                                <Button
                                                    justIcon
                                                    round
                                                    simple
                                                    onClick={() => this.handleClickOpenDocumento(wReturn.id)}
                                                    color="success"
                                                    className={classes.tcsign_action}
                                                >
                                                    <Search />
                                                </Button>
                                            </Tooltip>
                                        );

                                        let wActionDisabled = true;
                                        if (wReturn.letto) {
                                            wActionDisabled = false;
                                        }

                                        let wActionReject = (
                                            <Tooltip
                                                id="tooltip-top"
                                                title={wActionDisabled ? "" : "Rifiuta"}
                                                placement="bottom"
                                                classes={{ tooltip: classes.tooltip }}
                                            >
                                                <Button
                                                    justIcon
                                                    round
                                                    simple
                                                    disabled={wActionDisabled}
                                                    onClick={() => this.handleClickActionRefuse(wReturn)}
                                                    color="danger"
                                                    className={classes.tcsign_action}
                                                >
                                                    <Abort />
                                                </Button>
                                            </Tooltip>
                                        );

                                        let wActionButton = (
                                            <Tooltip
                                                id="tooltip-top"
                                                title={wActionDisabled ? "" : wReturn.desc_stato_flusso}
                                                placement="bottom"
                                                classes={{ tooltip: classes.tooltip }}
                                            >
                                                <Button
                                                    justIcon
                                                    round
                                                    simple
                                                    disabled={wActionDisabled}
                                                    onClick={() => this.handleClickAction(wReturn, false)}
                                                    color={colorAction}
                                                    className={classes.tcsign_action}
                                                >
                                                    {wIcon}
                                                </Button>
                                            </Tooltip>
                                        );

                                        wReturn.actions = (
                                            <div>
                                                {Authorize.checkAuthorizeFunc("FNCVWD") ? wViewFileButton : ""}
                                                {Authorize.checkAuthorizeFunc("FNCDWL") ? wDownloadButton : ""}
                                                {Authorize.checkAuthorizeFunc("FNCDDC") ? wViewButton : ""}
                                                {Authorize.checkAuthorizeFunc("FNCFDC") ||
                                                Authorize.checkAuthorizeFunc("FNCVDC")
                                                    ? wActionButton
                                                    : ""}
                                                {Authorize.checkAuthorizeFunc("FNCRDC") ? wActionReject : ""}
                                            </div>
                                        );

                                        return wReturn;
                                    })}
                                    filterable
                                    columns={[
                                        {
                                            Header: () => {
                                                return (
                                                    <Checkbox
                                                        tabIndex={-1}
                                                        onClick={() => this.toggleSelectAll()}
                                                        checked={selectedDocuments.length > 0 ? true : false}
                                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{
                                                            checked: classes.checked,
                                                        }}
                                                    />
                                                );
                                            },
                                            accessor: "sel",
                                            sortable: false,
                                            filterable: false,
                                            maxWidth: 50,
                                        },
                                        {
                                            accessor: "icon",
                                            sortable: false,
                                            filterable: false,
                                            maxWidth: 90,
                                        },
                                        {
                                            //Header: "Percorso firma",
                                            Header: "Oggetto",
                                            //accessor: "nome_raccoglitore",
                                            accessor: "nome",
                                            maxWidth: 250,
                                        },
                                        {
                                            Header: "Origine",
                                            accessor: "soggetto_originatore",
                                            maxWidth: 170,
                                        },
                                        {
                                            Header: "Tipologia",
                                            accessor: "desc_tipo_documento",
                                            maxWidth: 170,
                                            filterMethod: (filter, row) =>
                                                row[filter.id].toUpperCase().includes(filter.value.toUpperCase()),
                                        },
                                        {
                                            Header: "Caricato da",
                                            //ORIGINALE accessor: "utente_originatore",
                                            accessor: "utente_originatore",
                                            Cell: (row) => (
                                                <Tooltip
                                                    id="tooltip-top"
                                                    //ORIGINALE title={row.original.uo_created}
                                                    title={row.original.utente_originatore}
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                >
                                                    <span>{row.value}</span>
                                                </Tooltip>
                                            ),
                                        },
                                        {
                                            Header: "Stato",
                                            accessor: "desc_stato_flusso",
                                        },
                                        {
                                            Header: "Data documento",
                                            accessor: "data_documento",
                                            sortMethod: (a, b) => {
                                                // force null and undefined to the bottom
                                                a = a === null || a === undefined ? -Infinity : a;
                                                b = b === null || b === undefined ? -Infinity : b;

                                                let aMoment = moment(a.props.children);

                                                // Return either 1 or -1 to indicate a sort priority
                                                if (aMoment.isAfter(b.props.children)) {
                                                    return 1;
                                                }
                                                if (aMoment.isBefore(b.props.children)) {
                                                    return -1;
                                                }
                                                // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
                                                return 0;
                                            },
                                            maxWidth: 200,
                                        },
                                        {
                                            Header: "Azioni",
                                            accessor: "actions",
                                            sortable: false,
                                            filterable: false,
                                        },
                                    ]}
                                    defaultPageSize={10}
                                    showPaginationTop={false}
                                    showPaginationBottom
                                    className="-striped -highlight"
                                    striped
                                    hover
                                    condensed
                                    pagination
                                    insertRow
                                    deleteRow
                                    search
                                    // Text
                                    previousText="Precedente"
                                    nextText="Successivo"
                                    loadingText="In caricamento..."
                                    noDataText="Nessun task assegnato"
                                    pageText="Pagina"
                                    ofText="di"
                                    rowsText="righe"
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { alert, authentication, documenti, users, tema } = state;
    const { user } = authentication ?? {};
    return {
        user,
        alert,
        documenti,
        users,
        tema,
    };
}

const connectedDashboard = connect(mapStateToProps)(withStyles(styles)(withRouter(Dashboard)));
export default connectedDashboard;
