import React from 'react';
import PropTypes from 'prop-types';
import { ArrowLeftOutlined, ArrowRightOutlined, SkipNext, SkipPrevious } from '@material-ui/icons';
import { Tooltip, Button } from '@material-ui/core';
import ButtonUI from '@material-ui/core/Button';

const DocumentNavigator = (props) => {
    const { numPages, pageNumber, navPosition, goToPrevPage, goToNextPage } = props;
    const { listaDoc, numDocs, index, navigateDocs, tagPagina, setTagPagina } = props;

    const navigators = [
        {
            title: 'Pagina precedente',
            icon: <ArrowLeftOutlined />,
            onClick: () => goToPrevPage(pageNumber),
            disabled: false,
        },
        {
            title: 'Pagina successiva',
            icon: <ArrowRightOutlined />,
            onClick: () => goToNextPage(pageNumber, numPages),
            disabled: false,
        },
    ];

    const goToDoc = (direction) => {
        navigateDocs(index, listaDoc, direction);
    };

    if (listaDoc?.length > 0 && index >= 0) {
        navigators.unshift({
            title: 'Documento precedente',
            icon: <SkipPrevious />,
            onClick: () => goToDoc('prev'),
            disabled: numDocs === 1 || index === 0 ? true : false,
        });
        navigators.push({
            title: 'Documento successivo',
            icon: <SkipNext />,
            onClick: () => goToDoc('next'),
            disabled: numDocs === 1 || index === numDocs - 1 ? true : false,
        });
    }

    return (
        <nav
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: navPosition === 'bottom' ? 'column-reverse' : 'column',
            }}>
            {numPages && <div style={{ width: '100%', textAlign: 'center' }}>
                <p>
                    Page {pageNumber} of {numPages}
                </p>
            </div>}
            <div style={{ width: '100%', textAlign: 'center' }}>
                {navigators.map((navigator, index) => {
                    const { title, icon, onClick, disabled } = navigator;
                    return (
                        <Tooltip id="tooltip-nota" title={disabled ? '' : title} placement="bottom" key={index}>
                            <ButtonUI
                                size="small"
                                variant="outlined"
                                color={
                                    navigators.length === 2
                                        ? 'primary'
                                        : index === 0 || index === 3
                                        ? 'secondary'
                                        : 'primary'
                                }
                                style={{ margin: '10px' }}
                                disabled={disabled}
                                onClick={onClick}>
                                {icon}
                            </ButtonUI>
                        </Tooltip>
                    );
                })}
            </div>
            {tagPagina && tagPagina !== 1 && navPosition !== 'bottom' && (
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => setTagPagina(tagPagina)}
                    style={{ width: 200, margin: 'auto' }}>
                    Vai alla firma
                </Button>
            )}
        </nav>
    );
};

DocumentNavigator.propTypes = {
    navPosition: PropTypes.oneOf(['top', 'bottom']),
};

export default DocumentNavigator;
