import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import Datetime from "react-datetime";
import moment from "moment";
import "moment/locale/it";
import { FormControl, withStyles, ClickAwayListener } from "@material-ui/core";
import Calendar from "@material-ui/icons/DateRangeRounded";
import Clear from "@material-ui/icons/ClearRounded";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

const styles = {
    ...extendedFormsStyle,
    fromControlRoot: {
        verticalAlign: "middle",
    },
    dateLabel: {
        fontSize: "20px",
        color: "#606060",
        fontWeight: "bold",
        backgroundColor: "white",
        padding: "2px 4px",
        position: "absolute",
        left: 10,
        top: -21,
        zIndex: 8,
    },
    dateWrapper: {
        maxWidth: "200px",
        position: "relative",
        border: "1px solid lightgrey",
        borderRadius: "5px",
        "&:hover": { border: "1px solid black" },
    },
    dateIntWrapper: {
        display: "flex",
        alignItems: "center",
        padding: "0px 6px",
    },
    datetime: {
        padding: "0px 6px",
        color: "#0047BB",
        cursor: "pointer",
        "& th.rdtSwitch": { textTransform: "capitalize" },
        "& .rdtPicker .dow, .rdtPicker th.rdtSwitch, .rdtPicker th.rdtNext, .rdtPicker th.rdtPrev, .rdtPicker .rdtTimeToggle":
            { color: "#0047BB" },
        "& .rdtPicker td.rdtToday": { backgroundColor: "#eedc00 !important" },
    },
    active: {
        "& .rdtDay.rdtActive, .rdtDay.rdtActive:hover": {
            backgroundColor: "#0047BB !important",
            color: "white",
        },
    },
    inactive: {
        "& .rdtDay.rdtActive": {
            background: "none !important",
            boxShadow: "none",
            color: "#0047BB",
        },
        "&  .rdtDay.rdtActive:hover": {
            background: "#eeeeee !important",
        },
    },
    clear: {
        fontSize: "16px",
        cursor: "pointer",
        color: "DimGray",
        "& :hover": {
            color: "black",
        },
        marginRight: "3px",
    },
};

const DateRange = ({ classes, direction, dateRange, setDateRange, ...otherProps }) => {
    const { labelFrom, labelTo, placeholderFrom, placeholderTo, timeFormat, format } = otherProps;
    const { nameFrom, nameTo, maxRange } = otherProps;

    const defaultFormat = "YYYY-MM-DD";

    const [open, setOpen] = useState({ [nameFrom || "from"]: false, [nameTo || "to"]: false });

    const handleDate = (value, name) => {
        setDateRange(value.format(format), name);
        setOpen({ ...open, [name]: false });
    };

    const onClear = (name) => setDateRange(null, name);

    const handleOpen = (name) => {
        const value = !open[name];
        const oppositeRange = name === "from" ? "to" : "from";

        // if we are opening the calendar and the other side of the range has its calendar open, we close it
        const newValue =
            value && open[oppositeRange] ? { [oppositeRange]: false, [name]: value } : { ...open, [name]: value };

        setOpen(newValue);
    };

    const isValidDate = (current, caller) => {
        const { from, to } = dateRange;
        const { amount, unit } = maxRange ?? {};

        const maxFrom = maxRange && to ? current.isSameOrAfter(moment(to).subtract(amount, unit)) : true;

        const maxTo =
            maxRange && from
                ? current.isSameOrBefore(moment(from).add(amount, unit))
                : current.isSameOrBefore(moment());

        if (caller === "from" && to) return current.isSameOrBefore(to) && maxFrom;
        else if (caller === "to" && from) return current.isSameOrAfter(from) && maxTo;
        else return current.isSameOrBefore(moment());
    };

    const ranges = [
        {
            name: nameFrom || "from",
            label: labelFrom || "Dal",
            value: dateRange.from || "",
            placeholder: placeholderFrom || "Creazione dal",
            open: open[nameFrom || "from"],
            isValidDate: (current) => isValidDate(current, "from"),
        },
        {
            name: nameTo || "to",
            label: labelTo || "Al",
            value: dateRange.to || "",
            placeholder: placeholderTo || "Creazione al",
            open: open[nameTo || "to"],
            isValidDate: (current) => isValidDate(current, "to"),
        },
    ];

    const onClickAway = (name) => setOpen({ ...open, [name]: false });

    return (
        <FormControl
            fullWidth
            style={{ display: "flex", flexDirection: direction || "row", gap: "20px", justifyContent: "space-between" }}
        >
            {ranges.map((r, i) => {
                const { label, value, placeholder, name, open, isValidDate } = r;
                const formattedValue = value ? moment(value, format || defaultFormat).format("DD/MM/YYYY") : value;

                return (
                    <ClickAwayListener key={i} onClickAway={() => onClickAway(name)}>
                        <div className={classes.dateWrapper}>
                            <label className={classes.dateLabel}>{label}</label>
                            <div className={classes.dateIntWrapper}>
                                <Datetime
                                    name={name}
                                    locale="it"
                                    className={`${classes.datetime} ${value ? classes.active : classes.inactive}`}
                                    isValidDate={isValidDate}
                                    open={open}
                                    timeFormat={timeFormat !== undefined ? timeFormat : true}
                                    onChange={(value) => handleDate(value, name)}
                                    inputProps={{
                                        readOnly: true,
                                        placeholder,
                                        value: formattedValue,
                                        style: {
                                            background: "none",
                                            cursor: "pointer",
                                            fontSize: "16px",
                                            color: "#606060",
                                        },
                                        onClick: () => handleOpen(name),
                                    }}
                                />
                                {value && <Clear className={classes.clear} onClick={() => onClear(name)} />}
                                <Calendar
                                    style={{ cursor: "pointer", color: "DimGray" }}
                                    onClick={() => handleOpen(name)}
                                />
                            </div>
                        </div>
                    </ClickAwayListener>
                );
            })}
        </FormControl>
    );
};

DateRange.propTypes = {
    direction: PropTypes.string,
    dateRange: PropTypes.object.isRequired,
    setDateRange: PropTypes.func.isRequired,
    labelFrom: PropTypes.string,
    labelTo: PropTypes.string,
    placeholderFrom: PropTypes.string,
    placeholderTo: PropTypes.string,
    timeFormat: PropTypes.bool,
    format: PropTypes.string,
    nameFrom: PropTypes.string,
    nameTo: PropTypes.string,
    maxRange: PropTypes.object,
};

export default withStyles(styles)(DateRange);
