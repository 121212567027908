import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from "@material-ui/core/Hidden";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Dashboard from "@material-ui/icons/Dashboard";
import Help from "@material-ui/icons/Help";



// core components
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-dashboard-pro-react/components/headerLinksStyle";

class HeaderLinks extends React.Component {
    state = {
        open: false
    };
    handleClick = () => {
        this.setState({ open: !this.state.open });
    };
    handleClose = () => {
        this.setState({ open: false });
    };
    render() {
        const { classes, rtlActive } = this.props;
        
        const wrapper = classNames({
            [classes.wrapperRTL]: rtlActive
        });
        
        return (
            <div className={wrapper}>
                <Tooltip title="Manuale utente">
                    <Button href="/lib/mu_tcsign.pdf"
                        color="transparent"
                        aria-label="Help"
                        justIcon
                        className={`${classes.title} ${rtlActive ? classes.buttonLinkRTL : classes.buttonLink}`}
                        muiClasses={{
                            label: rtlActive ? classes.labelRTL : ""
                        }}

                    >
                        <Help
                            className={
                                classes.headerLinksSvg +
                                " " +
                                (rtlActive
                                    ? classes.links + " " + classes.linksRTL
                                    : classes.links)
                            }
                        />
                        <Hidden mdUp>
                            <span className={classes.linkText}>
                                {rtlActive ? "Ø§Ù„Ù…Ù„Ù Ø§Ù„Ø´Ø®ØµÙŠ" : "Help"}
                            </span>
                        </Hidden>
                    </Button>
                </Tooltip>
            </div>
        );
    }
}

HeaderLinks.propTypes = {
    classes: PropTypes.object.isRequired,
    rtlActive: PropTypes.bool
};

export default withStyles(headerLinksStyle)(HeaderLinks);
