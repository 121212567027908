import React from 'react';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { css } from 'emotion';
import { ClipLoader } from 'react-spinners';
import _ from 'lodash';

// @material-ui/core components
import { Paper, withStyles } from '@material-ui/core';

// @material-ui/icons
import { ViewList } from '@material-ui/icons';
import CheckDocument from '@material-ui/icons/AssignmentTurnedIn';
import SignDocument from '@material-ui/icons/BorderColor';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import GridItem from 'components/Grid/GridItem.jsx';

// custom components
import RaccoglitoreModalForm from '../../Custom/Raccoglitore/RaccoglitoreModalForm.jsx';
import DocumentoModalForm from '../../Custom/Documento/DocumentoModalForm.jsx';
import FileModalForm from '../../Custom/Documento/FileModalForm.jsx';
import ListaDocumenti from 'Custom/Documento/ListaDocumenti.jsx';
import FilterSwitch from 'Custom/Documento/FilterSwitch.jsx';
import RicercaIndicizzata from 'Custom/Documento/Ricerca/RicercaIndicizzata.jsx';
import SelectSearch from 'Custom/SelectSearch.jsx';

import {
    raccoglitoriActions,
    utilsActions,
    documentiActions,
    alertActions,
    userActions,
    searchActions
} from '../../Actions';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

const overlay = css`
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(18, 37, 19, 0.35); /* Black w/ opacity */
`;

const override = css`
    display: block !important;

    border-color: red;
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

const styles = {
    ...extendedFormsStyle,
    tc_sign_btn_confirm: {
        'background-color': '#4caf50 !important',
    },
    tcsign_delegante: {
        padding: '20px!important',
        marginBottom: '50px',
    },
};

class DocumentsTables extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            documento: undefined,
            uuidDocumento: undefined,
            showFile: false,
            showRaccoglitore: false,
            showDocumento: false,
            selectedDocumento: undefined,
            selectedRaccoglitore: undefined,
            filters: [false, false],
        };
    }

    componentDidMount() {
        localStorage.removeItem('filters');
        this.getData();
        let ambito = this.getAmbito();
        this.props.dispatch(searchActions.setAmbito(ambito, false));
    }

    getData() {
        const { user } = this.props;
        let wFilter = JSON.parse(localStorage.getItem('filters'));
        let ambito = this.getAmbito();

        if (wFilter === null) {
            wFilter = {
                query: {
                    must: [],
                    range: {},
                },
                ambito,
                from: 0,
                size: 100,
                sort: [
                    {
                        data_ult_modif: { order: 'desc' },
                    },
                ],
            };
        }
        this.props.dispatch(documentiActions.getAll(wFilter, user.ruoli));
        this.props.dispatch(documentiActions.countValidatedDoc());
        this.props.dispatch(raccoglitoriActions.getAll());
        this.props.dispatch(utilsActions.getUnitaOrganizzative());
        this.props.dispatch(utilsActions.getTipologiaDocumento());
        this.props.dispatch(utilsActions.getTipologiaRaccoglitore());
        this.props.dispatch(utilsActions.getStatoDocumento());
        this.props.dispatch(utilsActions.getSoggettiOriginatori());
        this.props.dispatch(userActions.getAll());
    }

    getAmbito() {
        const { pathname } = this.props.location;
        if (pathname === '/auth/mydocuments') return 'Created';
        else if (pathname === '/auth/flagged') return 'Attentioned';
        else if (pathname === '/auth/refused') return 'Rejected';
        else if (pathname === '/auth/signedchecked') return 'Signed';
        else if (pathname === '/auth/delegated') return 'Delegati';
        else if (pathname === '/auth/incoming') return 'Incoming';
        else if (pathname === '/auth/deleted') return 'Deleted';

        return '';
    }

    handleClickFilter = (event, index) => {
        const { checked } = event.target;

        const filters = [false, false];
        filters[index] = checked;

        this.setState({
            filters,
        });

        this.handleClickSearch(filters);
    };

    handleClickSearch = (switchVal) => {
        const { user } = this.props;
        let filters = {
            query: {
                must: [],
                range: {},
            },
            ambito: '',
            from: 0,
            size: 100,
            sort: [
                {
                    data_ult_modif: { order: 'desc' },
                },
            ],
        };

        filters.ambito = this.getAmbito();

        const must = [];

        //parameter coming from handleClickFilter
        if (switchVal) {
            if (switchVal[0]) {
                must.push({
                    term: { id_stato_flusso_op: 3 },
                });
            } else if (switchVal[1]) {
                must.push({
                    term: { id_stato_flusso_op: 4 },
                });
            }
        }
        filters.query.must = must;

        this.props.dispatch(documentiActions.search(filters, user.ruoli));
    };

    checkViewToolbar = () => {
        const { pathname } = this.props.location;
        return !(
            pathname === '/auth/flagged' ||
            pathname === '/auth/refused' ||
            pathname === '/auth/signedchecked' ||
            pathname === '/auth/delegated' ||
            pathname === '/auth/incoming' ||
            pathname === '/auth/deleted'
        );
    };

    handleClickOpenDocumento = (idDocumento, uuid) => {
        this.setState({
            showDocumento: true,
            selectedDocumento: idDocumento,
            uuidDocumento: uuid,
        });
    };

    closeFileViewer = () => {
        this.setState({
            showFile: false,
        });
    };

    handleClickOpenRaccoglitore = (idRaccoglitore) => {
        this.setState({
            showRaccoglitore: true,
            selectedRaccoglitore: idRaccoglitore,
        });
        this.props.dispatch(raccoglitoriActions.resetRaccoglitore());
    };

    handleClickOpenFile = (doc) => {
        const { documenti } = this.props;

        if (doc.id_riunione && doc.id_argomento) {
            /*this.props.dispatch(documentiActions.getMeetingDetails(doc.id_riunione))
            this.props.dispatch(documentiActions.getTopicDetails(doc.id_riunione, doc.id_argomento))*/
            this.props.dispatch(
                documentiActions.getMeetingDetails(documenti.sessionId, doc.id_riunione, doc.id_argomento)
            );
        }
        console.log('doc :>> ', doc);
        this.setState({
            showFile: true,
            uuidDocumento: doc.uuid,
            documento: doc,
        });
    };

    handleCloseAlert = () => {
        this.props.dispatch(alertActions.clear());
    };

    handleClickAction = (documento) => {
        if (documento.id_stato_flusso === 1) {
            this.setState({
                showModalFirma: true,
                selectedDocuments: [documento.id],
            });
        } else if (documento.id_stato_flusso === 2) {
            this.setState({
                showModalVisto: true,
                selectedDocuments: [documento.id],
            });
        }
    };

    handleClickCloseRaccoglitore = () => {
        this.setState({
            showRaccoglitore: false,
            selectedRaccoglitore: undefined,
        });
    };

    handleClickCloseDocumento = () => {
        this.setState({
            showDocumento: false,
            selectedDocumento: undefined,
        });
    };

    handleClickCloseFile() {
        this.setState({
            showFile: false,
            selectedDocumento: undefined,
        });
        this.props.dispatch(documentiActions.getTaskList());
    }

    initUnitaOrganizzative(listUO) {
        return listUO.map((prop, key) => {
            if (typeof prop.children === 'undefined') return prop;
            else return this.initUnitaOrganizzative(prop.children);
        });
    }

    handleClickFlagDocumento(idDocumento, isAttenzionato) {
        this.props.dispatch(
            documentiActions.impostaAttenzionato({
                id: idDocumento,
                attenzionato: !isAttenzionato,
            })
        );
    }

    handleSelectDelegato(event) {
        const { value, label, other } = event.target;
        console.log('Utente delegante selezionato ' + value);
        this.props.dispatch(documentiActions.addDelegante(value, label, other));
        this.handleClickSearch();
    }

    render() {
        const { classes, documenti, alert, user, tema } = this.props;
        const { documento, uuidDocumento, showDocumento, selectedDocumento, showFile } = this.state;
        const { selectedRaccoglitore, showRaccoglitore, filters } = this.state;
        const {ruoli} = user;

        let cardColor = tema.theme_color;
        let operaiRule = false;
        let validatedDocuments = documenti.countValidatedDoc;
        let validatedDocumentsMsg = `Numero documenti validati: ${validatedDocuments}`; 

        if(ruoli.filter((rl) => rl.cod_ruolo === 'OPERAI').length > 0) operaiRule = true;

        const resultFuncClass = {
            readOnly: this.checkViewToolbar(),
            closeFileViewer: this.closeFileViewer,
            handleClickOpenRaccoglitore: this.handleClickOpenRaccoglitore,
            handleClickOpenDocumento: this.handleClickOpenDocumento,
            handleClickOpenFile: this.handleClickOpenFile,
        };

        let alertMessage = '';

        if (alert?.message) {
            if (alert.type === 'alert-success') {
                alertMessage = (
                    <SweetAlert success title="Info" onConfirm={() => this.handleCloseAlert()}>
                        {alert.message}
                    </SweetAlert>
                );
            } else if (alert.type === 'alert-danger') {
                alertMessage = (
                    <SweetAlert
                        danger
                        title="Attenzione"
                        onConfirm={() => {
                            this.handleCloseAlert();
                            if (alert.message === 'Sessione scaduta, eseguire il login.') {
                                this.props.dispatch(userActions.logout());
                            }
                        }}>
                        {alert.message}
                    </SweetAlert>
                );
            }
        }

        let loading;
        if (documenti?.loading) {
            loading = (
                <div className={overlay}>
                    <ClipLoader
                        className={override}
                        sizeUnit={'px'}
                        size={80}
                        color={'#3a783d'}
                        loading={documenti.loading}
                    />
                </div>
            );
        }

        let wFormRaccoglitore = showRaccoglitore && (
            <RaccoglitoreModalForm
                title={!selectedRaccoglitore ? 'Nuovo Percorso Firma' : 'Id Percorso Firma: ' + selectedRaccoglitore}
                open={showRaccoglitore}
                id={selectedRaccoglitore}
                keepMounted
                onClose={() => this.handleClickCloseRaccoglitore()}
                aria-labelledby="notice-modal-slide-title"
                aria-describedby="notice-modal-slide-description"
            />
        );

        let wFormDocumento = showDocumento && (
            <DocumentoModalForm
                title={!selectedDocumento ? 'Nuovo documento' : 'Id Documento: ' + selectedDocumento}
                id={selectedDocumento}
                readonly={this.checkViewToolbar()}
                uuid={uuidDocumento}
                onClose={() => this.handleClickCloseDocumento()}
            />
        );

        let wFormFile = showFile && (
            <FileModalForm
                open={showFile}
                uuid={uuidDocumento}
                documento={documento}
                listType='items'
                onClose={() => this.handleClickCloseFile()}
                onSubmitSign={() => this.handleClickAction(documento)}
                onSubmitCheck={() => this.handleClickAction(documento)}
            />
        );

        let delegaOption = [];
        let utenteDelegatiRow;
        if (user?.utente_deleganti?.length > 0) {
            utenteDelegatiRow = _.find(user.utente_deleganti, function (o) {
                return o.tipo_delega === 'V' || o.tipo_delega === 'E';
            });
            if (utenteDelegatiRow)
                for (var i = 0; i < utenteDelegatiRow.length; i++) {
                    var option = {};
                    var labelDelega = utenteDelegatiRow[i].tipo_delega === 'E' ? ' (ereditato per delega)' : '';
                    option['label'] = utenteDelegatiRow[i].utente + labelDelega;
                    option['value'] = utenteDelegatiRow[i].id_utente;
                    option['other'] = utenteDelegatiRow[i].tipo_delega;
                    delegaOption.push(option);
                }
        }

        let delega =
            delegaOption.length > 0 ? (
                <Paper className={classes.tcsign_delegante}>
                    <SelectSearch
                        label={'Verifica documenti per conto di'}
                        placeholder={'Selezionare utente'}
                        options={delegaOption}
                        isClearable={true}
                        id={'id_utente_delegante'}
                        isSearchable={true}
                        value={documenti.deleganteSelected.id_delegante}
                        onChange={(e) => this.handleSelectDelegato(e)}
                    />
                </Paper>
            ) : null;

        const switchStyle = {
            width: '24px',
            height: '24px',
            marginRight: '5px',
            marginLeft: '5px',
        };

        const switchList = [
            {
                title: 'Firmati',
                color: 'success',
                icon: <SignDocument style={switchStyle} />,
                switchColor: 'green',
                checked: filters[0],
                onChange: (event) => this.handleClickFilter(event, 0),
            },
            {
                title: 'Vistati',
                color: 'info',
                icon: <CheckDocument style={switchStyle} />,
                switchColor: 'blue',
                checked: filters[1],
                onChange: (event) => this.handleClickFilter(event, 1),
            },
        ];

        return (
            <div>
                {delega}
                <GridContainer>
                    {loading}
                    {alertMessage}
                    {wFormRaccoglitore}
                    {wFormDocumento}
                    {wFormFile}
                    <RicercaIndicizzata resultFuncClass={resultFuncClass} operaiRule={operaiRule}/>
                    <ListaDocumenti
                        validatedDocumentsMsg={validatedDocumentsMsg}
                        readOnly={this.checkViewToolbar()}
                        temaColor={cardColor}
                        closeFileViewer={this.closeFileViewer}
                        handleClickOpenRaccoglitore={this.handleClickOpenRaccoglitore}
                        handleClickOpenDocumento={this.handleClickOpenDocumento}
                        handleClickOpenFile={this.handleClickOpenFile}
                    />
                </GridContainer>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { alert, authentication, documenti, tema } = state;
    return {
        user: authentication?.user ?? {},
        alert,
        documenti,
        tema
    };
}

const connectedDocumentsGrid = connect(mapStateToProps)(withStyles(styles)(DocumentsTables));
export default connectedDocumentsGrid;
