import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from 'classnames';

import customInputStyle from 'assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx';

function CustomInput({ ...props }) {
    const { classes, id, error, white, success } = props;
    const { formControlProps, labelText, labelProps, inputProps, inputRootCustomClasses } = props;

    const labelClasses = classNames({
        [' ' + classes.labelRootError]: error,
        [' ' + classes.labelRootSuccess]: success && !error,
    });
    const underlineClasses = classNames({
        [classes.underlineError]: error,
        [classes.underlineSuccess]: success && !error,
        [classes.underline]: true,
        [classes.whiteUnderline]: white,
    });
    const marginTop = classNames({
        [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
    });
    const inputClasses = classNames({
        [classes.input]: true,
        [classes.whiteInput]: white,
    });
    const shrinkCustom = classNames({
        [classes.shrink]: true,
    });
    var formControlClasses;
    if (formControlProps !== undefined) {
        formControlClasses = classNames(formControlProps.className, classes.formControl);
    } else {
        formControlClasses = classes.formControl;
    }
    var feedbackClasses = classes.feedback;
    if (inputProps !== undefined) {
        if (inputProps.endAdornment !== undefined) {
            feedbackClasses = feedbackClasses + ' ' + classes.feedbackRight;
        }
    }

    return (
        <FormControl {...formControlProps} className={formControlClasses}>
            {labelText !== undefined ? (
                <InputLabel classes={{ shrink: shrinkCustom }} className={classes.labelRoot + ' ' + labelClasses} htmlFor={id} {...labelProps}>
                    {labelText}
                </InputLabel>
            ) : null}
            <Input
                classes={{
                    input: inputClasses,
                    root: marginTop,
                    disabled: classes.disabled,
                    underline: underlineClasses,
                }}
                id={id}
                name={id}
                {...inputProps}
                autoComplete="new-password"
            />
            {error ? (
                <Clear className={feedbackClasses + ' ' + classes.labelRootError} />
            ) : success ? (
                <Check className={feedbackClasses + ' ' + classes.labelRootSuccess} />
            ) : null}
        </FormControl>
    );
}

CustomInput.propTypes = {
    classes: PropTypes.object.isRequired,
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool,
};

export default withStyles(customInputStyle)(CustomInput);
