import { concat } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

const SIGNATURE_BOX_WIDTH = 250;
const SIGNATURE_BOX_HEIGHT = 65;

/**
 * - Oftentimes a string doesn't completly fit in a its "box" -
 * A method to format a string in a more user friendly way.
 * @param {String} String to be formatted
 * @param {Number} length
 * @returns a segment of the string passed as param
 */

export const formatString = (string, maxLength) => {
    return capitalizeFirstLetter(string)
        .substring(0, maxLength)
        .concat(string.length <= maxLength ? '' : '...');
};

/**
 * return a String with the first letter of every word capitalized
 * @param {string} string - string to be capitalized
 */
export const capitalizeFirstLetter = (string) => {
    return string
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

/**
 * return screen pixels in pdf pixels based on params
 * @param {Number} CANVAS_WIDTH - Canvas width
 * @param {Number} CANVAS_HEIGHT - Canvas height
 * @param {Number} PDF_WIDTH - Pdf original width
 * @param {Number} PDF_HEIGHT - Pdf original height
 * @param {Object} coordinates - Object containing coordinates x and y
 */
export const toPDFPixels = (CANVAS_WIDTH, CANVAS_HEIGHT, PDF_WIDTH, PDF_HEIGHT, coordinates) => {
    const PDF_BOX_HEIGHT = (SIGNATURE_BOX_HEIGHT * PDF_HEIGHT) / CANVAS_HEIGHT;
    let x = (coordinates.x * PDF_WIDTH) / CANVAS_WIDTH;
    let y = ((CANVAS_HEIGHT - coordinates.y) * PDF_HEIGHT) / CANVAS_HEIGHT - PDF_BOX_HEIGHT;

    return {
        x,
        y,
    };
};

/**
 * return pdf pixels from screen pixels based on params
 * @param {Number} CANVAS_WIDTH - Canvas width
 * @param {Number} CANVAS_HEIGHT - Canvas height
 * @param {Number} PDF_WIDTH - Pdf original width
 * @param {Number} PDF_HEIGHT - Pdf original height
 * @param {Object} coordinates - Object containing coordinates x and y
 **/
export const toScreenPixels = (CANVAS_WIDTH, CANVAS_HEIGHT, PDF_WIDTH, PDF_HEIGHT, coordinates) => {
    let x = (coordinates.x * CANVAS_WIDTH) / PDF_WIDTH;
    let y = CANVAS_HEIGHT - (coordinates.y * CANVAS_HEIGHT) / PDF_HEIGHT - SIGNATURE_BOX_HEIGHT;

    return {
        x,
        y,
    };
};

/**
 * return a list with all signers who don't belong (internally) to the organization
 * @param {Array} firmatari - Array of all signers belonging to a "signer path"
 */
export const getListaFirmatariEsterni = (firmatari) => {
    let result = [];
    if (firmatari?.length > 0) {
        firmatari.forEach((f) => {
            if (f.id_contatto_owner && f.tipo_firma !== 'FD') {
                result.push(f);
            }
        });

        return result;
    } else return;
};

/**
 * return a list with all signers who don't belong (internally) to the organization
 * @param {Array} firmatari - Array of all signers belonging to a "signer path"
 */
export const getListaFirmatariInterni = (firmatari) => {
    let result = [];
    if (firmatari?.length > 0) {
        firmatari.forEach((f) => {
            if (f.id_uo_owner && f.flag_firma_vista === 'F') {
                result.push(f);
            }
        });

        return result;
    } else return;
};

/**
 * return the coordinates on the position relative to the current pdf size
 * @param {Object}documenti - the full documenti obj (found in props)
 * @param {Object}dimensions - canvas dimensions
 */
export const adaptCoordToCurrentPDF = (documenti, dimensions) => {
    const { coordinate } = documenti.current;
    if (coordinate) {
        let coord = JSON.parse(JSON.stringify(coordinate));
        const { CANVAS_WIDTH, CANVAS_HEIGHT, PDF_WIDTH, PDF_HEIGHT } = dimensions;

        coord.forEach((tag) => {
            let screenCoords = toScreenPixels(CANVAS_WIDTH, CANVAS_HEIGHT, PDF_WIDTH, PDF_HEIGHT, {
                x: tag.coordinata_x,
                y: tag.coordinata_y,
            });

            tag.id = tag.id_coordinata; //uuidv4();
            tag.left = screenCoords.x;
            tag.top = screenCoords.y;

            delete tag.coordinata_x;
            delete tag.coordinata_y;
        });

        return coord;
    } else return [];
};

/**
 * return the name of a specific signer given his id
 * @param {Array} contatti - List of external signers
 * @param {Number} idContatto - id of the signer
 */
export const getFirmatario = (contatti, tag) => {
    let firmatario;
    if (tag.id_contatto)
        firmatario =
            contatti.filter((contatto) => contatto.id_contatto_owner === tag.id_contatto)[0]?.contatto_owner ?? '';
    else firmatario = contatti.filter((contatto) => contatto.id_utente_owner === tag.id_utente)[0]?.utente_owner ?? '';
    return capitalizeFirstLetter(firmatario);
};

/**
 * return true if there's at least 1 coordinate per signer, else false
 * @param {Array} tags - list of signing tags positioned
 * @param {Array} firmatariEsterni - list of external signers
 */

export const areExternalsPositioned = (tags, firmatariEsterni) => {
    for (let i = 0; i < firmatariEsterni.length; i++) {
        let firmatario = firmatariEsterni[i];
        let signerTags = tags.filter((tag) => tag.id_contatto === firmatario.id_contatto_owner);
        if (signerTags.length === 0) return false;
    }
    return true;
};

/**
 * return true if there's at least 1 coordinate per signer, else false
 * @param {Array} tags - list of signing tags positioned
 * @param {Array} firmatariInterni - list of internal signers
 */

export const areInternalsPositioned = (tags, firmatariInterni, areThereExternals) => {
    let checkDefault = [];
    for (let i = 0; i < firmatariInterni.length; i++) {
        let firmatario = firmatariInterni[i];

        let individualTags = tags.filter((tag) => tag.id_utente && tag.id_utente === firmatario.id_utente_owner)[0];
        let uoTags = tags.filter(tag => !tag.id_utente && !firmatario.id_utente_owner && tag.id_uo === firmatario.id_uo_owner)[0]

        if (individualTags) checkDefault.push(individualTags);
        if (uoTags) checkDefault.push(uoTags);
    }

    if (firmatariInterni.length === 0 || (checkDefault.length === 0 && !areThereExternals)) return null;
    else if (checkDefault.length === firmatariInterni.length) return true;
    else return false;
};

/**
 * return true if the arrays of objects are equal else false
 * @param {Array} array1 - 1st array
 * @param {Array} array2 - 2nd array
 */

export const areArraysOfObjectsEqual = (array1, array2) => {
    let equal = true;
    if (array1.length === array2.length) {
        for (let i = 0; i < array1.length; i++) {
            for (let key in array1[i]) {
                //console.log(key + ': ' + array1[i][key] + ' = ' + array2[i][key]);
                if (array1[i][key] !== array2[i][key]) return (equal = false);
            }
            if (!equal) return false;
        }
        return true;
    }

    return false;
};

/**
 * return true if the arrays of objects are equal else false
 * @param {Array} prevCoords - 1st array
 * @param {Array} currentCoords - 2nd array
 */

export const getModifiedCoord = (prevCoords, currentCoords) => {
    for (let i = 0; i < prevCoords.length; i++) {
        for (let key in prevCoords[i]) {
            //console.log(key + ': ' + array1[i][key] + ' = ' + array2[i][key]);
            if (prevCoords[i][key] !== currentCoords[i][key]) return currentCoords[i];
        }
    }
    return false;
};

export { SIGNATURE_BOX_WIDTH, SIGNATURE_BOX_HEIGHT };
