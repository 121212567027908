import { withRouter } from 'react-router-dom';
import Dashboard from 'views/Dashboard/TC_Dashboard.jsx';
import TCSignDocumenti from 'views/Tables/TCSign_Documenti.jsx';
import TCSignUtenti from 'views/Tables/TCSign_Utenti.jsx';
import TCSignUO from 'views/Tables/TCSign_UO.jsx';
import TCSignProfili from 'views/Tables/TCSign_Profili.jsx'; //CAMUSI 02072020
import TCSignRaccoglitori from 'views/Tables/TCSign_Raccoglitori.jsx';
import UserProfile from 'views/Pages/TCSign_UserProfile.jsx';
import TCSign_AS from 'views/Pages/TCSign_AS.jsx';
import Logout from '../views/Components/Logout';

// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import ContentPaste from '@material-ui/icons/FileCopy';
import Profile from '@material-ui/icons/AccountBox';
import Settings from '@material-ui/icons/Settings';
import Exit from '@material-ui/icons/PowerSettingsNew';
import Folder from '@material-ui/icons/Book';

import { ServiceMatrix } from 'Helpers/model-view.js';

var dashRoutes = [
    {
        icon: DashboardIcon,
        collapse: true,
        path: '/auth/grid',
        name: 'Bacheca documenti',
        state: 'openDashboard',
        roles: ServiceMatrix.LinkScrivania,
        codMenu: 'LNKSCR',
        views: [
            {
                path: '/auth/dashboard',
                name: 'Documenti da validare',
                mini: 'SC',
                component: Dashboard,
                roles: ServiceMatrix.LinkAttenzionati,
                codMenu: 'LNKTSC',
            },
            {
                path: '/auth/flagged',
                name: 'Documenti Attenzionati',
                mini: 'AT',
                component: withRouter(TCSignDocumenti),
                roles: ServiceMatrix.LinkAttenzionati,
                codMenu: 'LNKATZ',
            },
            {
                path: '/auth/signedchecked',
                name: 'Documenti Validati',
                mini: 'FV',
                component: TCSignDocumenti,
                roles: ServiceMatrix.LinkFirmatiVistati,
                codMenu: 'LNKDVF',
            },
            {
                path: '/auth/delegated',
                name: 'Documenti in Delega',
                mini: 'DE',
                component: TCSignDocumenti,
                roles: ServiceMatrix.LinkFirmatiVistati,
                codMenu: 'LNKDDG',
            },
            {
                path: '/auth/refused',
                name: 'Documenti Rifiutati',
                mini: 'RI',
                component: TCSignDocumenti,
                roles: ServiceMatrix.LinkRifiutati,
                codMenu: 'LNKDRF',
            },
            {
                path: '/auth/incoming',
                name: 'Documenti in Arrivo',
                mini: 'IA',
                component: TCSignDocumenti,
                roles: ServiceMatrix.LinkInArrivo,
                codMenu: 'LNKDAR',
            },
        ],
    },
    {
        icon: ContentPaste,
        collapse: true,
        path: '/auth/grid',
        name: 'Documenti',
        state: 'openComponents',
        roles: ServiceMatrix.LinkAllDocuments,
        codMenu: 'LNKDOC',
        views: [
            {
                path: '/auth/grid',
                name: 'Tutti i Documenti',
                mini: 'AL',
                component: TCSignDocumenti,
            },
            {
                path: '/auth/mydocuments',
                name: 'I Miei Documenti',
                mini: 'MY',
                component: TCSignDocumenti,
                roles: ServiceMatrix.LinkMyDocuments,
                codMenu: 'LNKMYD',
            },
            {
                path: '/auth/deleted',
                name: 'Documenti Cancellati',
                mini: 'CA',
                component: TCSignDocumenti,
                roles: ServiceMatrix.LinkCancellati,
                codMenu: 'LNKDDL',
            },
        ],
    },
    // {
    //     path: '/auth/folders',
    //     name: 'Percorsi di Firma',
    //     icon: Folder,
    //     component: TCSignRaccoglitori,
    //     roles: ServiceMatrix.PercorsiFirma,
    //     codMenu: 'LNKPFR',
    // },
    {
        icon: Settings,
        collapse: true,
        path: '/auth/settings',
        name: 'Configurazione',
        state: 'openSettings',
        roles: ServiceMatrix.GestioneUtenti,
        codMenu: 'LNKCNF',
        views: [
            {
                path: '/auth/users',
                name: 'Gestione Utenti',
                mini: 'GU',
                codMenu: 'LNKGUT',
                component: TCSignUtenti,
            },
            {
                path: '/auth/uo',
                name: 'Gestione UO',
                mini: 'UO',
                codMenu: 'LNKGUO',
                component: TCSignUO,
            },
            {
                path: '/auth/profili',
                name: 'Gestione Profili',
                mini: 'GP',
                codMenu: 'LNKGPR',
                component: TCSignProfili,
            },
            {
                path: '/auth/as',
                name: 'Amministrazione Sito',
                mini: 'AS',
                codMenu: 'LNKAS',
                component: TCSign_AS,
            },
        ],
    },
    // {
    //     user: true,
    //     path: '/auth/edit-profile',
    //     name: 'Profilo utente',
    //     icon: Profile,
    //     component: UserProfile,
    // },
    {
        user: true,
        path: '/auth/logout',
        name: 'Logout',
        icon: Exit,
        component: Logout,
    },
    { redirect: true, path: '/', pathTo: '/auth/grid', name: 'TCSignDocumenti' },
];

export default dashRoutes;
