export const userConstants = {
    USERS_RESET: 'USERS_RESET',
    PROFILI_RESET: 'PROFILI_RESET', //CAMUSI 02072020
    ERROR_RESET: 'ERROR_RESET',

    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    UO_GETALL_REQUEST: 'UO_GETALL_REQUEST',
    UO_GETALL_SUCCESS: 'UO_GETALL_SUCCESS',
    UO_GETALL_FAILURE: 'UO_GETALL_FAILURE',

    UO_GET_REQUEST: 'UO_GET_REQUEST',
    UO_GET_SUCCESS: 'UO_GET_SUCCESS',
    UO_GET_FAILURE: 'UO_GET_FAILURE',

    //CAMUSI 06072020 inizio
    PROFILO_GET_REQUEST: 'PROFILO_GET_REQUEST',
    PROFILO_GET_SUCCESS: 'PROFILO_GET_SUCCESS',
    PROFILO_GET_FAILURE: 'PROFILO_GET_FAILURE',
    //CAMUSI 06072020 fine

    UO_SAVE_REQUEST: 'UO_SAVE_REQUEST',
    UO_SAVE_SUCCESS: 'UO_SAVE_SUCCESS',
    UO_SAVE_FAILURE: 'UO_SAVE_FAILURE',

    //CAMUSI 06072020 inizio
    PROFILO_SAVE_REQUEST: 'PROFILO_SAVE_REQUEST',
    PROFILO_SAVE_SUCCESS: 'PROFILO_SAVE_SUCCESS',
    PROFILO_SAVE_FAILURE: 'PROFILO_SAVE_FAILURE',
    //CAMUSI 06072020 fine

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    //CAMUSI 03072020 inizio
    PROFILI_GETALL_REQUEST: 'PROFILI_GETALL_REQUEST',
    PROFILI_GETALL_SUCCESS: 'PROFILI_GETALL_SUCCESS',
    PROFILI_GETALL_FAILURE: 'PROFILI_GETALL_FAILURE',
    //CAMUSI 03072020 fine

    USERS_GET_REQUEST: 'USERS_GET_REQUEST',
    USERS_GET_SUCCESS: 'USERS_GET_SUCCESS',
    USERS_GET_FAILURE: 'USERS_GET_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    UO_DELETE_REQUEST: 'UO_DELETE_REQUEST',
    UO_DELETE_SUCCESS: 'UO_DELETE_SUCCESS',
    UO_DELETE_FAILURE: 'UO_DELETE_FAILURE',

    PROFILO_DELETE_REQUEST: 'PROFILO_DELETE_REQUEST', //CAMUSI 03072020
    PROFILO_DELETE_SUCCESS: 'PROFILO_DELETE_SUCCESS', //CAMUSI 03072020
    PROFILO_DELETE_FAILURE: 'PROFILO_DELETE_FAILURE', //CAMUSI 03072020

    SAVE_FIRMA_REQUEST: 'SAVE_FIRMA_REQUEST',
    SAVE_FIRMA_SUCCESS: 'SAVE_FIRMA_SUCCESS',
    SAVE_FIRMA_FAILURE: 'SAVE_FIRMA_FAILURE',

    ADD_EDIT_FIRMA: 'ADD_EDIT_FIRMA',

    DELETE_FIRMA_REQUEST: 'DELETE_FIRMA_REQUEST',
    DELETE_FIRMA_SUCCESS: 'DELETE_FIRMA_REQUEST',
    DELETE_FIRMA_FAILURE: 'DELETE_FIRMA_FAILURE',

    DELETE_UTENTE_REQUEST: 'DELETE_UTENTE_REQUEST',
    DELETE_UTENTE_SUCCESS: 'DELETE_UTENTE_REQUEST',
    DELETE_UTENTE_FAILURE: 'DELETE_UTENTE_FAILURE',

    ATTIVA_DELEGATO_REQUEST: 'ATTIVA_DELEGATO_REQUEST',
    ATTIVA_DELEGATO_SUCCESS: 'ATTIVA_DELEGATO_SUCCESS',
    ATTIVA_DELEGATO_FAILURE: 'ATTIVA_DELEGATO_FAILURE',

    SAVE_DELEGATI_REQUEST: 'SAVE_DELEGATI_REQUEST',
    SAVE_DELEGATI_SUCCESS: 'SAVE_DELEGATI_SUCCESS',
    SAVE_DELEGATI_FAILURE: 'SAVE_DELEGATI_FAILURE',

    GET_USER_COMPANIES_REQUEST: 'GET_USER_COMPANIES_REQUEST',
    GET_USER_COMPANIES_SUCCESS: 'GET_USER_COMPANIES_SUCCESS',
    GET_USER_COMPANIES_FAILURE: 'GET_USER_COMPANIES_FAILURE',

    GET_ALL_COMPANIES_REQUEST: 'GET_ALL_COMPANIES_REQUEST',
    GET_ALL_COMPANIES_SUCCESS: 'GET_ALL_COMPANIES_SUCCESS',
    GET_ALL_COMPANIES_FAILURE: 'GET_ALL_COMPANIES_FAILURE',

    GET_ID_TENANT_REQUEST: 'GET_ID_TENANT_REQUEST',
    GET_ID_TENANT_FAILURE: 'GET_ID_TENANT_FAILURE',

    GET_TENANT_THEME_REQUEST: 'GET_TENANT_THEME_REQUEST',
    GET_TENANT_THEME_SUCCESS: 'GET_TENANT_THEME_SUCCESS',
    GET_TENANT_THEME_FAILURE: 'GET_TENANT_THEME_FAILURE',
    
    GET_UO_DEFAULT_REQUEST: 'GET_UO_DEFAULT_REQUEST',
    GET_UO_DEFAULT_SUCCESS: 'GET_UO_DEFAULT_SUCCESS',
    GET_UO_DEFAULT_FAILURE: 'GET_UO_DEFAULT_FAILURE',

};
