import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import User from "@material-ui/icons/AccountBalance";
import People from "@material-ui/icons/AccountBalance";
import Search from "@material-ui/icons/Search";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from 'react-redux';

import { userActions, alertActions, utilsActions } from '../../Actions';

import UOModalForm from "Custom/Profilo/UOModalForm.jsx";

import NavPills from "components/NavPills/NavPills.jsx";//CAMUSI 18062020
import Remove from '@material-ui/icons/RemoveCircle'; //CAMUSI 18062020
import Add from '@material-ui/icons/AddCircle'; //CAMUSI 18062020
import CheckboxTree from 'react-checkbox-tree'; //CAMUSI 18062020


import SweetAlert from "react-bootstrap-sweetalert";

import { ModelViewHelper, Authorize } from "Helpers/model-view.js";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

import { css } from 'emotion'
import { ClipLoader } from 'react-spinners';

const overlay = css`
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(18, 37, 19, 0.35); /* Black w/ opacity */
`;

const override = css`
    display: block!important;
    
    border-color: red;
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

const styles = {
    ...extendedFormsStyle,
    selectLabel: {
        fontSize: "14px",
        //textTransform: "uppercase",
        color: "#AAA !important",
        top: "8px",
    },
    tcsign_card: {
        "margin-top": "0px",
        "margin-bottom": "0px"
    },
    tcsign_cardbody: {
        "padding-top": "0",
        "padding-bottom": "0"
    },
    tcsign_action: {
        //"padding": "0px!important",
        //"margin": "0px!important",
        "width": "20px",
        "minWidth": "20px",
    },
    tcsign_warning: {
        top: "0",
        width: "18px",
        height: "18px",
        display: "inline-block",
        position: "relative",
        marginRight: "4px",
        verticalAlign: "middle"
    },
    tc_sign_btn_confirm: {
        "background-color": "#4caf50 !important"
    },
    tc_sign_datetime: {
        color: "#4caf50",
        top: "-2px",

        '& input.form-control': {
            backgroundImage: 'linear-gradient(#4caf50, #4caf50), linear-gradient(#D2D2D2, #D2D2D2)!important'
        },

        '& .rdtPicker .dow, .rdtPicker th.rdtSwitch, .rdtPicker th.rdtNext, .rdtPicker th.rdtPrev, .rdtPicker .rdtTimeToggle': {
            color: '#4caf50'
        }
    },
    tc_sign_searchSelectLabel: {
        fontSize: "10px",
        //textTransform: "uppercase",
        color: "#AAA !important",
        top: "8px",
        padding: "0px",
    },
    tc_sign_search_row: {
        paddingTop: "10px!important",
    },
};



class UOTables extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            listaUO: [],
            alberoUO: [],//CAMUSI 21062020
            alert: undefined,
            show: false,
            showUO: false,
            showDocumento: false,
            selectedDocumento: undefined,
            multipleSelect: [],
            closeInfo: false,
            ids_uo_visibili: undefined,//CAMUSI 20062020
            card_color: 'success'
        }

    }

    getData() {
        this.props.dispatch(userActions.getAllUO());
    }

    componentDidMount() {
        this.getData();
        this.props.dispatch(utilsActions.getUnitaOrganizzative());
    }

    componentDidUpdate() {

    }

    handleCloseAlert() {
        this.props.dispatch(alertActions.clear());


    }

    handleClickEditUO(idUtente) {

        var x = [];
        x["showUO"] = true;
        x["selectedUO"] = idUtente;
        this.setState(x);

    }

    handleClickDeleteUO(idUtente) {

        this.setState({
            confirmPanel: (
                <SweetAlert
                    info
                    showCancel
                    confirmBtnText="Ok"
                    confirmBtnCssClass={
                        this.props.classes.tc_sign_btn_confirm
                    }
                    cancelBtnText="Annulla"
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    title="Vuoi cancellare l'unit&agrave; organizzativa dal sistema ?"
                    onConfirm={e => {

                        this.props.dispatch(userActions.deleteUObyId(idUtente));

                        this.setState({ confirmPanel: undefined });

                    }
                    }
                    onCancel={() => { this.setState({ confirmPanel: undefined }); }}
                >
                </SweetAlert>
            )
        });
    }

    handleClickCloseUtente() {
        var x = [];
        x["showUO"] = false;
        x["selectedUO"] = undefined;
        this.setState(x);
    }

    //CAMUSI 18062020 inizio
    handleEspandiVisibilita() {
        const { utils } = this.props;

        let espandi = [];
        if (typeof (utils.unita_organizzative) !== "undefined") {
            espandi = utils.unita_organizzative.map((prop, key) => {
                return prop.id_uo_group;
            });

        }

        this.setState({ ids_uo_visibili_expanded: espandi });
    }

    handleComprimiVisibilita() {
        this.setState({ ids_uo_visibili_expanded: [] });
    }


    getFormFieldValue(pFieldName) {

        const { id, users, utils } = this.props;

        if (pFieldName === "ids_uo_visibili") {
            if (typeof (this.state.checked) === "undefined") {
                return (users && users.current && users.current.ids_uo_visibili) ? users.current.ids_uo_visibili.split(",") : [];
            }
            else {
                return this.state.checked;
            }
        }

        if (typeof (this.state[pFieldName]) !== "undefined") {
            return this.state[pFieldName];
        }
        else if (typeof (id) !== "undefined" && typeof (users.current) !== "undefined") {

            if (pFieldName === "ids_uo_visibili_expanded") {
                if (typeof (utils.unita_organizzative) !== "undefined") {
                    let appo = utils.unita_organizzative.map((prop, key) => {
                        return prop.id_uo_group;
                    });
                    return appo;
                }
            }

            let wReturn = ModelViewHelper.user_ModelToView(users.current);
            return wReturn[pFieldName];
        }

        if (pFieldName === "ruoli" ||
            pFieldName === "unita_organizzative" ||
            pFieldName === "notifiche") {
            return [];
        }
        else if (pFieldName === "ids_uo_visibili_expanded") {
            if (typeof (utils.unita_organizzative) !== "undefined") {
                let appo = utils.unita_organizzative.map((prop, key) => {
                    return prop.id_uo_group;
                });
                return appo;
            }
            else {
                return [];
            }
        }

        return "";
    }

    flatToHierarchy(flat) {

        let roots = [] // things without parent

        // make them accessible by guid on this map
        let all = {}

        flat.forEach(function (item) {

            //item.value = item.id_uo_group;
            //item.label = item.nome;
            //item.title = item.path;

            all[item.id_uo_group] = item
        });

        // connect childrens to its parent, and split roots apart
        Object.keys(all).forEach(function (id_uo_group) {
            let item = all[id_uo_group]
            if (item.parent_id === null) {
                roots.push(item)
            } else if (item.parent_id in all) {
                let p = all[item.parent_id]
                if (!('children' in p)) {
                    p.children = []
                }
                p.children.push(item)
            }
        });

        // done!
        return roots
    }
    //CAMUSI 18062020 fine

    render() {

        const { confirmPanel } = this.state;
        const { classes, users, alert, utils, tema } = this.props;
        const { unita_organizzative } = utils;
        let cardColor = tema.theme_color;

        let alertMessage = "";
        if (alert && typeof (alert.message) !== "undefined") {
            if (alert.type === "alert-success") {
                alertMessage = <SweetAlert success
                    title="Info"
                    onConfirm={() => this.handleCloseAlert()}>
                    {alert.message}
                </SweetAlert>;
            }
            else if (alert.type === "alert-danger") {
                alertMessage = <SweetAlert danger
                    title="Attenzione"
                    onConfirm={() => { this.handleCloseAlert(); if (alert.message === "Sessione scaduta, eseguire il login.") { this.props.dispatch(userActions.logout()); } }}>
                    {alert.message}
                </SweetAlert>;
            }
        }

        let listaUO = [];
        if (typeof (users.items) !== "undefined") {
            for (let i = 0; i < users.items.length; i++) {
                if (users.items[i].dismessa === false) {
                    listaUO.push(users.items[i]);
                }
            }
        }

        //CAMUSI 21062020 inizio
        let alberoUO = [];
        if (typeof (users.items) !== "undefined") {
            for (let i = 0; i < users.items.length; i++) {
                if (users.items[i].dismessa === false) {
                    alberoUO.push(users.items[i]);
                }
            }
        }
        //CAMUSI 21062020 fine

        let loading;
        if (users && users.loading) {
            loading = <div className={overlay}>
                <ClipLoader
                    className={override}
                    sizeUnit={"px"}
                    size={80}
                    color={'#3a783d'}
                    loading={users.loading}
                />
            </div>
        }

        let wUOModalForm = null;
        if (this.state.showUO) {
            wUOModalForm = <UOModalForm
                title={(typeof (this.state.selectedUO) === "undefined") ? "Nuova Unit\xE0 Organizzativa" : "UO: " + this.state.selectedUO}
                open={this.state.showUO}
                id={this.state.selectedUO}
                keepMounted
                onClose={() => this.handleClickCloseUtente()}
                aria-labelledby="notice-modal-slide-title"
                aria-describedby="notice-modal-slide-description"
            />
        }

        //CAMUSI 18062020 inizio
        let wUnitorganizzativeTree = [];
        if (typeof (unita_organizzative) !== "undefined") {
            let wUnitaOrganizzative = alberoUO.map(item => ({
                id_uo_group: item.id_uo_group,
                parent_id: item.parent_id,
                value: item.id_uo_group,
                label: item.nome,
                title: item.path,
                className: "tcSign_tree_item"
            }));
            wUnitorganizzativeTree = this.flatToHierarchy(wUnitaOrganizzative);
        }
        //CAMUSI 18062020 fine

        let wToolbarCreate = "";
        if (Authorize.checkAuthorizeFunc('FNCCMO')) {
            wToolbarCreate = (<div><Button
                color={cardColor}
                size="sm"
                style={{ float: "right" }}
                onClick={() => this.handleClickEditUO()}
            >
                <User /> Nuova Unit&agrave; Organizzativa
                </Button>
                {wUOModalForm}
            </div>);
        }

        //ICONA DETTAGLIO
        let dettaglioUO = "";
        if (this.state.checked && this.state.checked.length === 1) {
            dettaglioUO = <Tooltip
                id="tooltip-top"
                title="Modifica unit&agrave; organizzativa"
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
            >
                <Button
                    justIcon
                    round
                    simple
                    onClick={() => this.handleClickEditUO(this.state.checked)}
                    color="info"
                    className={classes.tcsign_action}>
                    <Search />
                </Button>
            </Tooltip>
        }

        //ICONA ELIMINAZIONE
        let eliminazioneUO = "";
        if (this.state.checked && this.state.checked.length === 1) {
            eliminazioneUO = <Tooltip
                id="tooltip-top"
                title="Elimina unit&agrave; organizzativaa"
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
            >
                <Button
                    justIcon
                    round
                    simple
                    onClick={() => this.handleClickDeleteUO(this.state.checked)}
                    color="danger"
                    className={classes.tcsign_action}>
                    <Close />
                </Button>
            </Tooltip>
        }

        return (

            //CAMUSI 18062020 inizio
            <GridContainer>
                {loading}
                {alertMessage}
                {confirmPanel}
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color={cardColor} icon>
                            <CardIcon color={cardColor}>
                                <People />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Unit&agrave; Organizzative</h4>
                            {/*<Can run="delete" on={this.props.todo}>*/}
                            {wToolbarCreate}
                            {/*</Can>*/}
                        </CardHeader>

                        <CardBody>
                            <NavPills
                                ref={this.navPills}
                                init={this.state.activeTab}
                                color={cardColor}

                                //CAMUSI 22062020 inizio tabs
                                tabs={[
                                    {

                                        //TAB Visualizzazione a lista
                                        tabButton: "Visualizzazione a lista",
                                        tabContent: (

                                            <ReactTable
                                                data={
                                                    listaUO.map((prop, key) => {
                                                        let wReturn = prop;
                                                        let wButtonEditUO = <Tooltip
                                                            id="tooltip-top"
                                                            title="Modifica unit&agrave; organizzativa"
                                                            placement="bottom"
                                                            classes={{ tooltip: classes.tooltip }}
                                                        >
                                                            <Button
                                                                justIcon
                                                                round
                                                                simple
                                                                onClick={() => this.handleClickEditUO(wReturn.id_uo_group)}
                                                                color="info"
                                                                className={classes.tcsign_action}>
                                                                <Search />
                                                            </Button>
                                                        </Tooltip>;

                                                        let wButtonRemoveUO = "";
                                                        if (Authorize.checkAuthorizeFunc('FNCEUO')) {
                                                            wButtonRemoveUO = <Tooltip
                                                                id="tooltip-top"
                                                                title="Elimina unit&agrave; organizzativa"
                                                                placement="bottom"
                                                                classes={{ tooltip: classes.tooltip }}
                                                            >
                                                                <Button
                                                                    justIcon
                                                                    round
                                                                    simple
                                                                    onClick={() => this.handleClickDeleteUO(wReturn.id_uo_group)}
                                                                    color="danger"
                                                                    className={classes.tcsign_action}>
                                                                    <Close />
                                                                </Button>
                                                            </Tooltip>;
                                                        }
                                                        wReturn.actions =
                                                            (
                                                                <div className="actions-center">
                                                                    {wButtonEditUO}
                                                                    {wButtonRemoveUO}
                                                                </div>
                                                            );
                                                        return wReturn;
                                                    })
                                                }
                                                filterable
                                                columns={[
                                                    {
                                                        Header: "ID",
                                                        accessor: "id_uo_group",
                                                        maxWidth: 50

                                                    },
                                                    {
                                                        Header: "Nome",
                                                        accessor: "nome",
                                                        filterMethod: (filter, row) =>
                                                            row[filter.id].toUpperCase().includes((filter.value.toUpperCase()))
                                                    },
                                                    {
                                                        Header: "Struttura",
                                                        accessor: "path",
                                                        filterMethod: (filter, row) =>
                                                            row[filter.id].toUpperCase().includes((filter.value.toUpperCase()))
                                                    },
                                                    {
                                                        Header: "Responsabile",
                                                        accessor: "responsabile",
                                                        filterMethod: (filter, row) =>
                                                            row[filter.id].toUpperCase().includes((filter.value.toUpperCase()))
                                                    },
                                                    {
                                                        Header: "Cod. integrazione",
                                                        accessor: "cod_integrazione",
                                                        filterMethod: (filter, row) =>
                                                            row[filter.id].toUpperCase().includes((filter.value.toUpperCase()))
                                                    },
                                                    {
                                                        Header: "Azioni",
                                                        accessor: "actions",
                                                        sortable: false,
                                                        filterable: false,
                                                        maxWidth: 200,
                                                    }
                                                ]}

                                                defaultPageSize={10}
                                                showPaginationTop={false}
                                                showPaginationBottom
                                                className="-striped -highlight"
                                                striped
                                                hover
                                                condensed
                                                pagination
                                                insertRow
                                                deleteRow
                                                search
                                                // Text
                                                previousText='Precedente'
                                                nextText='Successiva'
                                                loadingText='In caricamento...'
                                                noDataText='Nessuna unit&agrave; organizzativa trovata'
                                                pageText='Pagina'
                                                ofText='di'
                                                rowsText='righe'
                                            />


                                        )
                                    },

                                    //CAMUSI 22062020 TAB Visualizzazione ad albero
                                    {
                                        tabButton: "Visualizzazione ad albero",
                                        tabContent: (
                                            <GridContainer style={{ width: "100%" }}
                                                direction="column"
                                            >
                                                <GridItem xs={12} sm={12} md={12}>

                                                    <Tooltip
                                                        id="tooltip-top"
                                                        title="Espandi albero"
                                                        placement="bottom"
                                                        classes={{ tooltip: classes.tooltip }}
                                                    //style={{ float: "left" }}
                                                    ><Button
                                                        simple
                                                        style={{ padding: "0px", margin: "25px" }}
                                                        onClick={this.handleEspandiVisibilita.bind(this)}
                                                        color="info"
                                                        className={classes.tcsign_action}>
                                                            <Add />Espandi
                                    </Button></Tooltip>

                                                    <Tooltip
                                                        id="tooltip-top"
                                                        title="Comprimi albero"
                                                        placement="bottom"
                                                        classes={{ tooltip: classes.tooltip }}
                                                    //style={{ float: "right" }}
                                                    ><Button
                                                        style={{ padding: "0px", margin: "50px" }}
                                                        simple
                                                        onClick={this.handleComprimiVisibilita.bind(this)}
                                                        color="info"
                                                        className={classes.tcsign_action}>
                                                            <Remove />Comprimi
                                    </Button></Tooltip>

                                                    {dettaglioUO}
                                                    {eliminazioneUO}

                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <CheckboxTree
                                                        icons={{
                                                            leaf: "",
                                                            parentClose: "",
                                                            parentOpen: "",
                                                            //checkbox:"",
                                                        }}
                                                        showExpandAll={true}
                                                        showNodeTitle={true}
                                                        nodes={wUnitorganizzativeTree}
                                                        checked={this.getFormFieldValue("ids_uo_visibili")}
                                                        expanded={this.getFormFieldValue("ids_uo_visibili_expanded")}
                                                        noCascade={true}
                                                        onCheck={checked => this.setState({ checked })}
                                                        onExpand={expanded => this.setState({ ids_uo_visibili_expanded: expanded })}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        )
                                    },
                                ]}
                            />
                        </CardBody>

                    </Card>
                </GridItem>
            </GridContainer>

        );//chiusura return

    }
}


function mapStateToProps(state) {
    const { alert, authentication, users, utils, tema } = state;
    const { user } = authentication ?? {};
    return {
        user,
        users,
        alert,
        utils,
        tema
    };
}

const connectedUOTables = connect(mapStateToProps)(withStyles(styles)(UOTables));
export default connectedUOTables;