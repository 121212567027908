import {
  warningCardHeader,
  successCardHeader,
  bordeauxCardHeader,
  bluNavyCardHeader,
  azzurroCardHeader,
  gialloCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  tc_loginCardHeader
} from "assets/jss/material-dashboard-pro-react.jsx";
const cardIconStyle = {
  cardIcon: {
    "&$warningCardHeader,&$successCardHeader,&$bordeauxCardHeader,&$bluNavyCardHeader,&$gialloCardHeader,&$azzurroCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader,&$tc_loginCardHeader": {
      borderRadius: "3px",
      backgroundColor: "#999",
      padding: "15px",
      marginTop: "-20px",
      marginRight: "15px",
      float: "left"
    }
  },
  warningCardHeader,
  successCardHeader,
  bordeauxCardHeader,
  bluNavyCardHeader,
  azzurroCardHeader,
  gialloCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  tc_loginCardHeader
};

export default cardIconStyle;
