import React from "react";

import { connect } from "react-redux";

import Button from "components/CustomButtons/Button.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Table from "components/Table/Table.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";

import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";
import Up from "@material-ui/icons/ArrowUpward";
import Down from "@material-ui/icons/ArrowDownward";

import FirmatarioModalForm from "./FirmatarioModalForm.jsx";
import ContattoEsternoModalForm from "./ContattoEsternoModalForm.jsx";
import SelectSearch from "Custom/SelectSearch.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Folder from "@material-ui/icons/Folder";

import FormValidationHelper from "../../Helpers/form-validation.js";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

import { raccoglitoriActions, alertActions, userActions, utilsActions } from "../../Actions";

import { css } from "emotion";
import { ClipLoader } from "react-spinners";

import { ModelViewHelper, Authorize, Constants } from "Helpers/model-view.js";

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

const extSignDisabled = process.env.REACT_APP_EXT_SIGN_DISABLED === 'true' ? true : false;

const styles = {
    ...extendedFormsStyle,
    selectLabel: {
        fontSize: "14px",
        //textTransform: "uppercase",
        color: "#AAA !important",
        top: "8px",
    },
    tcSignSelectControl: {
        margin: "7px 1px 0px 0px !important",
    },
    tcSign_hidden_col: {
        display: "none!important"
    },
    tc_sign_required_div: {
        "opacity": "1",
        "font-size": "14px",
        "font-family": '"Roboto", "Helvetica", "Arial", sans-serif',
        "color": "#9a9898",
        "font-weight": "400",
        "line-height": "1.42857"
    }



};

const overlay = css`
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(18, 37, 19, 0.35); /* Black w/ opacity */
`;

const override = css`
    display: block !important;

    border-color: red;
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

const initialState = {
    direzione: "1",
    closeAlert: false,

    id: undefined,
    priorita: undefined,
    tipo_percorso: undefined,
    nome: undefined,
    nomeState: undefined,
    descrizione: undefined,
    descrizioneState: undefined,
    submitted: false,
    showModalFirmatario: false,
    percorsi: undefined,
    id_titolario_classificazione: undefined,
    showModalFirmatarioEsterno: false,
};

class RaccoglitoreModalForm extends Dialog {
    constructor(props, context) {
        super(props, context);

        this.state = initialState;
    }

    getData(idRaccoglitore) {
        if (idRaccoglitore)
            this.props.dispatch(raccoglitoriActions.getRaccoglitoreById(idRaccoglitore));
    }

    componentDidMount() {
        const { id, raccoglitori, utils } = this.props;

        this.props.dispatch(userActions.getAll());



        if (
            (typeof raccoglitori === "undefined" ||
                typeof raccoglitori.loading === "undefined" ||
                !raccoglitori.loading) &&
            typeof id !== "undefined" &&
            typeof raccoglitori.current === "undefined"
        ) {
            this.getData(this.props.id);
        }
        else {
            if (utils?.tipologia_raccoglitore?.length) {
                let titolario_default;
                for (var y = 0; y < utils.tipologia_raccoglitore.length; y++) {
                    if (utils.tipologia_raccoglitore[y].default_value === true) {
                        titolario_default = utils.tipologia_raccoglitore[y].id_titolario_classificazione;
                    }
                }
                this.setState({ id_titolario_classificazione: titolario_default });
            }
        }
    }

    handleSelect = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleOpenModalFirmatario() {
        this.setState({
            showModalFirmatario: true,
        });
    }

    handleOpenModalFirmatarioEsterno() {
        const { user } = this.props;
        this.setState({
            showModalFirmatarioEsterno: true,
        });
        if (user.unita_organizzative && user.unita_organizzative.length === 1) {
            this.props.dispatch(utilsActions.getContattiEsterniByUo(user.unita_organizzative[0].id_uo_group));
            this.props.dispatch(utilsActions.selectUo(user.unita_organizzative[0]));
        }
    }

    handleSubmitModalFirmatario(value) {
        let wPercorsoFirma = this.getFormFieldValue("percorsi");

        let tipo_firma = value.firma_visto === "V" ? "V" : "FD";
        let wFirmatario = {
            id_utente_owner: value.id_utente,
            id_uo_owner: value.id_uo,
            flag_firma_vista: Constants.percorso.label_firma[value.firma_visto === "V" ? "Visto" : "Firma"],
            ordinamento: wPercorsoFirma.length + 1,
            utente_owner: value.nome_utente,
            uo_owner: value.nome_uo,
            utente_ult_modif: "",
            tipo_firma
        };
        //CAMUSI controllo per evitare di aggiungere più di una volta stessa coppia utente/uo nel percorso di firma
        for (var i = 0; i < wPercorsoFirma.length; i++) {
            if (
                wPercorsoFirma[i].id_utente_owner === wFirmatario.id_utente_owner &&
                wPercorsoFirma[i].id_uo_owner === wFirmatario.id_uo_owner
            ) {
                this.props.dispatch(
                    alertActions.error("Attenzione coppia UTENTE/UNITA' ORGANIZZATIVA già presente nel Percorso Firma")
                );
                return;
            }
        }

        wPercorsoFirma.push(wFirmatario);

        this.setState({
            showModalFirmatario: false,
            percorsi: wPercorsoFirma,
        });
    }

    handleSubmitModalFirmatarioEsterno(value) {
        const { user } = this.props;
        let wPercorsoFirma = this.getFormFieldValue("percorsi");

        const { fea, firma_digitale } = value.tipo_firma
        const tipo_firma = fea && firma_digitale ? "FD_FEA" : fea && !firma_digitale ? "FEA" : "FD"

        let wFirmatario = {
            id_contatto_owner: value.id_contatto,
            flag_firma_vista: Constants.percorso.label_firma[value.firma_visto === "V" ? "Visto" : "Firma"],
            ordinamento: wPercorsoFirma.length + 1,
            contatto_owner: value.nome_contatto,
            utente_ult_modif: user.nome + " " + user.cognome,
            tipo_firma
        };

        //CAMUSI controllo per evitare di aggiungere più di una volta stessa coppia utente/uo nel percorso di firma
        for (var i = 0; i < wPercorsoFirma.length; i++) {
            if (wPercorsoFirma[i].id_contatto_owner === wFirmatario.id_contatto_owner) {
                this.props.dispatch(alertActions.error("Attenzione CONTATTO ESTERNO già presente nel Percorso Firma"));
                return;
            }
        }

        wPercorsoFirma.push(wFirmatario);

        this.setState({
            showModalFirmatarioEsterno: false,
            percorsi: wPercorsoFirma,
        });
    }

    handleCloseModalFirmatario() {
        this.setState({
            showModalFirmatario: false,
        });
    }

    handleCloseModalFirmatarioEsterno() {
        this.setState({
            showModalFirmatarioEsterno: false,
        });
        this.props.dispatch(utilsActions.unselectUo());
        this.props.dispatch(utilsActions.unselectContacts());
    }

    handleCloseAlert() {
        this.props.dispatch(alertActions.clear());
    }

    handleClose() {
        this.setState(initialState);
        this.props.dispatch(raccoglitoriActions.resetRaccoglitore());
        this.props.onClose();
    }

    handleAction(key, row) {
        let wCurrentPercorsoFirma = this.getFormFieldValue("percorsi");

        for (var i = 0; i < wCurrentPercorsoFirma.length; i++) {
            if (wCurrentPercorsoFirma[i].ordinamento === row.idRow) {
                if (key.key === 0 && i !== 0) {
                    let temp = wCurrentPercorsoFirma[i - 1];
                    wCurrentPercorsoFirma[i - 1] = wCurrentPercorsoFirma[i];
                    wCurrentPercorsoFirma[i] = temp;
                } else if (key.key === 1 && i !== wCurrentPercorsoFirma.length - 1) {
                    let temp = wCurrentPercorsoFirma[i + 1];
                    wCurrentPercorsoFirma[i + 1] = wCurrentPercorsoFirma[i];
                    wCurrentPercorsoFirma[i] = temp;
                    i++;
                } else if (key.key === 2) {
                    wCurrentPercorsoFirma.splice(i, 1);
                }
            }
        }

        for (var j = 0; j < wCurrentPercorsoFirma.length; j++) {
            wCurrentPercorsoFirma[j].ordinamento = j + 1;
        }

        this.setState({ percorsi: wCurrentPercorsoFirma });
    }

    handleSubmit() {
        const { authentication } = this.props;

        let nomeState = FormValidationHelper.validateField("required", this.getFormFieldValue("nome"), 1);
        let descrizioneState = FormValidationHelper.validateField("required", this.getFormFieldValue("descrizione"), 1);

        if (nomeState === "error" || descrizioneState === "error") {
            this.setState({ nomeState, descrizioneState });
            this.props.dispatch(
                alertActions.error("É necessario compilare tutti i campi obbligatori della scheda Generale.")
            );
            return;
        }

        this.setState({
            closeAlert: false,
            submitted: true,
        });

        let idRaccoglitore = this.getFormFieldValue("id");
        let tipo_percorso = this.getFormFieldValue("tipo_percorso");
        let nome = this.getFormFieldValue("nome");
        let descrizione = this.getFormFieldValue("descrizione");
        let percorso_firma = this.getFormFieldValue("percorsi");
        let id_titolario_classificazione = this.getFormFieldValue("id_titolario_classificazione");

        let raccoglitore = {
            //"id_utente_owner": authentication.user.id_utente,
            id_raccoglitore: idRaccoglitore === "" ? undefined : idRaccoglitore,
            nome: nome === "" ? undefined : nome,
            descrizione: descrizione === "" ? undefined : descrizione,
            tipo_percorso: tipo_percorso === "" || !tipo_percorso ? "S" : "D",
            id_titolario_classificazione: id_titolario_classificazione,
            utente_ult_modif: authentication.user.id_utente,
            tcs_percorso_firma: percorso_firma,
        };

        //CAMUSI 14012021 inizio
        if (percorso_firma.length > 0)// CAMUSI controllo aggiunto per evitare di poter creare un percorso di firma vuoto
        {
            if (this.correttoOrdine(percorso_firma)) {
                this.props.dispatch(raccoglitoriActions.save(raccoglitore));
            }
            else {
                this.props.dispatch(
                    alertActions.error(
                        "Attenzione non è possibile creare un percorso di firma in cui i firmatari interni precedono quelli esterni."
                    )
                );
            }
        }
        else {
            this.props.dispatch(
                alertActions.error(
                    "Atttenzione il percorso di firma deve contenere almeno un componente."
                )
            );
        }
        //CAMUSI 14012021 fine


        //CODICE ORIGINALE INIZIO 14012021
        // if (this.correttoOrdine(percorso_firma)) {
        //     this.props.dispatch(raccoglitoriActions.save(raccoglitore));
        // } else {
        //     this.props.dispatch(
        //         alertActions.error(
        //             "Attenzione non è possibile creare un percorso di firma in cui i firmatari interni precedono quelli esterni."
        //         )
        //     );
        // }
        //CODICE ORIGINALE FINE 14012021
    }

    correttoOrdine(elencoPercorsi) {
        let corretto = true;
        let percorsoMisto = this.percorsoMisto(elencoPercorsi);
        let percorsiInterni = percorsoMisto.percorsiInterni;
        let percorsiEsterni = percorsoMisto.percorsiEsterni;
        if (percorsiInterni.length > 0 && percorsiEsterni.length > 0) {
            percorsiInterni.forEach((pi) => {
                if (pi.flag_firma_vista === "F") {
                    percorsiEsterni.forEach((pe) => {
                        if (pe.ordinamento > pi.ordinamento) corretto = false;
                    });
                }
            });
        }
        return corretto;
    }

    percorsoMisto(elencoPercorsi) {
        let percorsiInterni = [];
        let percorsiEsterni = [];
        for (let i = 0; i < elencoPercorsi.length; i++) {
            if (elencoPercorsi[i].id_contatto_owner) {
                percorsiEsterni.push(elencoPercorsi[i]);
            } else if (elencoPercorsi[i].id_uo_owner || elencoPercorsi[i].id_utente_owner) {
                percorsiInterni.push(elencoPercorsi[i]);
            }
        }
        return { percorsiInterni: percorsiInterni, percorsiEsterni: percorsiEsterni };
    }

    buildActionButton(obj, idRow) {
        const { classes } = this.props;

        return [
            { row: idRow, color: "info", icon: Up },
            { row: idRow, color: "success", icon: Down },
            { row: idRow, color: "danger", icon: Close },
        ].map((prop, key) => {
            return (
                <Button
                    round
                    color={prop.color}
                    className={classes.actionButton + " " + classes.actionButtonRound}
                    key={key}
                    size="sm"
                    onClick={() => obj.handleAction({ key }, { idRow })}
                >
                    <prop.icon className={classes.icon} />
                </Button>
            );
        });
    }

    getFormFieldValue(pFieldName) {
        const { raccoglitori } = this.props;

        if (typeof this.state[pFieldName] !== "undefined") {
            return this.state[pFieldName];
        } else if (typeof raccoglitori.current !== "undefined") {
            let wReturn = ModelViewHelper.raccoglitori_ModelToView(raccoglitori.current);

            if (pFieldName === "tipo_percorso") {
                return wReturn["id_tipo_percorso"] === "D" ? true : false;
            }

            return wReturn[pFieldName];
        }

        if (pFieldName === "percorsi") {
            return [];
        }

        return "";
    }

    change(event, stateName, type, stateNameEqualTo, maxValue) {
        const { value, checked } = event.target;

        if (stateName === "tipo_percorso") {
            this.setState({ [stateName]: checked });
        } else {
            this.setState({ [stateName]: value });
        }

        switch (type) {
            case "required":
                this.setState({
                    [stateName + "State"]: FormValidationHelper.validateField("required", event.target.value, 1),
                });
                break;
            default:
                break;
        }
    }

    render() {
        const { classes, raccoglitori, utils, tema } = this.props;
        let cardColor = tema.theme_color;
        let alertMessage = "";
        if (alert && typeof alert.message !== "undefined") {
            if (alert.type === "alert-success") {
                alertMessage = <SweetAlert success
                    title="Info"
                    onConfirm={() => this.handleCloseAlert()}>
                    {alert.message}
                </SweetAlert>;
            }
            else if (alert.type === "alert-danger") {
                alertMessage = <SweetAlert danger
                    title="Attenzione"
                    onConfirm={() => { this.handleCloseAlert(); if (alert.message === "Sessione scaduta, eseguire il login.") { this.props.dispatch(userActions.logout()); } }}>
                    {alert.message}
                </SweetAlert>;
            }
        }

        let loading;
        if (raccoglitori && raccoglitori.loading) {
            loading = (
                <div className={overlay}>
                    <ClipLoader
                        className={override}
                        sizeUnit={"px"}
                        size={80}
                        color={"#3a783d"}
                        loading={raccoglitori.loading}
                    />
                </div>
            );
        }

        let percorso = this.getFormFieldValue("percorsi");
        let firmatari = percorso
            .sort(function (a, b) {
                return a.ordinamento - b.ordinamento;
            })
            .map((prop, key) => {
                return [
                    prop.ordinamento,
                    prop.flag_firma_vista === "V" ? "Visto" : "Firma",
                    prop.utente_owner
                        ? prop.utente_owner
                        : prop.uo_owner
                            ? prop.uo_owner
                            : prop.contatto_owner,
                    prop.contatto_owner ? "Esterno" : "Interno",
                    (Authorize.checkAuthorizeFunc('FNCCMP'))
                        ? this.buildActionButton(this, prop.ordinamento)
                        : "",
                ];
            });

        let optionClassificazione = [];
        if (typeof utils.tipologia_raccoglitore !== "undefined") {
            optionClassificazione = utils.tipologia_raccoglitore.map((prop) => ({
                value: prop.id_titolario_classificazione,
                label: prop.desc_titolario_classificazione,
            }));
        }

        let wButtonSalva = "";
        let wButtonAggiungi = "";
        let btnAggEsterno = "";
        if (Authorize.checkAuthorizeFunc('FNCCMP')) {
            wButtonSalva = <Button color={cardColor} onClick={() => this.handleSubmit()}>
                <Save style={{ margin: "0px" }} /> Salva
                        </Button>;

            wButtonAggiungi = (
                <Button color={cardColor} onClick={this.handleOpenModalFirmatario.bind(this)} style={{ float: "right" }}>
                    Aggiungi Firmatario Interno
                </Button>
            );
            if (!extSignDisabled)
                btnAggEsterno = (
                    <Button
                        color={cardColor}
                        onClick={this.handleOpenModalFirmatarioEsterno.bind(this)}
                        style={{ float: "right" }}
                    >
                        Aggiungi Firmatario Esterno
                    </Button>
                );
        }

        let wTitle = this.props.title;
        if (typeof raccoglitori.current !== "undefined") {
            wTitle = "Percorso di firma: " + this.getFormFieldValue("id");
        }

        return (
            <Dialog
                PaperProps={
                    {
                        //style: {
                        //    maxWidth: "700px"
                        //},
                    }
                }
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                scroll="body"
                fullScreen
                onClose={() => this.handleClose()}
                aria-labelledby="notice-modal-slide-title"
                aria-describedby="notice-modal-slide-description"
            >
                {loading}
                {alertMessage}
                <DialogContent id="notice-modal-slide-description" className={classes.modalBody}>
                    <Card style={{ marginBottom: "0px" }}>
                        <CardHeader color={cardColor} icon>
                            <CardIcon color={cardColor}>
                                <Folder />
                            </CardIcon>
                            <h4 className={classes.cardTitle} style={{ color: "#777" }}>
                                {wTitle}
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <NavPills
                                color={cardColor}
                                tabs={[
                                    {
                                        tabButton: "Generale",
                                        tabContent: (
                                            <GridContainer style={{ width: "100%", margin: "0px" }}>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <CustomInput
                                                        labelText="Id Percorso firma"
                                                        id="idRaccoglitore"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                        inputProps={{
                                                            disabled: true,
                                                            onChange: (event) => this.change(event, "id", "id"),
                                                            type: "id",
                                                            value: this.getFormFieldValue("id"),
                                                        }}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <CustomInput
                                                        success={this.state.nomeState === "success"}
                                                        error={this.state.nomeState === "error"}
                                                        labelText="Nome Percorso Firma"
                                                        id="nomeRaccoglitore"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                            required: true,
                                                        }}
                                                        inputProps={{
                                                            onChange: (event) => this.change(event, "nome", "required"),
                                                            type: "nome",
                                                            value: this.getFormFieldValue("nome") || "",
                                                        }}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <CustomInput
                                                        success={this.state.descrizioneState === "success"}
                                                        error={this.state.descrizioneState === "error"}
                                                        labelText="Descrizione"
                                                        id="descrizioneRaccoglitore"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                            required: true,
                                                        }}
                                                        inputProps={{
                                                            onChange: (event) =>
                                                                this.change(event, "descrizione", "required"),
                                                            type: "descrizione",
                                                            value: this.getFormFieldValue("descrizione") || "",
                                                        }}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <FormControl
                                                        fullWidth
                                                        className={
                                                            classes.selectFormControl +
                                                            " " +
                                                            classes.tcSignSelectControl
                                                        }
                                                    >
                                                        <SelectSearch
                                                            label={"Classificazione *"}
                                                            placeholder={"Selezionare la classificazione "}
                                                            options={optionClassificazione}
                                                            isClearable={true}
                                                            id={"id_titolario_classificazione"}
                                                            isSearchable={true}
                                                            value={this.getFormFieldValue("id_titolario_classificazione")}
                                                            onChange={this.handleSelect}
                                                        />

                                                    </FormControl>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12} style={{
                                                    height: "34vh", marginTop: "10px"
                                                }}>
                                                    <span className={classes.tc_sign_required_div}><i>* Campi obbligatori</i></span>
                                                </GridItem>
                                            </GridContainer>

                                        )
                                    },
                                    {
                                        tabButton: "Percorso di firma",
                                        tabContent: (
                                            <GridContainer style={{ width: "100%" }} direction="column">
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <GridContainer
                                                        direction="row"
                                                        justify="flex-end"
                                                        alignItems="baseline"
                                                    >
                                                        <GridItem xs={12} sm={12} md={8}>
                                                            {/*
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        color="primary"
                                                                        checked={this.getFormFieldValue("tipo_percorso")}
                                                                        onChange={event => this.change(event, "tipo_percorso", "tipo_percorso")}
                                                                        value="tipo_percorso"
                                                                    />
                                                                }
                                                                classes={{
                                                                    label: classes.selectLabel
                                                                }}
                                                                label="Percorso dinamico"
                                                            />*/}
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={4}>
                                                            {btnAggEsterno}
                                                            {wButtonAggiungi}

                                                            <FirmatarioModalForm
                                                                selectedIdUnitaOrganizzativa={undefined}
                                                                selectedUtente={undefined} //CAMUSI non ci stava
                                                                open={this.state.showModalFirmatario}
                                                                onClose={() => this.handleCloseModalFirmatario()}
                                                                onSubmit={(value) =>
                                                                    this.handleSubmitModalFirmatario(value)
                                                                }
                                                            />
                                                            <ContattoEsternoModalForm
                                                                open={this.state.showModalFirmatarioEsterno}
                                                                onClose={() => this.handleCloseModalFirmatarioEsterno()}
                                                                onSubmit={(value) =>
                                                                    this.handleSubmitModalFirmatarioEsterno(value)
                                                                }
                                                            />
                                                        </GridItem>
                                                    </GridContainer>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <Table
                                                        hover
                                                        tableHeaderColor="warning"
                                                        tableHead={["#", "Tipo", "Nome", "Tipo Utente", "Azione"]}
                                                        tableData={firmatari}
                                                        customCellClasses={[
                                                            classes.center,
                                                            classes.center,
                                                            classes.left,
                                                            classes.left,
                                                            classes.right,
                                                        ]}
                                                        customClassesForCells={[0, 1, 2, 3, 4]}
                                                        customHeadCellClasses={[
                                                            classes.center,
                                                            classes.center,
                                                            classes.center,
                                                            classes.center,
                                                            classes.center,
                                                        ]}
                                                        customHeadClassesForCells={[0, 1, 2, 3, 4]}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        ),
                                    },
                                ]}
                            />
                        </CardBody>
                    </Card>
                </DialogContent>
                <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
                    {wButtonSalva}
                    <Button color="info" onClick={() => this.handleClose()}>
                        <Close style={{ margin: "0px" }} /> Chiudi
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    const { alert, authentication, raccoglitori, utils, tema } = state;
    const { user } = authentication ?? {};
    return {
        authentication,
        alert,
        raccoglitori,
        utils,
        user,
        tema
    };
}

const connectedRaccoglitoriModalForm = connect(mapStateToProps)(withStyles(styles)(RaccoglitoreModalForm));
export default connectedRaccoglitoriModalForm;
