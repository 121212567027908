import React from "react";

import { connect } from 'react-redux';

import Button from "components/CustomButtons/Button.jsx";
import Table from "components/Table/Table.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Clearfix from "components/Clearfix/Clearfix.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from "@material-ui/core/IconButton";

import Tooltip from "@material-ui/core/Tooltip";
import Add from '@material-ui/icons/AddCircle';
import SelectSearch from "Custom/SelectSearch.jsx"

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

import { userActions, alertActions } from '../../Actions';

import { css } from 'emotion'
import { ClipLoader } from 'react-spinners';
import FormControl from "@material-ui/core/FormControl";
import _ from "lodash";
import moment from "moment";
import Datetime from "react-datetime";

const styles = {
    ...extendedFormsStyle,
    selectLabel: {
        fontSize: "16px",
        color: "rgba(0, 0, 0, 0.87)",
        top: "8px",
    },
    tcSignSelectControl: {
        margin: "7px 1px 0px 0px !important",
    },
    tcSign_tree_item: {
        color: "black"
    },
    tc_sign_searchSelectLabel: {
        fontSize: "10px",
        //textTransform: "uppercase",
        color: "#AAA !important",
        top: "8px",
        padding: "10px 0px",
    },
    tc_sign_datetime: {
        color: "#4caf50",
        top: "-2px",

        '& input.form-control': {
            backgroundImage: 'linear-gradient(#4caf50, #4caf50), linear-gradient(#D2D2D2, #D2D2D2)!important'
        },

        '& .rdtPicker .dow, .rdtPicker th.rdtSwitch, .rdtPicker th.rdtNext, .rdtPicker th.rdtPrev, .rdtPicker .rdtTimeToggle': {
            color: '#4caf50'
        }
    },


};

const overlay = css`
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(18, 37, 19, 0.35); /* Black w/ opacity */
`;

const override = css`
    display: block!important;
    
    border-color: red;
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;


const initialState = {

    direzione: "1",
    closeAlert: false,
    checked: undefined,
    delegatiSelectedList: [],
    data_inizio_delega: null,
    data_fine_delega: null,
    tipo_delega: null,
    card_color: "success"
};

class ProfiloDelegati extends Dialog {

    constructor(props, context) {
        super(props, context);

        this.state = initialState;
    }

    getData(idUtente) {
        this.props.dispatch(userActions.getAll());
        this.props.dispatch(userActions.getUtenteById(idUtente));

    }

    handleClickAttivaDelegato(id_utente_delegati, checked) {
        console.log("UTENTE_DELEGATI : " + id_utente_delegati);
        const { users } = this.props;
        this.props.dispatch(userActions.attivaDelegato(users.current.id_utente, id_utente_delegati, checked));
    }

    componentDidMount() {
        const { user } = this.props;
        this.getData(user.id_utente);
    }

    handleSelect = event => {
        console.log({ [event.target.name]: event.target.value })
        this.setState({ [event.target.name]: event.target.value });
    };


    handleCloseAlert() {
        this.props.dispatch(alertActions.clear());
    }


    changeDate(value, stateLabel) {
        var date = moment(value);
        console.log(date);
        if (date.isValid()) {
            this.setState({ [stateLabel]: date.format('DD/MM/YYYY HH:mm') });
        }
        else {
            this.setState({ [stateLabel]: undefined });
        }
    }

    handleSubmit() {

        const { users } = this.props;

        let delegatiSel = [];

        if (this.state.delegatiSelectedList.length > 0) {
            delegatiSel = this.state.delegatiSelectedList;
        } else if (users && users.current && users.current.utente_delegati.length > 0) {
            delegatiSel = users.current.utente_delegati;
        }


        this.setState({ delegatiSelectedList: [] });

        this.props.dispatch(userActions.saveDelegati(users.current.id_utente, delegatiSel));

    }

    handleClickRemoveDelegato(idDelegato, tipo_delega) {
        let delegatiList = [];
        const { users } = this.props;

        if (this.state.delegatiSelectedList.length > 0)
            delegatiList = this.state.delegatiSelectedList;
        else if (users && users.current && users.current.utente_delegati.length > 0)
            delegatiList = users.current.utente_delegati;

        for (var i = 0; i < delegatiList.length; i++) {
            if (delegatiList[i].id_utente === idDelegato && delegatiList[i].tipo_delega === tipo_delega) {
                delegatiList.splice(i, 1);
                break;
            }
        }

        this.setState({ delegatiSelectedList: delegatiList });
    }

    handleAddUtenteDelegato() {

        const { users } = this.props;
        var _this = this;
        if (typeof (this.state.id_utente_delegato) === "undefined") {
            return;
        }

        if (this.state.id_utente_delegato && this.props.users.current && this.state.id_utente_delegato === this.props.users.current.id_utente) {
            this.props.dispatch(alertActions.error("Selezionare un utente diverso dal delegante!"));
            return;
        }

        if (!this.state.data_inizio_delega) {
            this.props.dispatch(alertActions.error("Selezionare la data inizio!"));
            return;
        }

        if (!this.state.tipo_delega) {
            this.props.dispatch(alertActions.error("Selezionare la tipologia di delega!"));
            return;
        }



        var utentiDelegatiList = []
        if (this.state.delegatiSelectedList.length > 0)
            utentiDelegatiList = this.state.delegatiSelectedList;
        else if (users && users.current && users.current.utente_delegati.length > 0)
            utentiDelegatiList = users.current.utente_delegati;


        //var controlVar = false;
        if (_.find(utentiDelegatiList, { 'id_utente': this.state.id_utente_delegato, 'tipo_delega': this.state.tipo_delega })) {
            _this.props.dispatch(alertActions.error("Attenzione: delega esistente per l'utente selezionato!"));
            return;
        }

        var delegatiArr = _.filter(utentiDelegatiList, { 'tipo_delega': 'D' });
        if (delegatiArr.length >= 1 && this.state.tipo_delega === "D") {//CAMUSI 23022021
            _this.props.dispatch(alertActions.error("Attenzione: l'utente può avere un solo delegato (con tipo delega : Delega)"));
            return;
        }

        if (moment(_this.state.data_fine_delega, 'DD/MM/YYYY HH:mm').isBefore(moment(_this.state.data_inizio_delega, 'DD/MM/YYYY HH:mm'))) {
            _this.props.dispatch(alertActions.error("Attenzione: data fine delega minore di data inizio delega!"));
            return;
        }

        for (var i = 0; i < users.items.length; i++) {
            if (users.items[i].id_utente === this.state.id_utente_delegato) {
                var utente = {};
                utente['id_utente'] = users.items[i].id_utente;
                utente['utente'] = users.items[i].utente;
                utente['data_inizio_delega'] = moment(this.state.data_inizio_delega, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD HH:mm:ss");
                utente['data_fine_delega'] = this.state.data_fine_delega ? moment(this.state.data_fine_delega, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD HH:mm:ss") : null;
                utente['attiva'] = true;
                utente['disabled'] = true;
                utente['tipo_delega'] = this.state.tipo_delega;

                if (utentiDelegatiList.length > 0 && utentiDelegatiList.some(item => !_.isEqual(item, utente)))
                    utentiDelegatiList.push(utente);
                else if (utentiDelegatiList.length === 0)
                    utentiDelegatiList.push(utente);
            }
        }

        if (utentiDelegatiList.length > 0)
            this.setState({
                delegatiSelectedList: utentiDelegatiList,
                id_utente_delegato: undefined,
                data_inizio_delega: undefined,
                data_fine_delega: undefined,
                tipo_delega: undefined,
            });
        return;

    }



    render() {
        const { classes, users, tema } = this.props;
        let cardColor = tema.theme_color;
        let alertMessage = "";
        if (alert && typeof (alert.message) !== "undefined") {
            if (alert.type === "alert-success") {
                alertMessage = <SweetAlert success
                    title="Info"
                    onConfirm={() => this.handleCloseAlert()}>
                    {alert.message}
                </SweetAlert>;
            }
            else if (alert.type === "alert-danger") {
                alertMessage = <SweetAlert danger
                    title="Attenzione"
                    onConfirm={() => { this.handleCloseAlert(); if (alert.message === "Sessione scaduta, eseguire il login.") { this.props.dispatch(userActions.logout()); } }}>
                    {alert.message}
                </SweetAlert>;
            }
        }

        let loading;
        if (users?.loading) {
            loading = <div className={overlay}>
                <ClipLoader
                    className={override}
                    sizeUnit={"px"}
                    size={80}
                    color={'#3a783d'}
                    loading={users.loading}
                />
            </div>
        }

        let delegatiSelectedList = [];
        var this_ = this;
        if (this.state.delegatiSelectedList.length > 0) {
            delegatiSelectedList = this.state.delegatiSelectedList;
        } else if (users && users.current && users.current.utente_delegati && users.current.utente_delegati.length > 0) {
            delegatiSelectedList = users.current.utente_delegati;

        }

        delegatiSelectedList = delegatiSelectedList.map((prop, key) => {
            let active;
            let del;

            active = <Checkbox
                disabled={prop.disabled ? true : false}
                tabIndex={-1}
                onChange={(e) => this_.handleClickAttivaDelegato(prop.id_utente_delegati, e.target.checked)}
                checked={prop.attiva}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                    checked: classes.checked
                }}
            />;
            del = <Tooltip
                id="tooltip-top-start"
                title="Elimina"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
            >
                <IconButton
                    aria-label="Close"
                    className={classes.tableActionButton}
                    onClick={() => this.handleClickRemoveDelegato(prop.id_utente, prop.tipo_delega)}
                >
                    <DeleteIcon
                        style={{ color: "red" }}
                        className={
                            classes.tableActionButtonIcon + " " + classes.close
                        }
                    />
                </IconButton>
            </Tooltip>;

            return [prop.id_utente, prop.utente, moment(prop.data_inizio_delega).isValid() ? moment(prop.data_inizio_delega).format('DD/MM/YYYY HH:mm') : '', prop.data_fine_delega && moment(prop.data_fine_delega).isValid() ? moment(prop.data_fine_delega).format('DD/MM/YYYY HH:mm') : '', prop.tipo_delega === 'D' ? 'Delega' : 'Verifica',
                active,
                del];
        });


        let optionUtentiDelegabili = [];
        if (users && users.items) {
            optionUtentiDelegabili = users.items.map(utente => ({
                value: utente.id_utente,
                label: utente.utente
            }));
        }


        return (
            <div>
                {loading}
                {alertMessage}

                <GridContainer
                    style={{ width: "100%" }}
                >
                    <GridItem xs={12} sm={12} md={7}>
                        <Table
                            hover
                            tableHeaderColor="warning"
                            tableHead={["Id", "Nome", "Data inizio delega", "Data fine delega", "Tipo delega", "Attiva"]}
                            tableData={delegatiSelectedList || ''}
                            customCellClasses={[
                                classes.center,
                                classes.center,
                                classes.center,
                                classes.center,
                                classes.center,
                                classes.center,
                                classes.left,
                            ]}
                            customClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
                            customHeadCellClasses={[
                                classes.center,
                                classes.center,
                                classes.center,
                                classes.center,
                                classes.center,
                                classes.center,
                                classes.center,
                            ]}
                            customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
                        />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={5}
                        style={{
                            borderLeftWidth: "1px",
                            borderLeftColor: "#c4c4c4",
                            borderLeftStyle: "solid"
                        }}>
                        <GridContainer style={{ width: "100%" }} direction="row">
                            <GridItem xs={12} sm={12} md={12}>
                                <SelectSearch
                                    label={"Utenti delegabili"}
                                    placeholder={"Selezionare utente"}
                                    options={optionUtentiDelegabili}
                                    isClearable={true}
                                    id={"id_utente_delegato"}
                                    isSearchable={true}
                                    onChange={this.handleSelect}
                                    value={this.state.id_utente_delegato || ''}
                                    disabled={false}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <FormControl fullWidth>
                                    <label className={classes.tc_sign_searchSelectLabel}>Data inizio delega</label>
                                    <Datetime
                                        className={classes.tc_sign_datetime}
                                        closeOnSelect={true}
                                        onChange={value => this.changeDate(value, "data_inizio_delega")}
                                        timeFormat={true}
                                        locale={"IT-it"}
                                        viewDate={moment()}
                                        value={this.state.data_inizio_delega}
                                    />
                                </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <FormControl fullWidth>
                                    <label className={classes.tc_sign_searchSelectLabel}>Data fine delega</label>
                                    <Datetime
                                        className={classes.tc_sign_datetime}
                                        closeOnSelect={true}
                                        onChange={value => this.changeDate(value, "data_fine_delega")}
                                        timeFormat={true}
                                        locale={"IT-it"}
                                        viewDate={moment()}
                                        value={this.state.data_fine_delega}
                                    />
                                </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <SelectSearch
                                    label={"Tipo delega"}
                                    placeholder={"Selezionare la tipologia di delega"}
                                    options={[{ 'value': 'D', 'label': "Delega" }, { 'value': 'V', 'label': "Verifica" }]}
                                    isClearable={true}
                                    id={"tipo_delega"}
                                    isSearchable={true}
                                    onChange={this.handleSelect}
                                    value={this.state.tipo_delega || ''}
                                    disabled={false}
                                />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={12}>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Aggiungi nuovo utente"
                                    placement="bottom"
                                    classes={{ tooltip: classes.tooltip }}>
                                    <Button
                                        simple
                                        onClick={this.handleAddUtenteDelegato.bind(this)}
                                        color={cardColor}
                                        className={classes.tcsign_action}>
                                        <Add />Aggiungi
                            </Button>
                                </Tooltip>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                </GridContainer>
                <Button color={cardColor} className={classes.updateProfileButton}
                    onClick={() => this.handleSubmit()}>
                    Salva
                    </Button>
                <Clearfix />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { alert, authentication, users, utils, tema } = state;
    const { user } = authentication ?? {};
    return {
        authentication,
        alert,
        users,
        utils,
        user,
        tema
    };
}

const connectedProfiloDelegati = connect(mapStateToProps)(withStyles(styles)(ProfiloDelegati));
export default connectedProfiloDelegati;


