import React from 'react';
import { connect } from 'react-redux';
import Datetime from 'react-datetime';
import Select, { components } from 'react-select';
import moment from 'moment';
import { withSnackbar } from 'notistack';

// material core
import { withStyles, FormControl } from '@material-ui/core';

//core components
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import DateRange from '../../Utilities/DateRange';
import { searchActions } from '../../../Actions';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

const styles = {
    ...extendedFormsStyle,
    datetime: {
        color: '#4caf50',
        '& input.form-control': {
            backgroundImage: 'linear-gradient(#4caf50, #4caf50), linear-gradient(#D2D2D2, #D2D2D2)!important',
        },
        '& .rdtPicker .dow, .rdtPicker th.rdtSwitch, .rdtPicker th.rdtNext, .rdtPicker th.rdtPrev, .rdtPicker .rdtTimeToggle': {
            color: '#4caf50',
        },
    },
    dateLabel: { fontSize: '10px' },
    fromControlRoot: {
        verticalAlign: 'middle',
    },
    noPad: { padding: '0 !important' },
};

const selectStyles = {
    tc_sign_searchSelectLabel: {
        fontSize: '24px',
        color: '#606060',
        top: '8px',
        padding: '0px',
        fontWeight: 'bold'
    },
    control: (styles, state) => ({
        ...styles,
        padding: '0px',
        backgroundColor: 'transparent',
        borderColor: state.isFocused ? '#4caf50' : 'rgb(204, 204, 204)',
        boxShadow: state.isFocused ? `0 0 0 1px "#4caf50"` : '0px',
        borderTopWidth: '0px',
        borderLeftWidth: '0px',
        borderRightWidth: '0px',
        borderRadius: '0px',

        '&:hover': {
            borderColor: state.isFocused ? '#4caf50' : 'rgb(204, 204, 204)',
        },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isDisabled ? null : isSelected ? '#43a047' : isFocused ? '#43a047' : null,
            color: isDisabled ? '#ccc' : isSelected ? 'white' : isFocused ? 'white' : null,
            cursor: isDisabled ? 'not-allowed' : 'default',
            fontSize: '20px',
            fontWeight: '400',
            lineHeight: '2',
            ':active': {
                backgroundColor: isSelected ? '#43a047' : null,
            },
        };
    },
    input: (styles) => ({
        ...styles,
        padding: '0px',
        backgroundColor: 'white',
        fontSize: '20px',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight: '1.42857',
    }),
    valueContainer: (styles) => ({
        ...styles,
        padding: '0px',
    }),
    singleValue: (styles) => ({
        ...styles,
        opacity: '1',
        fontSize: '20px',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight: '1.42857',
    }),
    menuList: (styles) => ({
        ...styles,
        zIndex: '9999',
        position: 'relative',
    }),
    menu: (styles) => ({
        ...styles,
        zIndex: '9999',
    }),
    placeholder: (styles) => ({
        ...styles,
        top: '13px',
        color: '#6E6E6E !important',
        fontSize: '20px',
        fontWeight: '400',
        padding: '0px',
    }),
};

class RicercaAvanzata extends React.PureComponent {
    setFilters = (name, value) => {
        value = value && value !== '' ? value : undefined;

        this.props.dispatch(searchActions.setFilter(name, value));
    };

    onChange = (event) => {
        const { name, value } = event.target;
        this.setFilters(name, value);
    };

    handleSelect = (event, action) => {
        const value = event?.map((ev) => ev.value) ?? [];
        this.setFilters(action.name, value);
    };

    handleKeyPress = (event) => {
        const { nome, id_documento, desc_tipo_documento, fulltext, data_documento, id_soggetto_originatore } = this.props.filters;
        const disableSearch =
            nome || id_documento || desc_tipo_documento || id_soggetto_originatore || fulltext || data_documento?.gte || data_documento?.lte 
                ? false
                : true;
        if (event.key === 'Enter' && !disableSearch) this.props.search();
    };

    setDateRange = (value, name) => {
        const { data_documento } = this.props.filters;
        if (value) {
            if (name === 'gte') value = value + ' 00:00:00';
            else value = value + ' 23:59:59';
            this.setFilters('data_documento', {
                ...data_documento,
                [name]: value,
            });
        } else {
            delete data_documento[name];
            this.setFilters('data_documento', { ...data_documento });
        }
    };

    render() {
        const { classes, filters, utils } = this.props;
        const customInputs = [
            { md: 6, lg: 5, label: 'Oggetto', id: 'nome', type: 'text' },
        ];

        const dateRangeProps = {
            timeFormat: false,
            format: 'YYYY-MM-DD',
            labelFrom: 'Dal',
            labelTo: 'Al',
            placeholderFrom: 'Documento dal',
            placeholderTo: 'Documento al',
            nameFrom: 'gte',
            nameTo: 'lte',
            dateRange: {
                from: filters.data_documento?.gte,
                to: filters.data_documento?.lte,
            },
            setDateRange: (value, name) => this.setDateRange(value, name),
        };

        const tipoDocumentoOptions = utils.tipologia_documento?.map((utils) => ({
            value: utils.id_tipo_documento,
            label: utils.desc_tipo_documento,
        }));

        const soggettoOriginatoreOptions = utils.soggettiOriginatori?.map((so) => ({
            value: so.id_soggetto_originatore,
            label: so.descrizione
        }));
        
        const tipoDocumentoValue = tipoDocumentoOptions?.filter((utils) => filters.id_tipo_documento?.includes(utils.value));
        //const utenteCreatoreValue = utenteCreatoreOptions?.filter((user) => filters.id_utente_created?.includes(user.value));
        
        const soggettoOriginatoreValue = soggettoOriginatoreOptions?.filter((so) => filters.id_soggetto_originatore?.includes(so.value));
        // console.log('Data:::', soggettoOriginatoreValue)
        return (
            <GridContainer style={{ width: '100%', margin: 'auto', display: 'flex', justifyContent: 'space-between' }}>
                {customInputs.map((input) => {
                    const { md, lg, label, id, type } = input;
                    return (
                        <GridItem xs={12} sm={12} md={md} lg={lg} key={label} className={classes.noPad}>
                            <CustomInput
                                labelText={label}
                                id={id}
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                    onChange: this.onChange,
                                    type,
                                    value: filters[id] ?? '',
                                    onKeyPress: this.handleKeyPress,
                                }}
                            />
                        </GridItem>
                    );
                })}
                <GridItem md={5} lg={5} className={classes.noPad} style={{ marginRight: '5px', display: 'flex', alignItems: 'flex-end' }}>
                    <DateRange {...dateRangeProps}/>
                </GridItem>
                <GridItem md={5} lg={5} className={classes.noPad} style={{ marginRight: '5px', display: 'flex', alignItems: 'flex-end' }}>
                    <FormControl fullWidth>
                        <Select
                            id="t_d"
                            name="id_tipo_documento"
                            styles={selectStyles}
                            isMulti
                            isSearchable
                            label="Tipo documento"
                            placeholder="Seleziona il tipo di documento"
                            className="basic-multi-select"
                            components={{ Control: ControlComponent }}
                            onChange={this.handleSelect}
                            onKeyDown={this.handleKeyPress}
                            value={tipoDocumentoValue}
                            options={tipoDocumentoOptions}
                        />
                    </FormControl>
                </GridItem>
                <GridItem md={5} lg={5} className={classes.noPad} style={{ marginRight: '5px', display: 'flex', alignItems: 'flex-end' }}>
                    <FormControl fullWidth>
                        <Select
                            id="s_o"
                            name="id_soggetto_originatore"
                            styles={selectStyles}
                            isMulti
                            isSearchable
                            label="Soggetto originatore"
                            placeholder="Seleziona il soggetto originatore"
                            className="basic-multi-select"
                            components={{ Control: ControlComponent }}
                            onChange={this.handleSelect}
                            onKeyDown={this.handleKeyPress}
                            value={soggettoOriginatoreValue}
                            options={soggettoOriginatoreOptions}
                        />
                    </FormControl>
                </GridItem>
            </GridContainer>
        );
    }
}

const ControlComponent = (props) => {
    const { label } = props.selectProps;

    const labelStyle = {
        fontSize: '22px',
        color: '#606060',
        top: '8px',
        padding: '0px',
        fontWeight: 'bold'
    };

    return (
        <div style={{ marginTop: '1em' }}>
            {label && <label style={labelStyle}>{label}</label>}
            <components.Control {...props} />
        </div>
    );
};

const mapStateToProps = (state) => {
    const { utils, search, users, documenti } = state;
    return {
        utils,
        users,
        documenti,
        filters: search.filters,
        ambito: search.ambito,
    };
};

const RicercaAvanzataConnected = connect(mapStateToProps)(withSnackbar(withStyles(styles)(RicercaAvanzata)));
export default RicercaAvanzataConnected;
