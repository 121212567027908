import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { alertActions, utilsActions } from '../../Actions';
import FormValidationHelper from '../../Helpers/form-validation.js';
import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import FormControl from '@material-ui/core/FormControl';
import SelectSearch from 'Custom/SelectSearch.jsx';
import Close from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Save from '@material-ui/icons/Save';
import { Checkbox, FormLabel } from '@material-ui/core/';

import Button from 'components/CustomButtons/Button.jsx';

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

const styles = {
    //...extendedFormsStyle,
    ...regularFormsStyle,
};

const initialState = {
    selectedContact: undefined,
    disabledAdd: true,
    disabledField: true,
    disabledBtnSave: true,
    fea: false,
    firma_digitale: false,
};

class ContattoEsternoModalForm extends Dialog {
    constructor(props, context) {
        super(props, context);
        this.state = initialState;
    }

    handleClose() {
        this.setState(initialState);
        this.resetFields(true);
        this.props.dispatch(utilsActions.unselectSavedContact());
        this.props.onClose();
    }

    getFormFieldValue(pFieldName) {
        const { utils } = this.props;
        if (typeof this.state[pFieldName] !== 'undefined') {
            return this.state[pFieldName];
        } else if (
            pFieldName === 'id_uo_group' &&
            typeof this.state['selectedUO'] !== 'undefined'
        ) {
            return this.state.selectedUO;
        } else if (typeof utils.current_contact !== 'undefined') {
            if (pFieldName === 'id_prefisso') {
                let val = utils.current_contact['prefisso_internazionale'];
                if (val) return parseInt(val.replace('+', ''));
            } else return utils.current_contact ? utils.current_contact[pFieldName] : undefined;
        }
        return '';
    }

    change(event, elemName) {
        const { value } = event.target;
        let changedField = {};
        changedField[elemName] = value;
        this.setState(changedField);
    }

    handleSaveSigner() {
        const { utils } = this.props;
        let nomeState = FormValidationHelper.validateField(
            'required',
            this.getFormFieldValue('nome'),
            1
        );
        let cognomeState = FormValidationHelper.validateField(
            'required',
            this.getFormFieldValue('cognome'),
            1
        );
        let aziendaState = FormValidationHelper.validateField(
            'required',
            this.getFormFieldValue('azienda'),
            1
        );
        // Almeno una tra email e pec deve essere valorizzata, segnalo l'errore solo se sono entrambe non valorizzate
        let emailState = FormValidationHelper.validateField(
            'email',
            this.getFormFieldValue('email'),
            1
        );
        let cellulareState = FormValidationHelper.validateField(
            'required',
            this.getFormFieldValue('cellulare'),
            1
        );
        let id_uo_group_state = FormValidationHelper.validateField(
            'required-select',
            this.getFormFieldValue('id_uo_group'),
            1
        );
        if (aziendaState === 'success') {
            nomeState = 'success';
            cognomeState = 'success';
        } else if (nomeState === 'success' && cognomeState === 'success') {
            aziendaState = 'success';
        }
        let webState = {
            nomeState: nomeState,
            cognomeState: cognomeState,
            aziendaState: aziendaState,
            emailState: emailState,
            cellulareState: cellulareState,
            id_uo_group_state: id_uo_group_state,
        };

        if (
            nomeState === 'error' ||
            cognomeState === 'error' ||
            aziendaState === 'error' ||
            emailState === 'error' ||
            cellulareState === 'error' ||
            id_uo_group_state === 'error'
        ) {
            this.setState(webState);
            this.props.dispatch(
                alertActions.error(
                    'Attenzione errori di compilazione nella scheda dati del Contatto'
                )
            );
            return;
        } else {
            this.setState(this.resetSignerState());
            let contatto = this.recuperaValoriContatto();
            this.props.dispatch(
                utilsActions.saveContatto({
                    contatto,
                    id_uo_group: this.state.selectedUO.id_uo_group || utils.current_uo.id_uo_group,
                })
            );
        }
    }

    resetSignerState() {
        let contactState = {
            nomeState: undefined,
            cognomeState: undefined,
            aziendaState: undefined,
            emailState: undefined,
            cellulareState: undefined,
        };

        return contactState;
    }

    recuperaValoriContatto() {
        const { user } = this.props;
        let contatto = {
            id_contatto:
                this.getFormFieldValue('id_contatto') &&
                    this.getFormFieldValue('id_contatto') !== ''
                    ? this.getFormFieldValue('id_contatto')
                    : undefined,
            nome: this.getFormFieldValue('nome'),
            cognome: this.getFormFieldValue('cognome'),
            email:
                this.getFormFieldValue('email') && this.getFormFieldValue('email') !== ''
                    ? this.getFormFieldValue('email')
                    : null,
            azienda: this.getFormFieldValue('azienda'),
            utente_ult_modif: user.nome + ' ' + user.cognome,
            data_creazione: moment(new Date()).format(),
            cellulare: this.getFormFieldValue('cellulare'),
            prefisso_internazionale: this.getFormFieldValue('id_prefisso')
                ? '+' + this.getFormFieldValue('id_prefisso')
                : '+39',
        };
        return contatto;
    }

    handleSelectedContact = (event) => {
        this.props.dispatch(utilsActions.selectSavedContact(event.target.value));
        this.resetFields(event.target.value ? false : true, this.state.selectedUO, false, false);
    };

    handleSelectedUO(event) {
        if (typeof event.target.value === 'undefined') {
            event.target.value = '-1';
            this.props.dispatch(utilsActions.unselectContacts());
        }
        if (event.target.value) {
            this.props.dispatch(
                utilsActions.getContattiEsterniByUo(event.target.value.id_uo_group)
            );
        }
        this.props.dispatch(utilsActions.unselectSavedContact());
        this.setState(this.resetSignerState());
        this.resetFields(
            true,
            event.target.value,
            event.target.value !== '-1' ? false : true,
            event.target.value !== '-1' ? false : true
        );
    }

    handlePrefix = (event) => {
        if (typeof event.target.value === 'undefined') {
            event.target.value = '-1';
        }
        let wState = { [event.target.name]: event.target.value };
        this.setState(wState);
    };

    resetFields(disabled, selectedUO, disabledField, disabledBtnSave) {
        this.setState({
            nome: undefined,
            cognome: undefined,
            email: undefined,
            azienda: undefined,
            id_prefisso: undefined,
            cellulare: undefined,
            disabledAdd: disabled,
            selectedUO: selectedUO,
            selectedContact: undefined,
            disabledField: disabledField !== undefined ? disabledField : this.state.disabledField,
            disabledBtnSave:
                disabledBtnSave !== undefined ? disabledBtnSave : this.state.disabledBtnSave,
        });
    }

    handleSubmit() {
        const { utils } = this.props;
        const { fea, firma_digitale } = this.state;
        let selected = this.state.selectedContact
            ? this.state.selectedContact
            : utils.current_contact;
        let returnData = {
            id_contatto: selected.id_contatto,
            nome_contatto: selected.cognome
                ? selected.nome + ' ' + selected.cognome
                : selected.azienda,
            //firma_visto: 'F',
            tipo_firma: { fea, firma_digitale },
            disabledAdd: true,
        };

        this.setState(initialState);
        this.resetFields(true);
        this.props.dispatch(utilsActions.unselectSavedContact());
        this.props.dispatch(utilsActions.unselectContacts());
        this.props.dispatch(utilsActions.unselectUo());
        this.props.onSubmit(returnData);
    }

    onChangeCheckbox = (event) => {
        const { checked, name } = event.target;

        this.setState({
            [name]: checked,
        });
    };

    selectDefault() {
        const { user } = this.props;
        if (user?.unita_organizzative?.length > 0) {
            this.props.dispatch(
                utilsActions.getContattiEsterniByUo(user.unita_organizzative[0].id_uo_group)
            );
            this.resetFields(true, user.unita_organizzative[0], false, false);
        }
    }

    render() {
        const { fea, firma_digitale } = this.state;
        const { classes, utils, user } = this.props;
        let disabledUO = false;
        let disabledBtnAdd = utils.current_contact && (fea || firma_digitale) ? false : true;
        let elencoContatti = [];
        let elencoUO = [];
        let elencoPrefissi = [
            { id: 39, prefisso: '+39' },
            { id: 33, vaprefissolue: '+33' },
            { id: 34, prefisso: '+34' },
            { id: 49, prefisso: '+49' },
            { id: 30, prefisso: '+30' },
            { id: 353, prefisso: '+353' },
            { id: 31, prefisso: '+31' },
            { id: 41, prefisso: '+41' },
            { id: 44, prefisso: '+44' },
        ].map((prefix) => ({
            value: prefix.id,
            label: prefix.prefisso,
        }));
        let selectedPrefix =
            this.getFormFieldValue('id_prefisso') ||
            elencoPrefissi.filter((option) => option.label === '+39')[0].value;
        if (user?.unita_organizzative?.length > 0) {
            elencoUO = user.unita_organizzative.map((uo) => ({
                value: uo,
                label: uo.nome,
            }));
            if (elencoUO.length === 1) disabledUO = true;
        }

        if (utils.elenco_contatti && utils.elenco_contatti.length > 0) {
            elencoContatti = utils.elenco_contatti.map((contatto) => ({
                value: contatto,
                label:
                    (contatto.cognome ? contatto.cognome + ' ' + contatto.nome : '') +
                    (contatto.cognome && contatto.azienda ? ', ' : '') +
                    (contatto.azienda ? contatto.azienda : '') +
                    (contatto.email ? ', ' + contatto.email : ''),
            }));
        }
        const tipologieFirma = ['fea', 'firma_digitale'];

        return (
            <Dialog
                open={this.props.open}
                PaperProps={{
                    style: {
                        maxWidth: '50%',
                        width: '50%',
                        height: '72%',
                    },
                }}
                scroll="body"
                TransitionComponent={Transition}
                keepMounted
                onEntering={() => this.selectDefault()}
                aria-labelledby="notice-modal-slide-title"
                aria-describedby="notice-modal-slide-description"
            >
                <DialogContent id="notice-modal-slide-description" className={classes.modalBody}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <FormControl
                                fullWidth
                                className={
                                    classes.selectFormControl + ' ' + classes.tcSignSelectControl
                                }
                            >
                                <SelectSearch
                                    success={this.state.id_uo_group_state === 'success'}
                                    error={this.state.id_uo_group_state === 'error'}
                                    label="Unità Organizzativa *"
                                    placeholder={'Selezionare una Unità Organizzativa'}
                                    options={elencoUO}
                                    isClearable={true}
                                    id={'id_uo_group'}
                                    isSearchable={true}
                                    value={
                                        this.state.selectedUO
                                            ? this.state.selectedUO
                                            : utils.current_uo
                                                ? utils.current_uo
                                                : ''
                                    }
                                    onChange={this.handleSelectedUO.bind(this)}
                                    disabled={disabledUO}
                                />
                            </FormControl>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <FormControl
                                fullWidth
                                className={
                                    classes.selectFormControl + ' ' + classes.tcSignSelectControl
                                }
                            >
                                <SelectSearch
                                    success={this.state.id_contatto_state === 'success'}
                                    error={this.state.id_contatto_state === 'error'}
                                    label="Selezione Contatto Esterno"
                                    placeholder={'Selezionare un contatto'}
                                    options={elencoContatti}
                                    isClearable={true}
                                    id={'id_contatto'}
                                    isSearchable={true}
                                    disabled={this.state.disabledField}
                                    value={utils.current_contact}
                                    onChange={this.handleSelectedContact}
                                />
                            </FormControl>
                        </GridItem>
                    </GridContainer>
                    <GridContainer style={{ marginTop: '20px' }}>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                error={this.state.nomeState === 'error'}
                                labelText="Nome"
                                id="nome"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    onChange: (event) => this.change(event, 'nome'),
                                    type: 'text',
                                    value: this.getFormFieldValue('nome') || '',
                                    disabled: this.state.disabledField,
                                }}
                                className={classes.tcSignInputStyle}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                error={this.state.cognomeState === 'error'}
                                labelText="Cognome"
                                id="cognome"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    onChange: (event) => this.change(event, 'cognome'),
                                    type: 'text',
                                    value: this.getFormFieldValue('cognome') || '',
                                    disabled: this.state.disabledField,
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                error={this.state.aziendaState === 'error'}
                                labelText="Azienda"
                                id="azienda"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    onChange: (event) => this.change(event, 'azienda'),
                                    type: 'text',
                                    value: this.getFormFieldValue('azienda') || '',
                                    disabled: this.state.disabledField,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                error={this.state.emailState === 'error'}
                                labelText="Email *"
                                id="email"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    onChange: (event) => this.change(event, 'email'),
                                    type: 'email',
                                    value: this.getFormFieldValue('email') || '',
                                    disabled: this.state.disabledField,
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={2}>
                            <FormControl
                                fullWidth
                                className={
                                    classes.selectFormControl + ' ' + classes.tcSignSelectControl
                                }
                            >
                                <SelectSearch
                                    label="Prefisso"
                                    options={elencoPrefissi}
                                    isClearable={true}
                                    id={'id_prefisso'}
                                    isSearchable={true}
                                    disabled={true}
                                    value={selectedPrefix}
                                    onChange={this.handlePrefix}
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                                error={this.state.cellulareState === 'error'}
                                labelText="Cellulare *"
                                id="cellulare"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    onChange: (event) => this.change(event, 'cellulare'),
                                    type: 'text',
                                    value: this.getFormFieldValue('cellulare') || '',
                                    disabled: this.state.disabledField,
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer style={{ marginTop: '20px' }}>
                        <GridItem xs={12} sm={12} md={12}>
                            <FormLabel style={{ display: 'block', marginTop: '10px' }}>
                                Selezionare almeno una tipologia di firma
                            </FormLabel>
                            {tipologieFirma.map((tipologia, index) => (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            name={tipologia}
                                            checked={tipologia === 'fea' ? fea : firma_digitale}
                                            size="sm"
                                            onChange={this.onChangeCheckbox}
                                        />
                                    }
                                    classes={{
                                        label: classes.label,
                                    }}
                                    label={tipologia === 'fea' ? 'Firma elettronica avanzata' : 'Firma digitale (esterna)'}
                                />
                            ))}
                        </GridItem>
                    </GridContainer>
                </DialogContent>
                <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
                    <Button
                        color="success"
                        onClick={() => this.handleSubmit()}
                        disabled={disabledBtnAdd}
                    >
                        <Add style={{ margin: '0px' }} /> Aggiungi
                    </Button>
                    <Button
                        onClick={() => this.handleSaveSigner()}
                        color="info"
                        style={{ float: 'right' }}
                        disabled={this.state.disabledBtnSave}
                    >
                        <Save style={{ margin: '0px' }} /> SALVA IN RUBRICA
                    </Button>
                    <Button color="info" onClick={() => this.handleClose()}>
                        <Close style={{ margin: '0px' }} /> Chiudi
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    const { alert, authentication, raccoglitori, utils, users } = state;
    const { user } = authentication ?? {};
    return {
        authentication,
        alert,
        raccoglitori,
        utils,
        users,
        user,
    };
}

const connectedContattoEsternoModalForm = connect(mapStateToProps)(
    withStyles(styles)(ContattoEsternoModalForm)
);
export default connectedContattoEsternoModalForm;

/*<Radio
    checked={true}
    onChange={this.handleRadio}
    value="F"
    name="firma_visto"
    aria-label="A"
    icon={<FiberManualRecord className={classes.radioUnchecked} />}
    checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
    classes={{
        checked: classes.radio,
    }}
/>;*/
