import { utilsConstants } from '../Constants';
import { utilsService, userService } from '../Services';
import { alertActions } from '../Actions';

export const utilsActions = {
    getUnitaOrganizzative,
    getTipologiaDocumento,
    getStatoDocumento,
    getUtentiByUnitaOrganizzativa,
    getTipologiaRaccoglitore,
    getRuoliUtente,
    ruoliDefault,
    getAllFunctions,
    saveContatto,
    getContattiEsterni,
    getContattiEsterniByUo,
    selectSavedContact,
    unselectSavedContact,
    selectUo,
    unselectUo,
    unselectContacts,
    setReloaded,
    getSoggettiOriginatori
};

function setReloaded(val) {
    return { type: utilsConstants.SET_RELOADED_SUCCESS, val };
}

function getUtentiByUnitaOrganizzativa(unita_organizzativa) {
    return dispatch => {

        dispatch(request());

        utilsService.getUtentiByUnitaOrganizzativa(unita_organizzativa)
            .then(
                uo => dispatch(success(uo)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: utilsConstants.GET_UTENTI_BY_UO_REQUEST } }
    function success(uo) { return { type: utilsConstants.GET_UTENTI_BY_UO_SUCCESS, uo } }
    function failure(error) { return { type: utilsConstants.GET_UTENTI_BY_UO_FAILURE, error } }
}

function getUnitaOrganizzative() {
    return dispatch => {

        dispatch(request());

        utilsService.getUnitaOrganizzative()
            .then(
                unita_organizzative => dispatch(success(unita_organizzative)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: utilsConstants.GET_UNITA_ORGANIZZATIVE_REQUEST } }
    function success(unita_organizzative) { return { type: utilsConstants.GET_UNITA_ORGANIZZATIVE_SUCCESS, unita_organizzative } }
    function failure(error) { return { type: utilsConstants.GET_UNITA_ORGANIZZATIVE_FAILURE, error } }
}

function getRuoliUtente() {

    return dispatch => {

        dispatch(request());

        userService.getRuoliUtente()
            .then(
                ruoli_utente => {
                    dispatch(ruoliDefault(ruoli_utente));//CAMUSI
                    dispatch(success(ruoli_utente));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: utilsConstants.GET_UTENTI_RUOLO_REQUEST } }
    function success(ruoli_utente) { return { type: utilsConstants.GET_UTENTI_RUOLO_SUCCESS, ruoli_utente } }
    function failure(error) { return { type: utilsConstants.GET_UTENTI_RUOLO_FAILURE, error } }
}

//CAMUSI
function ruoliDefault(ruoli) {
    let ruoli_utente_default = [];
    for (var i = 0; i < ruoli.length; i++) {
        if (ruoli[i].id_ruolo === 2 || ruoli[i].id_ruolo === 4) {
            ruoli_utente_default.push(ruoli[i]);
        }
    }
    return { type: utilsConstants.GET_RUOLI_DEFAULT_SUCCESS, ruoli_utente_default };
}

function getTipologiaDocumento() {
    return dispatch => {

        dispatch(request());

        utilsService.getTipologiaDocumento()
            .then(
                tipologia_documento => dispatch(success(tipologia_documento)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: utilsConstants.GET_TIPO_DOCUMENTO_REQUEST } }
    function success(tipologia_documento) { return { type: utilsConstants.GET_TIPO_DOCUMENTO_SUCCESS, tipologia_documento } }
    function failure(error) { return { type: utilsConstants.GET_TIPO_DOCUMENTO_FAILURE, error } }
}

function getTipologiaRaccoglitore() {
    return dispatch => {

        dispatch(request());

        utilsService.getTipologiaRaccoglitore()
            .then(
                tipologia_raccoglitore => dispatch(success(tipologia_raccoglitore)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: utilsConstants.GET_TIPO_RACCOGLITORE_REQUEST } }
    function success(tipologia_raccoglitore) { return { type: utilsConstants.GET_TIPO_RACCOGLITORE_SUCCESS, tipologia_raccoglitore } }
    function failure(error) { return { type: utilsConstants.GET_TIPO_RACCOGLITORE_FAILURE, error } }
}

function getStatoDocumento() {
    return dispatch => {

        dispatch(request());

        utilsService.getStatoDocumento()
            .then(
                stato_documento => dispatch(success(stato_documento)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: utilsConstants.GET_STATO_DOCUMENTO_REQUEST } }
    function success(stato_documento) { return { type: utilsConstants.GET_STATO_DOCUMENTO_SUCCESS, stato_documento } }
    function failure(error) { return { type: utilsConstants.GET_STATO_DOCUMENTO_FAILURE, error } }
}

function saveContatto(contatto) {
    return dispatch => {
        dispatch(request());
        utilsService.saveContatto(contatto)
            .then(
                contact => {
                    dispatch(success(contact));
                    dispatch(utilsActions.getContattiEsterniByUo(contatto.id_uo_group));
                    dispatch(utilsActions.selectSavedContact(contact))
                    dispatch(alertActions.success("Operazione eseguita con successo"));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: utilsConstants.SAVE_SIGNER_REQUEST } }
    function success(contact) { return { type: utilsConstants.SAVE_SIGNER_SUCCESS, contact } }
    function failure(error) { return { type: utilsConstants.SAVE_SIGNER_FAILURE, error } }
}

function getContattiEsterni() {
    return dispatch => {
        dispatch(request());
        utilsService.getContattiEsterni()
            .then(
                contatti => dispatch(success(contatti)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: utilsConstants.GET_CONTATTI_REQUEST } }
    function success(contatti) { return { type: utilsConstants.GET_CONTATTI_SUCCESS, contatti } }
    function failure(error) { return { type: utilsConstants.GET_CONTATTI_FAILURE, error } }
}

function getContattiEsterniByUo(id_uo) {
    return dispatch => {
        dispatch(request());
        utilsService.getContattiEsterniByUo(id_uo)
            .then(
                contatti => dispatch(success(contatti)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: utilsConstants.GET_CONTATTI_REQUEST } }
    function success(contatti) { return { type: utilsConstants.GET_CONTATTI_SUCCESS, contatti } }
    function failure(error) { return { type: utilsConstants.GET_CONTATTI_FAILURE, error } }
}

function getAllFunctions() {
    return dispatch => {
        dispatch(request());
        utilsService.getAllFunctions()
            .then(
                funzioni => dispatch(success(funzioni)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: utilsConstants.GET_ALL_FUNCTION_REQUEST } }
    function success(funzioni) { return { type: utilsConstants.GET_ALL_FUNCTION_SUCCESS, funzioni } }
    function failure(error) { return { type: utilsConstants.GET_ALL_FUNCTION_FAILURE, error } }
}

function selectSavedContact(contatto) {
    return { type: utilsConstants.SEL_SAVE_CONTACT, contatto };
}

function unselectSavedContact() {
    return { type: utilsConstants.UNSEL_SAVE_CONTACT };
}

function selectUo(uo) {
    return { type: utilsConstants.SEL_SINGLE_UO, uo };
}

function unselectUo() {
    return { type: utilsConstants.UNSEL_SINGLE_UO };
}

function unselectContacts() {
    return { type: utilsConstants.UNSEL_CONTACTS };
}

function getSoggettiOriginatori() {
    return (dispatch) => {
        dispatch(request());
        utilsService.getSoggettiOriginatori().then(
            (res) => {
                dispatch(success(res));
            },
            (error) => {
                dispatch(failure(error.toString()));
            }
        );
    };

    function request() {
        return { type: utilsConstants.GET_SOGGETTI_ORIGINATORI_REQUEST };
    }
    function success(res) {
        return { type: utilsConstants.GET_SOGGETTI_ORIGINATORI_SUCCESS, res };
    }
    function failure(error) {
        return { type: utilsConstants.GET_SOGGETTI_ORIGINATORI_FAILURE, error };
    }
}