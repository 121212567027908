const FormValidationHelper = class {
    
    // function that verifies if a string has a given length or not
    static verifyLength =
        function (value, length) {
            if (value.length >= length) {
                return true;
            }
            return false;
        }

    static verifyMinLength =
        function (value, length) {
            if (value.length <= length) {
                return true;
            }
            return false;
        }

    // function that returns true if value is email, false otherwise
    static verifyEmail =
        function (value) {
            var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!emailRex.test(value)) {
                return false;
            }
            return true;
        }

        
    static verifyCodiceFiscale =
        function (value) {

            if (typeof(value) === "undefined" || value === null || value.length === 0) {
                return true;
            }

            var cf = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;
            if (!cf.test(value)) {
                return false;
            }
            return true;
        }

    // function that verifies if two strings are equal
    static compare =
        function (string1, string2) {
            if (string1 === string2) {
                return true;
            }
            return false;
        }

    // function that verifies if value contains only numbers
    static verifyNumber =
        function (value) {
            var numberRex = new RegExp("^[0-9]+$");
            if (numberRex.test(value)) {
                return true;
            }
            return false;
        }

    // verifies if value is a valid URL
    static verifyUrl =
            function (value) {
            try {
                new URL(value);
                return true;
            } catch (_) {
                return false;
            }
        }

    // validates field and return state
    static validateField =
        function (type, value, compareTo, maxValue) {

            if (typeof (value) === "undefined") {
                return "error";
            }

            switch (type) {
                case "required":
                    if (this.verifyLength(value, 1)) {
                        return "success";
                    } else {
                        return "error";
                    }
                case "required-select":
                    if (typeof (value) !== "undefined" && value !== "" && value !== "-1" && value !== -1) {
                        return "success";
                    } else {
                        return "error";
                    }
                case "email":
                    if (this.verifyEmail(value)) {
                        return "success";
                    } else {
                        return "error";
                    }
                case "codice_fiscale":
                    if (this.verifyCodiceFiscale(value)) {
                        return "success";
                    } else {
                        return "error";
                    }
                case "equalTo":
                    if (this.compare(value, compareTo)) {
                        return "success";
                    } else {
                        return "error";
                    }
                case "boolean":
                    if (value) {
                        return "success";
                    } else {
                        return "error";
                    }
                case "number":
                    if (this.verifyNumber(value)) {
                        return "success";
                    } else {
                        return "error";
                    }
                case "length":
                    if (this.verifyLength(value, compareTo)) {
                        return "success";
                    } else {
                        return "error";
                    }
                case "min-length":
                    if (!this.verifyMinLength(value, compareTo + 1)) {
                        return "success";
                    } else {
                        return "error";
                    }
                case "max-length":
                    if (!this.verifyLength(value, compareTo + 1)) {
                        return "success";
                    } else {
                        return "error";
                    }
                case "url":
                    if (this.verifyUrl(value)) {
                        return "success";
                    } else {
                        return "error";
                    }
                case "min-value":
                    if (
                        this.verifyNumber(value) &&
                        value >= compareTo
                    ) {
                        return "success";
                    } else {
                        return "error";
                    }
                case "max-value":
                    if (
                        this.verifyNumber(value) &&
                        value <= compareTo
                    ) {
                        return "success";
                    } else {
                        return "error";
                    }
                case "range":
                    if (
                        this.verifyNumber(value) &&
                        value >= compareTo &&
                        value <= maxValue
                    ) {
                        return "success";
                    } else {
                        return "error";
                    }
                default:
                    break;
            }

        }

    }

export default FormValidationHelper;

    
