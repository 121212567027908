import React from "react";
import ReactDOMServer from "react-dom/server";
import { ClipLoader } from "react-spinners";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import DropzoneComponent from "react-dropzone-component";
import SweetAlert from "react-bootstrap-sweetalert";
import Moment from "react-moment";
import moment from "moment";
import Datetime from "react-datetime";
import { pdfjs } from "react-pdf";
import { css } from "emotion";

import SelectUnitaOrganizzative from "../Unita Organizzative/SelectUnitaOrganizzative.jsx";
import SelectTooltip from "../Unita Organizzative/SelectTooltip";
import SelectSearch from "Custom/SelectSearch.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Table from "components/Table/Table.jsx";
import Timeline from "components/Timeline/Timeline.jsx";

//@material-ui/core components
import { Dialog, DialogContent, DialogActions, Slide, Switch, Checkbox } from "@material-ui/core";
import { FormControl, FormControlLabel, Tooltip, withStyles } from "@material-ui/core";

//icons
import { Folder, Check, Save, Close, Add, Extension, Search } from "@material-ui/icons";

//assets
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

import PercorsoFirma from "../Raccoglitore/PercorsoFirma.jsx";
import GestioneSeduteModalForm from "./GestioneSeduteModalForm.jsx";
import PosizionamentoFirma from "../Utilities/PosizionamentoFirma";
import DettaglioPercorsoModalForm from "./DettaglioPercorsoModalForm.jsx"; //CAMUSI 23072020

import {
    documentiActions,
    alertActions,
    raccoglitoriActions,
    userActions,
    posFirmaActions,
    searchActions,
} from "../../Actions";
import { wMount } from "../../Services/documenti.service";

//helpers
import FormValidationHelper from "../../Helpers/form-validation.js";
import { ModelViewHelper, Constants, Authorize, ServiceMatrix } from "Helpers/model-view.js";
import { getListaFirmatariEsterni, getListaFirmatariInterni } from "../../Helpers";
import { areExternalsPositioned, areInternalsPositioned, areArraysOfObjectsEqual } from "../../Helpers";
import { toPDFPixels, authHeader, capitalizeFirstLetter, utils } from "../../Helpers";

// Importazione lingua italiana dalla libreria moment per il calendario
import "moment/locale/it";

var pathStatic = process.env.REACT_APP_STATIC_FILE;
pdfjs.GlobalWorkerOptions.workerSrc = wMount + "/" + pathStatic + "/pdf.worker.js";

const styles = {
    ...extendedFormsStyle,
    tcSignTableButton: {
        margin: "0px",
        padding: "0px",
        height: "auto!important",
    },
    tcSignTableRow: {
        height: "28px!important",
        fontSize: "14px",
    },
    tcSignTableHeadRow: {
        height: "28px",
    },
    tcSignTableCell: {
        height: "28px!important",
    },
    tcSignTableHead: {
        color: "#AAA !important",
        padding: "14px 0px 0px 0px!important",
        fontSize: "11px!important",
        lineHeight: "1rem!important",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: "400",
    },
    tcSignInputStyle: {
        fontSize: "8px",
    },
    tooltip: {
        fontSize: "14px",
    },
    selectLabel: {
        fontSize: "14px",
        //textTransform: "uppercase",
        color: "#606060 !important",
        top: "8px",
    },
    datePlaceholder: {
        fontSize: "20px",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        color: "#606060 !important",
    },
    tcSignSelectControl: {
        margin: "7px 1px 0px 0px !important",
    },
    dateLabel: {
        fontSize: "22px",
        fontWeight: "bold",
        color: "#606060",
    },
    tcsign_card: {
        "margin-top": "0px",
        "margin-bottom": "0px",
    },
    tcsign_cardbody: {
        "padding-top": "0",
        "padding-bottom": "0",
    },
    tcsign_switchIconChecked: {
        "border-color": "#AAA",
    },
    tcsign_dialog: {
        "max-width": "700px",
    },
    tc_sign_btn_confirm: {
        "background-color": "#4caf50 !important",
    },
    tc_sign_required_div: {
        opacity: "1",
        "font-size": "14px",
        "font-family": '"Roboto", "Helvetica", "Arial", sans-serif',
        color: "#9a9898",
        "font-weight": "400",
        "line-height": "1.42857",
    },
    tcsign_percorso: {
        "line-height": "90px !important",
        padding: "0px !important",
        display: "inline-block",
    },
};

const overlay = css`
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(18, 37, 19, 0.35); /* Black w/ opacity */
`;

const override = css`
    display: block !important;

    border-color: red;
    position: fixed;
    z-index: 9999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

const conciliumDisabled = Authorize.checkAuthorizeFunc("FNCVSD") ? false : true;

const initialState = {
    value: "",
    documento: {},
    idDocumento: "",
    idDocumentoState: "",
    nomeDocumento: "",
    nomeDocumentoState: "",
    descrizioneDocumento: "",
    descrizioneDocumentoState: "",
    id_soggetto_originatore: undefined,
    id_soggetto_originatoreState: undefined,
    id_uo_created: undefined,
    id_uo_createdState: undefined,
    id_priorita: undefined,
    id_prioritaState: undefined,
    id_uo_group: undefined,
    id_uo_groupState: undefined,
    id_raccoglitore: undefined,
    id_raccoglitoreState: undefined,
    id_uo_richiedente: undefined,
    id_uo_richiedenteState: undefined,
    id_uo_rif_interno_protocollo: undefined,
    id_uo_rif_interno_protocolloState: undefined,
    showDettaglioPercorsoModalForm: false, //CAMUSI 23072020
    id_tipo_documento: undefined,
    id_tipo_documentoState: undefined,
    data_documento: undefined,
    data_documentoState: undefined,
    tipoDocumento: "",
    tipoDocumentoState: "",
    raccoglitoreDocumento: "",
    inviaProtocollo: "",
    inviaProtocolloState: "",
    linkDocumento: "",
    idRaccoglitore: "",
    statoDocumento: "",
    files: [],
    file: null,
    direzione: "1",
    signatureAlert: false,
    closeAlert: false,
    activeTab: 0,
    flag_protocollo: undefined,
    qr_code_enable: undefined,
    repertorio: undefined,
    flag_protocollo_uffici: undefined,
    nome: undefined,
    nomeState: undefined,
    descrizione: undefined,
    descrizioneState: undefined,
    numero_protocollo: undefined,
    oggetto_protocollo: undefined,
    impegno_spesa: undefined,
    oggetto_protocolloState: undefined,
    data_protocollo: undefined,
    uo_rif_interno_protocollo: undefined,
    utente_rif_interno_protocollo: undefined,
    submitted: false,
    noteModal: null,
    sollecitoModal: null,
    closeInfo: false,
    confirm: undefined,
};

class DocumentoModalForm extends Dialog {
    constructor(props, context) {
        super(props, context);

        this.state = {
            ...initialState,
        };

        this.componentConfig = {
            postUrl: "no-url",
            addRemoveLinks: false,
            showFiletypeIcon: true,
            dictDefaultMessage: "Clicca o trascina il file da caricare",
        };

        this.djsConfig = {
            autoProcessQueue: false,
            acceptedFiles:
                "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            dictDefaultMessage: "Clicca o trascina il file da caricare",
            previewTemplate: ReactDOMServer.renderToStaticMarkup(
                <div className="dz-preview dz-file-preview">
                    <div className="dz-details"></div>
                    <div className="dz-progress">
                        <span className="dz-upload" data-dz-uploadprogress="true"></span>
                    </div>
                </div>
            ),
        };

        this.eventHandlers = {
            addedfile: (file) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file);
                fileReader.onload = () => {
                    const document = fileReader.result;
                    /*const regex = /(?<=:)(.*)(?=;)/;
                    const fileType = document.match(regex)[0];*/
                    const fileType = utils.base64MimeType(document);
                    console.log("Mimetype uploaded file : ", fileType);

                    if (
                        fileType === "application/pdf" ||
                        fileType === "application/msword" ||
                        fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    ) {
                        this.uploadFile(file);
                    } else
                        this.props.enqueueSnackbar("Il file deve essere di tipo .pdf, .doc o .docx", {
                            variant: "warning",
                        });
                };
            },
        };
        this.navPills = React.createRef();
        this.gestMeeting = React.createRef();
    }

    componentDidCatch(errorString) {
        this.setState({
            error: errorString,
        });
    }

    componentDidMount() {
        const { id, documenti, alert } = this.props;

        this.props.dispatch(raccoglitoriActions.getRaccoglitoreValidatore());
        this.props.dispatch(userActions.getUODefault());

        if (
            !documenti.loading &&
            id &&
            documenti.current &&
            (typeof alert === "undefined" || typeof alert.type === "undefined")
        ) {
            this.props.dispatch(documentiActions.getDocumentoById(id));
        }
    }

    uploadFile(file) {
        const { user } = this.props;
        this.props.dispatch(
            documentiActions.upload(
                {
                    file,
                    id: this.getFormFieldValue("id"),
                },
                user.ruoli
            )
        );
    }

    openNoteModal() {
        this.setState({
            noteModal: (
                <SweetAlert
                    input
                    inputType="textarea"
                    showCancel
                    allowEscape
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Aggiungi un commento"
                    onConfirm={(e) => this.handleAddNota(e)}
                    onCancel={() => this.closeNoteModal()}
                    validationMsg="Digitare il commento nel campo di testo prima di confermare."
                    confirmBtnText="Salva"
                    cancelBtnText="Chiudi"
                    confirmBtnCssClass={this.props.classes.tc_sign_btn_confirm}
                    cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
                />
            ),
        });
    }

    openSollecitoModal(id_documento, id_utente, id_uo, id_contatto) {
        console.log("UTENTE SOLL : " + id_utente + " UO SOLL : " + id_uo + " CONTATTO SOLL: " + id_contatto);
        this.props.dispatch(documentiActions.sendSollecito(id_documento, id_utente, id_uo, undefined, id_contatto));
    }

    closeSollecitoModal() {
        this.setState({
            sollecitoModal: null,
        });
    }

    closeNoteModal() {
        this.setState({
            noteModal: null,
        });
    }

    handleAddNota(e) {
        const { user } = this.props;

        let nota = {
            descrizione: e,
            id_utente: user.id_utente,
            id_documento: this.getFormFieldValue("id"),
        };

        this.props.dispatch(documentiActions.addNota(nota));

        this.setState({
            noteModal: null,
        });
    }

    handleRemoveNota(id_documento, id_nota) {
        this.props.dispatch(
            documentiActions.deleteNota({
                id_documento: id_documento,
                id_nota: id_nota,
            })
        );
    }

    handleClose() {
        const { raccoglitori, documenti, posizionamentoFirma } = this.props;

        const { riunioneChanged } = this.state;
        const { CANVAS_WIDTH, CANVAS_HEIGHT, PDF_WIDTH, PDF_HEIGHT } = posizionamentoFirma?.dimensions ?? {};
        const tags = posizionamentoFirma?.tags ?? [];
        const tagsFetched = posizionamentoFirma?.tagsFetched;

        let firmatariEsterni = getListaFirmatariEsterni(raccoglitori?.current?.percorsi);
        let firmatariInterni = getListaFirmatariInterni(raccoglitori?.current?.percorsi);

        if (documenti?.current && tagsFetched) {
            let tagsProps = [];
            let tagsState = [];

            //create shallow copy of state and props
            tags.forEach((tag) => {
                let coords = toPDFPixels(CANVAS_WIDTH, CANVAS_HEIGHT, PDF_WIDTH, PDF_HEIGHT, {
                    x: tag.left,
                    y: tag.top,
                });
                const coord = {
                    coordinata_x: parseFloat(coords.x).toFixed(4),
                    coordinata_y: parseFloat(coords.y).toFixed(4),
                    pagina: tag.pagina,
                };

                if (tag.id_contatto) coord.id_contatto = tag.id_contatto;
                else if (tag.id_utente) coord.id_utente = tag.id_utente;
                else if (tag.id_uo) coord.id_uo = tag.id_uo;
                tagsState.push(coord);
            });

            documenti.current.coordinate.forEach((coordinata) => {
                const coord = {
                    coordinata_x: parseFloat(coordinata.coordinata_x).toFixed(4),
                    coordinata_y: parseFloat(coordinata.coordinata_y).toFixed(4),
                    pagina: coordinata.pagina,
                };

                if (coordinata.id_contatto) coord.id_contatto = coordinata.id_contatto;
                else if (coordinata.id_utente) coord.id_utente = coordinata.id_utente;
                else if (coordinata.id_uo) coord.id_uo = coordinata.id_uo;

                tagsProps.push(coord);
            });

            const areThereExternals = firmatariEsterni?.length > 0;
            const tag_posizionato_est = areThereExternals ? areExternalsPositioned(tagsProps, firmatariEsterni) : null;
            const tag_posizionato_int = areInternalsPositioned(tagsProps, firmatariInterni, areThereExternals);

            console.log("tagsFetched :>> ", tagsFetched);
            console.log("tagsProps :>> ", tagsProps);
            console.log("tagsState :>> ", tagsState);
            console.log(
                "areArraysOfObjectsEqual(tagsProps, tagsState) :>> ",
                areArraysOfObjectsEqual(tagsProps, tagsState)
            );
            if ((tagsFetched && !areArraysOfObjectsEqual(tagsProps, tagsState)) || riunioneChanged) {
                this.setState({
                    closeInfo: false,
                    confirm: (
                        <SweetAlert
                            info
                            showCancel
                            confirmBtnText="SI"
                            confirmBtnCssClass={this.props.classes.tc_sign_btn_confirm}
                            cancelBtnText="NO"
                            cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
                            title={"Modifiche non salvate"}
                            onConfirm={(e) => {
                                this.hideConfirm();
                                if (tag_posizionato_est === false || tag_posizionato_int === false)
                                    this.props.enqueueSnackbar(
                                        "Le firme di alcuni firmatari non sono state posizionate. Non sarà possibile avviare il processo di firma.",
                                        {
                                            variant: "warning",
                                        }
                                    );

                                this.close();
                            }}
                            onCancel={() => {
                                this.hideConfirm();
                            }}
                        >
                            <p style={{ textAlign: "center", margin: "0 1em" }}>Sicuro di volere chiudere</p>
                        </SweetAlert>
                    ),
                });
            } else {
                if (
                    documenti.current.id_stato_documento === 1 &&
                    (tag_posizionato_est === false || tag_posizionato_int === false)
                )
                    this.setState({
                        signatureAlert:
                            "Le firme di alcuni firmatari non sono state posizionate. Non sarà possibile avviare il processo di firma.",
                    });
                else this.close();
            }
        } else {
            this.close();
        }
    }

    hideSignatureAlert() {
        this.setState({ signatureAlert: false });
    }

    hideConfirm() {
        this.setState({
            confirm: undefined,
        });
    }

    close() {
        const { user } = this.props;
        this.navPills.current.setState({ init: 0 });
        this.setState(initialState);
        this.props.dispatch(documentiActions.resetDocument());
        this.props.dispatch(posFirmaActions.resetPosizionamentoFirma());
        this.props.dispatch(documentiActions.getAll(undefined, user.ruoli));
        this.props.dispatch(documentiActions.getTaskList());
        this.props.dispatch(raccoglitoriActions.resetRaccoglitore());
        this.props.onClose();
    }

    handleCloseAlert() {
        this.props.dispatch(alertActions.clear());
    }

    handleSelect = (event) => {
        const { user } = this.props;
        let { name, value } = event.target;
        if (typeof value === "undefined") {
            value = "-1";
        }

        if (name === "id_raccoglitore" && value !== "-1") {
            this.props.dispatch(raccoglitoriActions.getRaccoglitoreById(value));
        }

        let wState = { [name]: value };
        if (name === "id_uo_created") {
            wState["id_uo_rif_interno_protocollo"] = value;
        }

        this.setState(wState);

        if (name === "id_tipo_documento" && user?.protocolFlag === "true") this.setRepertorio(value);
    };

    change(event, stateName, type, stateNameEqualTo, maxValue) {
        const { value, checked } = event.target;

        let wState = {};
        if (
            stateName === "flag_protocollo" ||
            stateName === "qr_code_enable" ||
            stateName === "repertorio" ||
            stateName === "flag_protocollo_uffici"
        ) {
            wState[stateName] = checked;
        } else {
            wState[stateName] = value;

            if (stateName === "descrizione") wState["oggetto_protocollo"] = value;
        }

        if (stateName === "flag_protocollo") this.setState({ repertorio: false, flag_protocollo_uffici: false });
        else if (stateName === "repertorio") this.setState({ flag_protocollo: false, flag_protocollo_uffici: false });
        else if (stateName === "flag_protocollo_uffici") this.setState({ repertorio: false, flag_protocollo: false });

        wState[stateName + "State"] = FormValidationHelper.validateField(type, value, stateNameEqualTo);

        this.setState(wState);
    }

    setRepertorio(value) {
        //Lettera, Altro --> no repertorio
        var idTipoNoRepertorio = [1, 2];
        if (value && value !== -1 && !idTipoNoRepertorio.includes(value))
            this.setState({
                repertorio: true,
                flag_protocollo: false,
                flag_protocollo_uffici: false,
            });
        else this.setState({ repertorio: false });
    }

    handleFileDownload = (uuid, documento) => {
        this.props.dispatch(documentiActions.download(uuid, documento));
    };

    handleClickDeleteDocumento(idDoc, uuid) {
        this.props.dispatch(documentiActions.deleteFile(idDoc, uuid));
    }

    handleClickSendProtocollo(idDoc, uuid, checked) {
        this.props.dispatch(documentiActions.updateSendProtocollo(idDoc, uuid, checked));
    }

    handleSubmit() {
        const { documenti, user, raccoglitori, users } = this.props;
        const { defaultUO } = users;
        const { raccValidatore } = raccoglitori;

        //let nomeState = FormValidationHelper.validateField("required", this.getFormFieldValue("nome"), 1);
        //Il nome lo valorizzo automaticamente con lo stesso valore della descrizione (Richiesta cliente di eliminare il campo)
        let nomeState = FormValidationHelper.validateField("required", this.getFormFieldValue("descrizione"), 1);
        let descrizioneState = FormValidationHelper.validateField("required", this.getFormFieldValue("descrizione"), 1);
        let id_soggetto_originatoreState = FormValidationHelper.validateField(
            "required-select",
            this.getFormFieldValue("id_soggetto_originatore")
        );
        let id_tipo_documentoState = FormValidationHelper.validateField(
            "required-select",
            this.getFormFieldValue("id_tipo_documento")
        );
        let data_documentoState = FormValidationHelper.validateField(
            "required",
            this.getFormFieldValue("data_documento")
        );

        let wUpdateState = {
            nomeState: nomeState,
            descrizioneState: descrizioneState,
            activeTab: 0,
            data_documentoState: data_documentoState,
            id_soggetto_originatoreState: id_soggetto_originatoreState,
            id_tipo_documentoState: id_tipo_documentoState,
        };

        if (
            nomeState === "error" ||
            descrizioneState === "error" ||
            data_documentoState === "error" ||
            id_soggetto_originatoreState === "error" ||
            id_tipo_documentoState === "error"
        ) {
            this.setState(wUpdateState);
            this.props.dispatch(alertActions.error("Attenzione errori di compilazione nella scheda Dati Documento"));
            return;
        }

        this.setState(wUpdateState);

        this.setState({
            closeAlert: false,
            submitted: true,
        });

        let idDocumento = this.getFormFieldValue("id");
        let flagProtocollo = this.getFormFieldValue("flag_protocollo");
        let qr_code_enable = this.getFormFieldValue("qr_code_enable");
        let repertorio = this.getFormFieldValue("repertorio");
        let flag_protocollo_uffici = this.getFormFieldValue("flag_protocollo_uffici");
        let id_uo_rif_interno_protocollo = this.getFormFieldValue("id_uo_rif_interno_protocollo");
        let id_soggetto_originatore = this.getFormFieldValue("id_soggetto_originatore");
        let oggetto_protocollo = this.getFormFieldValue("oggetto_protocollo");
        let data_documento = this.getFormFieldValue("data_documento");
        let data_documento_frm = moment(data_documento, "DD/MM/YYYY").format("YYYY-MM-DD");

        let joinDocMeeting = undefined;

        let id_uo_created = defaultUO ? defaultUO.filter((uo) => uo.cod_integrazione === "uo_coop")[0] : undefined;
        let id_uo_richiedente = defaultUO
            ? defaultUO.filter((uo) => uo.cod_integrazione === "uo_usigrai")[0]
            : undefined;

        let documento = {
            id_documento: idDocumento === "" ? undefined : idDocumento,
            nome: this.getFormFieldValue("descrizione"),
            descrizione: this.getFormFieldValue("descrizione"),
            id_tipo_documento: this.getFormFieldValue("id_tipo_documento"),
            data_documento: data_documento_frm,
            priorita: this.getFormFieldValue("id_priorita"),
            flag_protocollo: flagProtocollo === "" ? false : flagProtocollo,
            qr_code_enable: qr_code_enable === "" ? false : qr_code_enable,
            repertorio: repertorio === "" ? false : repertorio,
            flag_protocollo_uffici: flag_protocollo_uffici === "" ? false : flag_protocollo_uffici,
            id_uo_rif_interno_protocollo:
                id_uo_rif_interno_protocollo !== "" ? id_uo_rif_interno_protocollo : undefined,
            id_soggetto_originatore: id_soggetto_originatore,
            oggetto_protocollo,
            id_uo_created: id_uo_created.id_uo_group,
            id_uo_richiedente: id_uo_richiedente.id_uo_group,
            id_raccoglitore: raccValidatore.id_raccoglitore,
        };

        let { abilitaAnnulla } = this.state;
        if (this.state.associazioneDocRiunione) {
            joinDocMeeting = this.state.associazioneDocRiunione;
            joinDocMeeting["id_utente_ult_modif"] = user.id_utente;
            joinDocMeeting["data_ult_modif"] = moment(new Date()).format();
            joinDocMeeting["data_creazione"] = moment(new Date()).format();
            documento["associazioneDocRiunione"] = joinDocMeeting;
            abilitaAnnulla = false;
        } else if (this.state.riunioneChanged) {
            abilitaAnnulla = false;
        } else if (documenti?.current?.argomentoAssociato) {
            documento["associazioneDocRiunione"] = documenti.current.argomentoAssociato;
        }

        this.setState({
            riunioneChanged: false,
            abilitaAnnulla: abilitaAnnulla,
            closeAlert: false,
            submitted: true,
        });

        this.props.dispatch(documentiActions.save(documento, undefined, user.ruoli));
    }

    handleSubmitAssociated() {
        const { documenti, user } = this.props;
        let joinDocMeeting = undefined;
        let idDocumento = this.getFormFieldValue("id");
        let documento = {
            id_documento: idDocumento,
        };
        let abilitaAnnulla = this.state.abilitaAnnulla;
        if (this.state.associazioneDocRiunione) {
            joinDocMeeting = this.state.associazioneDocRiunione;
            joinDocMeeting["id_utente_ult_modif"] = user.id_utente;
            joinDocMeeting["data_ult_modif"] = moment(new Date()).format();
            joinDocMeeting["data_creazione"] = moment(new Date()).format();
            documento["associazioneDocRiunione"] = joinDocMeeting;
            abilitaAnnulla = false;
        } else if (this.state.riunioneChanged) {
            abilitaAnnulla = false;
        } else if (documenti?.current?.argomentoAssociato) {
            documento["associazioneDocRiunione"] = documenti.current.argomentoAssociato;
        }

        this.setState({
            riunioneChanged: false,
            abilitaAnnulla: abilitaAnnulla,
            closeAlert: false,
            submitted: true,
        });

        let coordinate = documenti.current.coordinate;

        this.child.simpleReset();
        this.props.dispatch(documentiActions.save(documento, coordinate, user.ruoli));
    }

    handleUpdateMetadata() {
        let impegno_spesa = this.getFormFieldValue("impegno_spesa");
        let idDocumento = this.getFormFieldValue("id");

        if (impegno_spesa) {
            let metadata = {
                impegno_spesa: impegno_spesa,
            };

            this.props.dispatch(documentiActions.updateMetadata(idDocumento, metadata, false));
        } else {
            this.props.dispatch(alertActions.error("Compilare il campo"));
            return;
        }
    }

    handleSelectedUOInternoProtocollo = (selectedValue, fieldName) => {
        if (typeof selectedValue.id_unita_organizzativa === "undefined") {
            selectedValue.id_unita_organizzativa = "-1";
        }

        this.setState({
            selectUtentiDisabled: false,
            id_uo_rif_interno_protocollo: selectedValue.id_unita_organizzativa,
            uo_rif_interno_protocollo: selectedValue.path,
            selectedUtente: undefined,
        });
    };

    handleSelectedUORichiedente = (selectedValue, fieldName) => {
        if (typeof selectedValue.id_unita_organizzativa === "undefined") {
            selectedValue.id_unita_organizzativa = null;
        }

        this.setState({
            selectUtentiDisabled: false,
            id_uo_richiedente: selectedValue.id_unita_organizzativa,
            utente_uo_richiedente: selectedValue.path,
            selectedUtente: undefined,
        });
    };

    handleSelectedUOOrigine = (selectedValue, fieldName) => {
        this.setState({
            selectUtentiDisabled: false,
            id_uo_created: selectedValue.id_unita_organizzativa,
            id_uo_rif_interno_protocollo: selectedValue.id_unita_organizzativa,
            uo_rif_interno_protocollo: selectedValue.path,
            utente_uo_origine: selectedValue.path,
            selectedUtente: undefined,
        });
    };

    handleSelectedUtente = (selectedValue) => {
        this.setState({ selectedUtente: selectedValue });
    };

    //CAMUSI inizio 23072020
    openDettaglioPercorsoModalForm() {
        const { documenti } = this.props;

        this.setState({
            showDettaglioPercorsoModalForm: true,
        });

        let raccoglitoreCorrente = this.state.id_raccoglitore
            ? this.state.id_raccoglitore
            : documenti.current && documenti.current.id_raccoglitore
            ? documenti.current.id_raccoglitore
            : undefined;
        if (raccoglitoreCorrente) this.props.dispatch(raccoglitoriActions.getRaccoglitoreById(raccoglitoreCorrente));
    }

    handleCloseDettaglioPercorsoModalForm() {
        this.setState({
            showDettaglioPercorsoModalForm: false,
        });
    }
    //CAMUSI fine 23072020

    basicAlert(pMessage) {
        this.setState({
            alertMessage: (
                <SweetAlert danger title="Attenzione">
                    {pMessage}
                </SweetAlert>
            ),
            submitted: false,
        });
    }

    getNoteList(id_documento, note) {
        const { user } = this.props;

        let wNoteList =
            typeof note === "undefined"
                ? []
                : note.map((prop, key) => {
                      let wButtonRemove = "";

                      if (prop.id_utente === user.id_utente) {
                          wButtonRemove = (
                              <Tooltip id="tooltip-top" title="Elimina commento" placement="bottom">
                                  <Button
                                      style={{
                                          width: "20px",
                                          height: "20px",
                                          minWidth: "20px",
                                          margin: "0px",
                                          padding: "0px",
                                      }}
                                      justIcon
                                      round
                                      simple
                                      onClick={() => this.handleRemoveNota(id_documento, prop.id_note_documento)}
                                      color="danger"
                                  >
                                      <Close />
                                  </Button>
                              </Tooltip>
                          );
                      }

                      return {
                          inverted: true,
                          badgeColor: "success",
                          badgeIcon: Extension,
                          title: (
                              <div>
                                  {prop.utente ? prop.utente : prop.contatto}
                                  {wButtonRemove}
                              </div>
                          ),
                          titleColor: "success",
                          body: <p>{prop.descrizione}</p>,
                          footerTitle: (
                              <Moment parse="YYYY-MM-DDTHH:mm:ss.msZ" format="DD/MM/YYYY HH:mm:ss">
                                  {prop.data_creazione}
                              </Moment>
                          ),
                      };
                  });

        if (Authorize.checkAuthorizeFunc("FNCINT")) {
            wNoteList.push({
                inverted: true,
                badgeColor: "info",
                badgeIcon: Add,
                title: "",
                titleColor: "info",
                body: (
                    <div>
                        <Button color="info" onClick={this.openNoteModal.bind(this)}>
                            <h5>Aggiungi un commento</h5>
                        </Button>
                    </div>
                ),
                footerTitle: "",
            });
        }

        return wNoteList;
    }

    handleSelezioneRiunione(value, changed, enable) {
        this.setState({
            associazioneDocRiunione: value,
            riunioneChanged: changed,
            abilitaAnnulla: enable,
        });
    }

    handleChangeRiunione(enable, change) {
        this.setState({ abilitaAnnulla: enable, riunioneChanged: change });
    }

    getFormFieldValue(pFieldName) {
        const { documenti, user } = this.props;
        let wReturn;
        if (typeof this.state[pFieldName] !== "undefined") {
            return this.state[pFieldName];
        } else if (typeof documenti.current !== "undefined") {
            wReturn = ModelViewHelper.document_ModelToView(documenti.current);

            if (pFieldName === "note") {
                return this.getNoteList(wReturn.id, wReturn[pFieldName]);
            }

            return wReturn[pFieldName];
        }

        if (pFieldName === "flag_protocollo") {
            return true;
        }

        if (pFieldName === "note" || pFieldName === "flusso_operativo") {
            return [];
        }

        if (
            this.state.id_stato_documento === Constants.documento.stato.inBozza ||
            this.state.id_stato_documento === ""
        ) {
            if (pFieldName === "id_uo_created" || pFieldName === "id_uo_rif_interno_protocollo") {
                return this.state[pFieldName];
            }
        } else if (
            this.state.id_stato_documento !== Constants.documento.stato.inBozza &&
            this.state.id_stato_documento !== ""
        ) {
            if (pFieldName === "id_uo_created" || pFieldName === "id_uo_rif_interno_protocollo") {
                if (typeof user !== "undefined" && typeof user.unita_organizzative) {
                    if (user.unita_organizzative.length > 1)
                        return user.unita_organizzative[user.unita_organizzative.length - 2].id_uo_group;
                    else if (user.unita_organizzative.length === 1)
                        return user.unita_organizzative[user.unita_organizzative.length - 1].id_uo_group;
                }
            }
        }

        return "";
    }

    static getDerivedStateFromProps = (props, state) => {
        const { documenti } = props;

        //PDF FILE REGEX
        let fileUrlReg = "[^/]+$";
        let uuid = state?.file?.url.match(fileUrlReg)[0];

        //if main pdf has changed reset tags
        if (documenti?.current?.files.length > 0 && documenti?.current?.files[0].uuid !== uuid) {
            state.file = {
                url: wMount + "/api/v1/documents/download/" + documenti.current.files[0].uuid,
                httpHeaders: { ...authHeader() },
            };
        }
        return { ...state };
    };

    onFileChange = (file, idDocumento, tipo_file) => {
        if (idDocumento) this.props.dispatch(documentiActions.getDocumentoById(idDocumento));
        this.setState({
            file,
            tipo_file,
        });
    };

    handleClickTab(active) {
        if (active === 5) this.props.dispatch(documentiActions.getSessionId());
    }

    handleDeleteFlusso(id_documento, idStepFlusso) {
        this.setState({
            closeInfo: false,
            sweetAlert: (
                <SweetAlert
                    info
                    showCancel
                    confirmBtnText="Ok"
                    confirmBtnCssClass={this.props.classes.tc_sign_btn_confirm}
                    cancelBtnText="Annulla"
                    cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
                    title="Vuoi cancellare il Firmatario/Unità Organizzativa dal percorso?"
                    onConfirm={(e) => {
                        this.hideAlert();
                        this.props.dispatch(documentiActions.deleteStepById(id_documento, idStepFlusso));
                    }}
                    onCancel={() => {
                        this.hideAlert();
                    }}
                ></SweetAlert>
            ),
        });
    }

    hideAlert() {
        this.setState({
            sweetAlert: undefined,
            closeInfo: true,
        });
    }

    verificaPresenzaPercorso(raccoglitori, raccoglitoreCorrente) {
        let esistente = false;
        if (raccoglitori && raccoglitori.items) {
            for (let i = 0; i < raccoglitori.items.length; i++) {
                if (raccoglitori.items[i].id_raccoglitore === raccoglitoreCorrente.value) {
                    esistente = true;
                }
            }
        }
        return esistente;
    }

    setFilters = (name, value) => {
        value = value && value !== "" ? value : undefined;
        this.props.dispatch(searchActions.setFilter(name, value));
    };

    handleDate = (value) => {
        const data_creazione = new Date(value).toLocaleDateString("en-GB");
        this.setState({
            data_documento: data_creazione,
            data_documentoState: "success",
        });
    };

    render() {
        const { classes, documenti, id, utils, raccoglitori, alert, user, uuid, tema } = this.props;
        const {
            noteModal,
            activeTab,
            sollecitoModal,
            signatureAlert,
            confirm,
            file,
            sweetAlert,
            data_documento,
            id_soggetto_originatore,
        } = this.state;
        const { idDocumentoState } = this.state;
        let cardColor = tema.theme_color;

        let posFirmaDisabled = true;
        let listaFirmatariEsterni = [];
        let listaFirmatariInterni = [];
        let editableMeeting = false;

        if (raccoglitori?.current?.percorsi?.length > 0) {
            let listExtTmp = getListaFirmatariEsterni(raccoglitori.current.percorsi);
            let listIntTmp = getListaFirmatariInterni(raccoglitori.current.percorsi);
            let lengthInterniVisto = listIntTmp.filter((f) => f.flag_firma_vista === "V").length;

            //disabilita tab Positionamento Firma se vi son solo firmatari interni con V flag
            if (
                documenti?.current?.files.length > 0 &&
                (listExtTmp.length > 0 || (listIntTmp.length > 0 && listIntTmp.length !== lengthInterniVisto))
            ) {
                posFirmaDisabled = false;
            }

            listaFirmatariEsterni = listExtTmp.map((prop) => ({
                value: capitalizeFirstLetter(prop.contatto_owner),
                label: capitalizeFirstLetter(prop.contatto_owner),
                id: prop.id_contatto_owner,
                tipo_firmatario: "Esterno",
            }));

            listaFirmatariInterni = listIntTmp.map((prop) => ({
                value: capitalizeFirstLetter(prop.utente_owner ?? prop.uo_owner),
                label: capitalizeFirstLetter(prop.utente_owner ?? prop.uo_owner),
                id: prop.id_utente_owner ?? prop.id_uo_owner,
                tipo_firmatario: prop.id_utente_owner ? "Interno" : "InternoUO",
            }));
        }

        let fileUrl;
        if (uuid) {
            fileUrl = {
                url: wMount + "/api/v1/documents/download/" + uuid,
                httpHeaders: { ...authHeader() },
            };
        }

        let alertMessage = "";
        if (alert && typeof alert.message !== "undefined") {
            if (alert.type === "alert-success") {
                alertMessage = (
                    <SweetAlert success title="Info" onConfirm={() => this.handleCloseAlert()}>
                        {alert.message}
                    </SweetAlert>
                );
            } else if (alert.type === "alert-danger") {
                alertMessage = (
                    <SweetAlert
                        danger
                        title="Attenzione"
                        onConfirm={() => {
                            this.handleCloseAlert();
                            if (alert.message === "Sessione scaduta, eseguire il login.") {
                                this.props.dispatch(userActions.logout());
                            }
                        }}
                    >
                        {alert.message}
                    </SweetAlert>
                );
            }
        }

        let loading;
        if (documenti?.loading) {
            loading = (
                <div className={overlay}>
                    <ClipLoader
                        className={override}
                        sizeUnit={"px"}
                        size={80}
                        color={"#3a783d"}
                        loading={documenti.loading}
                    />
                </div>
            );
        }

        let title;

        if (typeof id === "undefined") title = "Nuovo documento";
        else if (documenti?.current && !documenti.loading) {
            title = this.getFormFieldValue("descrizione") + " - (id: " + this.getFormFieldValue("id") + ")";
        }

        let optionTipoDocumento = [];
        if (typeof utils.tipologia_documento !== "undefined") {
            optionTipoDocumento = utils.tipologia_documento.map((tipo_documento) => ({
                value: tipo_documento.id_tipo_documento,
                label: tipo_documento.desc_tipo_documento,
            }));
        }

        let optionid_uo_group = [];
        for (let k in Constants.documento.id_uo_group) {
            optionid_uo_group.push({
                value: k,
                label: Constants.documento.id_uo_group[k],
            });
        }

        let optionPriorita = [];
        for (let k in Constants.documento.priorita) {
            optionPriorita.push({
                value: k,
                label: Constants.documento.priorita[k],
            });
        }

        let optionUO_Utente = [];

        if (
            this.getFormFieldValue("id_stato_documento") === Constants.documento.stato.inBozza ||
            this.getFormFieldValue("id_stato_documento") === ""
        ) {
            if (typeof user !== "undefined" && typeof user.unita_organizzative !== "undefined") {
                optionUO_Utente = user.unita_organizzative.map((prop) => ({
                    value: prop.id_uo_group,
                    label: prop.nome,
                }));
            }
        } else if (
            this.getFormFieldValue("id_stato_documento") !== Constants.documento.stato.inBozza &&
            this.getFormFieldValue("id_stato_documento") !== ""
        ) {
            optionUO_Utente = utils.unita_organizzative.map((prop, key) => {
                return {
                    value: prop.id_uo_group,
                    label: prop.path,
                    dismessa: prop.dismessa,
                };
            });
        }

        let optionRaccoglitoriSearch = [];
        if (typeof raccoglitori.items !== "undefined") {
            optionRaccoglitoriSearch = raccoglitori.items.map((prop, key) => {
                let option = ModelViewHelper.raccoglitori_ModelToView(prop);
                return {
                    value: option.id,
                    label: option.nome,
                    valido: option.flag_validita,
                };
            });
        }
        if (documenti?.current) {
            let raccoglitoreCorrente = {
                value: documenti.current.id_raccoglitore,
                label: documenti.current.nome_raccoglitore,
                valido: false,
            };
            let idRaccCoincidenti = this.verificaPresenzaPercorso(raccoglitori, raccoglitoreCorrente);
            if (!idRaccCoincidenti) optionRaccoglitoriSearch.push(raccoglitoreCorrente);
        }

        let disabled_impegno_spesa = false;
        if (!Authorize.checkAuthorize(ServiceMatrix.ModifyMetadata)) {
            disabled_impegno_spesa = true;
        }

        let disabled_protocollo = false;
        if (
            this.getFormFieldValue("id_stato_documento") !== Constants.documento.stato.inBozza &&
            this.getFormFieldValue("id_stato_documento") !== ""
        ) {
            disabled_protocollo = true;
        } else {
            if (this.getFormFieldValue("flag_protocollo")) {
                disabled_protocollo = false;
            } else {
                disabled_protocollo = true;
            }
        }

        let wUploadSection = "";
        let fileListSection = "";
        let downloadFile = [];
        let wButtonActionDisabled = true;

        if (documenti?.current?.id_stato_documento === Constants.documento.stato.inBozza) {
            wButtonActionDisabled = false;
        }

        if (documenti?.current?.files.length > 0) {
            if (Authorize.checkAuthorizeFunc("FNCIMS")) {
                disabled_impegno_spesa = false;
            }

            var this_ = this;
            var disableDelete = false;
            documenti.current.files.forEach(function (file, index, array) {
                if (array.length > 1 && file.tipo_file === "P") disableDelete = true;
                else if (array.length > 1 && file.tipo_file === "A") disableDelete = false;
                else if (array.length === 1 && file.tipo_file === "P") disableDelete = false;

                if (wButtonActionDisabled) disableDelete = true;

                let tipo_file = file.tipo_file === "P" ? "Principale" : "Allegato";
                let fileAction = (
                    <Tooltip
                        id="tooltip-top"
                        title="Elimina il file"
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                    >
                        <Button
                            justIcon
                            round
                            simple
                            onClick={() => this_.handleClickDeleteDocumento(file.id_documento, file.uuid)}
                            color="danger"
                            disabled={disableDelete}
                            className={classes.tcSignTableButton}
                        >
                            <Close />
                        </Button>
                    </Tooltip>
                );

                var disableCheck = {};
                if (wButtonActionDisabled) disableCheck = { disabled: true };

                let chooseProtocollo = "";
                if (file.tipo_file !== "P")
                    chooseProtocollo = (
                        <Checkbox
                            {...disableCheck}
                            tabIndex={-1}
                            onChange={(e) =>
                                this_.handleClickSendProtocollo(file.id_documento, file.uuid, e.target.checked)
                            }
                            checked={file.send_protocollo}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                                checked: classes.checked,
                            }}
                        />
                    );

                let row = [
                    <Button
                        color="primary"
                        simple
                        className={classes.marginRight + " " + classes.tcSignTableButton}
                        onClick={() => this_.handleFileDownload(file.uuid, documenti.current)}
                    >
                        {file.file_name}
                    </Button>,
                    tipo_file,
                    chooseProtocollo,
                    fileAction,
                ];
                downloadFile.push(row);
            });

            fileListSection = (
                <Table
                    tableHeaderColor="warning"
                    tableHead={["Nome", "Tipologia", "Invia al protocollo", "Azione"]}
                    tableData={downloadFile}
                    customCellClasses={[classes.left + " " + classes.tcSignTableCell, classes.center]}
                    customRowClasses={classes.tcSignTableRow}
                    customHeadRowClasses={classes.tcSignTableHeadRow}
                    customClassesForCells={[0, 1, 2, 3]}
                    customHeadCellClasses={[
                        classes.tcSignTableHead,
                        classes.center + ", " + classes.tcSignTableHead,
                        classes.center + ", " + classes.tcSignTableHead,
                        classes.center + ", " + classes.tcSignTableHead,
                    ]}
                    customHeadClassesForCells={[0, 1, 2, 3]}
                />
            );
        } else disabled_impegno_spesa = true;

        if (this.getFormFieldValue("id_stato_documento") !== "" && !wButtonActionDisabled) {
            wUploadSection = (
                <DropzoneComponent
                    config={this.componentConfig}
                    eventHandlers={this.eventHandlers}
                    djsConfig={this.djsConfig}
                    dictDefaultMessage={"Clicca o trascina il file da caricare"}
                    disablePreview={true}
                ></DropzoneComponent>
            );
        }

        let wButtonSalva = "",
            isAssociated = true;
        if (
            (this.getFormFieldValue("id_stato_documento") === "" ||
                this.getFormFieldValue("id_stato_documento") === Constants.documento.stato.inBozza) &&
            Authorize.checkAuthorizeFunc("FNCCMD")
        )
            isAssociated = false;

        wButtonSalva = (
            <Button
                color={cardColor}
                onClick={!isAssociated ? () => this.handleSubmit() : () => this.handleSubmitAssociated()}
                disabled={!id || (id && documenti.current) ? false : true}
            >
                <Save style={{ margin: "0px" }} /> Salva
            </Button>
        );

        let wFormDisabled = false;
        if (
            this.getFormFieldValue("id_stato_documento") !== Constants.documento.stato.inBozza &&
            this.getFormFieldValue("id_stato_documento") !== ""
        ) {
            wFormDisabled = true;
        }

        //CAMUSI INIZIO 30072020
        let iconaStepPercorso;

        if (
            (documenti?.current && this.state.id_raccoglitore === undefined) ||
            (this.state.id_raccoglitore !== undefined && this.state.id_raccoglitore !== -1)
        ) {
            iconaStepPercorso = (
                <Tooltip
                    id="tooltip-top"
                    title="Elenco step del percorso di firma"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                >
                    <Button
                        justIcon
                        round
                        simple
                        onClick={() => this.openDettaglioPercorsoModalForm()}
                        color="success"
                        className={classes.tcsign_percorso}
                    >
                        <Search />
                    </Button>
                </Tooltip>
            );
        }

        let tooltipUnitaOrganizzatrice;
        let uoOrganizzatricePath;
        let uoDiDefaultInCreazione;

        if (utils?.unita_organizzative) {
            //-----------------------------------
            if (this.state.id_uo_created === undefined && documenti.current === undefined) {
                if (typeof user !== "undefined" && typeof user.unita_organizzative) {
                    if (user.unita_organizzative.length > 1)
                        uoDiDefaultInCreazione =
                            user.unita_organizzative[user.unita_organizzative.length - 2].id_uo_group;
                    else if (user.unita_organizzative.length === 1)
                        uoDiDefaultInCreazione =
                            user.unita_organizzative[user.unita_organizzative.length - 1].id_uo_group;
                }
                for (let x = 0; x < utils.unita_organizzative.length; x++) {
                    if (uoDiDefaultInCreazione === utils.unita_organizzative[x].id_uo_group) {
                        uoOrganizzatricePath = utils.unita_organizzative[x].path;
                        break;
                    }
                }
            }
            //...................................
            if (this.state.id_uo_created !== undefined) {
                for (let y = 0; y < utils.unita_organizzative.length; y++) {
                    if (this.state.id_uo_created === utils.unita_organizzative[y].id_uo_group) {
                        uoOrganizzatricePath = utils.unita_organizzative[y].path;
                        break;
                    }
                }
            } else {
                if (documenti?.current) {
                    for (let i = 0; i < utils.unita_organizzative.length; i++) {
                        if (documenti.current.id_uo_created === utils.unita_organizzative[i].id_uo_group) {
                            uoOrganizzatricePath = utils.unita_organizzative[i].path;
                            break;
                        }
                    }
                }
            }
        }
        tooltipUnitaOrganizzatrice = (
            <FormControl
                fullWidth
                disabled={wFormDisabled}
                className={classes.selectFormControl + " " + classes.tcSignSelectControl}
            >
                <SelectTooltip
                    success={this.state.id_uo_createdState === "success"}
                    error={this.state.id_uo_createdState === "error"}
                    label={"Soggetto originatore *"}
                    placeholder={"Selezionare il soggetto originatore"}
                    options={optionUO_Utente}
                    isClearable={true}
                    id={"id_uo_created"}
                    isSearchable={true}
                    value={this.getFormFieldValue("id_uo_created") || ""}
                    onChange={this.handleSelect}
                    disabled={wFormDisabled}
                />
            </FormControl>
        );

        let optionSO = [];

        if (utils?.soggettiOriginatori) {
            optionSO = utils.soggettiOriginatori.map((uo) => ({
                value: uo.id_soggetto_originatore,
                label: uo.descrizione,
            }));
        }

        let uoRichiedentePath;

        if (utils?.unita_organizzative) {
            if (this.state.id_uo_richiedente !== undefined) {
                for (let z = 0; z < utils.unita_organizzative.length; z++) {
                    if (this.state.id_uo_richiedente === utils.unita_organizzative[z].id_uo_group) {
                        uoRichiedentePath = utils.unita_organizzative[z].path;
                        break;
                    }
                }
            } else {
                if (documenti?.current) {
                    for (let i = 0; i < utils.unita_organizzative.length; i++) {
                        if (documenti.current.id_uo_richiedente === utils.unita_organizzative[i].id_uo_group) {
                            uoRichiedentePath = utils.unita_organizzative[i].path;
                            break;
                        }
                    }
                }
            }
        }

        let dataDocumentoVal = data_documento ? data_documento : this.getFormFieldValue("data_documento_str");
        return (
            <Dialog
                open={true}
                TransitionComponent={Transition}
                keepMounted
                scroll="body"
                fullScreen
                onEntering={() => this.onFileChange(fileUrl, id, "P")}
                onClose={this.close}
                aria-labelledby="notice-modal-slide-title"
                aria-describedby="notice-modal-slide-description"
            >
                {loading}
                {noteModal}
                {sollecitoModal}
                {alertMessage}
                {confirm}
                {sweetAlert}
                <DialogContent id="notice-modal-slide-description" className={classes.modalBody}>
                    <Card style={{ marginBottom: "0px" }}>
                        <CardHeader color={cardColor} icon>
                            <CardIcon color={cardColor}>
                                <Folder />
                            </CardIcon>
                            <h4 className={classes.cardTitle} style={{ color: "#777" }}>
                                {title}
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <NavPills
                                ref={this.navPills}
                                init={activeTab}
                                color={cardColor}
                                onClick={(active) => this.handleClickTab(active)}
                                tabs={[
                                    {
                                        tabButton: "Dati documento",
                                        tabContent: (
                                            <GridContainer style={{ width: "100%", margin: "0px" }}>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={3}>
                                                            <CustomInput
                                                                labelText="Creato da"
                                                                id="creato_da"
                                                                formControlProps={{
                                                                    fullWidth: false,
                                                                }}
                                                                inputProps={{
                                                                    disabled: true,

                                                                    value:
                                                                        this.getFormFieldValue("utente_originatore") ||
                                                                        "",
                                                                }}
                                                                className={classes.tcSignInputStyle}
                                                            />
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={3}>
                                                            <CustomInput
                                                                labelText="Data creazione"
                                                                formControlProps={{
                                                                    fullWidth: false,
                                                                }}
                                                                inputProps={{
                                                                    disabled: true,
                                                                    value:
                                                                        this.getFormFieldValue("data_creazione_str") ||
                                                                        "",
                                                                }}
                                                                className={classes.tcSignInputStyle}
                                                            />
                                                        </GridItem>
                                                    </GridContainer>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12} style={{ marginTop: "30px" }}>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={6}>
                                                            <CustomInput
                                                                success={this.state.descrizioneState === "success"}
                                                                error={this.state.descrizioneState === "error"}
                                                                labelText="Oggetto Documento"
                                                                id="descrizione"
                                                                formControlProps={{ fullWidth: true, required: true }}
                                                                inputProps={{
                                                                    disabled: wFormDisabled,
                                                                    onChange: (event) =>
                                                                        this.change(event, "descrizione", "required"),
                                                                    type: "descrizione",
                                                                    value: this.getFormFieldValue("descrizione") || "",
                                                                }}
                                                            />
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={6}>
                                                            <FormControl
                                                                fullWidth
                                                                classes={{ root: classes.fromControlRoot }}
                                                            >
                                                                <label className={classes.dateLabel}>
                                                                    Data documento *
                                                                </label>
                                                                <Datetime
                                                                    closeOnSelect={true}
                                                                    locale="IT-it"
                                                                    timeFormat={false}
                                                                    onChange={(value) => this.handleDate(value._d)}
                                                                    inputProps={{
                                                                        placeholder: `Selezionare la data del documento`,
                                                                        readOnly: true,
                                                                        style: styles.datePlaceholder,
                                                                    }}
                                                                    value={dataDocumentoVal || ""}
                                                                />
                                                            </FormControl>
                                                        </GridItem>
                                                    </GridContainer>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={6}>
                                                            <FormControl
                                                                fullWidth
                                                                disabled={wFormDisabled}
                                                                className={
                                                                    classes.selectFormControl +
                                                                    " " +
                                                                    classes.tcSignSelectControl
                                                                }
                                                            >
                                                                <SelectSearch
                                                                    success={
                                                                        this.state.id_tipo_documentoState === "success"
                                                                    }
                                                                    error={
                                                                        this.state.id_tipo_documentoState === "error"
                                                                    }
                                                                    label={"Tipologia documento *"}
                                                                    placeholder={
                                                                        "Selezionare la tipologia di documento"
                                                                    }
                                                                    options={optionTipoDocumento}
                                                                    isClearable={true}
                                                                    id={"id_tipo_documento"}
                                                                    isSearchable={false}
                                                                    value={
                                                                        this.getFormFieldValue("id_tipo_documento") ||
                                                                        ""
                                                                    }
                                                                    onChange={this.handleSelect}
                                                                    disabled={wFormDisabled}
                                                                />
                                                            </FormControl>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={6}>
                                                            <FormControl
                                                                fullWidth
                                                                disabled={wFormDisabled}
                                                                className={
                                                                    classes.selectFormControl +
                                                                    " " +
                                                                    classes.tcSignSelectControl
                                                                }
                                                            >
                                                                <SelectSearch
                                                                    success={this.state.id_uo_groupState === "success"}
                                                                    error={this.state.id_uo_groupState === "error"}
                                                                    label={"Soggetto originatore *"}
                                                                    placeholder={"Selezionare il soggetto originatore"}
                                                                    options={optionSO}
                                                                    isClearable={true}
                                                                    id={"id_soggetto_originatore"}
                                                                    isSearchable={false}
                                                                    value={
                                                                        this.getFormFieldValue(
                                                                            "id_soggetto_originatore"
                                                                        ) || ""
                                                                    }
                                                                    onChange={this.handleSelect}
                                                                    disabled={wFormDisabled}
                                                                />
                                                            </FormControl>
                                                        </GridItem>
                                                    </GridContainer>
                                                </GridItem>
                                                {fileListSection && (
                                                    <GridItem xs={12} sm={12} style={{ minHeight: "15vh" }}>
                                                        {fileListSection}
                                                    </GridItem>
                                                )}
                                                <GridItem xs={12} sm={12} style={{ minHeight: "200px" }}>
                                                    {wUploadSection}
                                                </GridItem>
                                                <GridItem
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    style={{
                                                        marginTop: "10px",
                                                    }}
                                                >
                                                    <span className={classes.tc_sign_required_div}>
                                                        <i>* Campi obbligatori</i>
                                                    </span>
                                                </GridItem>
                                            </GridContainer>
                                        ),
                                    },
                                ]}
                            />
                        </CardBody>
                    </Card>
                </DialogContent>
                <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
                    {wButtonSalva}
                    <Button
                        color="info"
                        onClick={() => this.handleClose()}
                        disabled={!id || (id && documenti.current) ? false : true}
                    >
                        <Close style={{ margin: "0px" }} /> Chiudi
                    </Button>
                </DialogActions>
                {signatureAlert && (
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Si"
                        confirmBtnBsStyle="danger"
                        cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
                        cancelBtnText="No"
                        title="Sei sicuro di voler chiudere?"
                        onConfirm={(e) => {
                            this.hideSignatureAlert();
                            this.close();
                        }}
                        onCancel={() => {
                            this.hideSignatureAlert();
                        }}
                    >
                        <p style={{ textAlign: "justify", margin: "0 1em" }}>{signatureAlert}</p>
                    </SweetAlert>
                )}
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    const { alert, authentication, documenti, utils, raccoglitori, posizionamentoFirma, error, tema, users } = state;
    const { user } = authentication ?? {};

    return {
        user,
        alert,
        documenti,
        utils,
        raccoglitori,
        posizionamentoFirma,
        error,
        tema,
        users,
    };
}

const connectedDocumentoModalForm = connect(mapStateToProps)(withSnackbar(withStyles(styles)(DocumentoModalForm)));
export default connectedDocumentoModalForm;
