import React from "react";
import ReactTable from "react-table";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import swal from "@sweetalert/with-react";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
// @material-ui/core
import { Tooltip, withStyles } from "@material-ui/core";

// @material-ui/icons
import { Search, FolderOpen, FileCopy, AccountBalance, InfoOutlined, Folder } from "@material-ui/icons";
import Document from "@material-ui/icons/InsertDriveFile";
import Delete from "@material-ui/icons/DeleteForever";
import Play from "@material-ui/icons/PlayCircleOutline";
import Euro from "@material-ui/icons/EuroSymbolOutlined";
import File from "@material-ui/icons/ImportContacts";
import Download from "@material-ui/icons/CloudDownload";
import Abort from "@material-ui/icons/Cancel";
import Abort2 from "@material-ui/icons/ThumbDown";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import { ModelViewHelper, Constants, Authorize } from "Helpers/model-view.js";
import { documentiActions } from "../../Actions";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

const styles = {
    ...extendedFormsStyle,
    tc_sign_btn_confirm: {
        "background-color": "#4caf50 !important",
    },
    tcsign_action: {
        width: "20px",
        minWidth: "20px",
    },
    tcsign_spesa: {
        width: "20px",
        minWidth: "20px",
        cursor: "default",
    },
};

class ListaDocumenti extends React.Component {
    state = {
        idUtente: JSON.parse(localStorage.getItem("user")).id_utente,
        alert: undefined,
        sweetAlertTitle: undefined,
        sweetAlertConfirm: undefined,
    };

    selectRow = (e, index) => {
        this.setState({
            selected: index,
        });
    };

    handleClickOpenFileNote(note_singolo_doc) {
        let elencoNote = "",
            singolaNota = "";
        var lung = note_singolo_doc.length;

        for (var i = 0; i < lung; i++) {
            var dataCreazione = note_singolo_doc[i].data_creazione;
            var dataCreazioneFormattata = moment(dataCreazione).format("DD/MM/YYYY HH:mm");
            singolaNota =
                "Autore: " +
                note_singolo_doc[i].utente +
                "\n Data e ora: " +
                dataCreazioneFormattata +
                "\n Testo: " +
                note_singolo_doc[i].descrizione +
                "\n \n";
            elencoNote = elencoNote + singolaNota;
        }

        swal({
            animation: "slide-from-top",
            title: "Note del documento",
            overflow: "hidden",
            text: elencoNote,
            input: "text",
            type: "textarea",
            style: { display: "block", marginTop: "-100px" },
            height: "100px",
            inputType: "textarea",
            confirmButtonText: "Cool",
            showConfirmButton: false,
            showCancelButton: true,
        });
    }

    handleClickStartProcess = (idDocumento) => {
        const { user } = this.props;
        this.setState({
            alert: true,
            sweetAlertTitle: "Inviare il documento al validatore?",
            sweetAlertConfirm: () => {
                this.hideAlert();
                let azione = {
                    id_azione: 1,
                    id_documento: idDocumento,
                };
                this.props.dispatch(documentiActions.eseguiAzione(azione, undefined, undefined, user.ruoli));
            },
        });
    };

    handleClickDeleteDocumento = (idDocumento) => {
        const { user } = this.props;
        this.setState({
            alert: true,
            sweetAlertTitle: "Vuoi cancellare il documento?",
            sweetAlertConfirm: () => {
                this.hideAlert();
                this.props.dispatch(documentiActions.deleteById(idDocumento, user.ruoli));
            },
        });
    };

    handleClickCopiaDocumento = (idDocumento) => {
        var _this = this;
        this.setState({
            alert: true,
            sweetAlertTitle: "Vuoi copiare il documento?",
            sweetAlertConfirm: () => {
                this.hideAlert();
                this.props.dispatch(documentiActions.copiaDocumento(idDocumento));

                setTimeout(function () {
                    if (_this.props.documenti?.copyDoc)
                        _this.props.handleClickOpenDocumento(_this.props.documenti.copyDoc);
                }, 2000);
            },
        });
    };

    handleClickActionRefuse = (documento) => {
        const { user } = this.props;
        this.setState({
            alert: true,
            sweetAlertTitle: "Vuoi annullare il percorso del documento?",
            sweetAlertConfirm: () => {
                this.hideAlert();
                let azione = {
                    id_azione: 6,
                    id_documento: documento.id,
                };
                this.props.dispatch(documentiActions.eseguiAzione(azione, undefined, undefined, user.ruoli));
            },
        });
    };

    hideAlert() {
        this.setState({
            alert: undefined,
            sweetAlertTitle: undefined,
            sweetAlertConfirm: undefined,
        });
    }

    handleFileDownload = (id, documento) => {
        this.props.dispatch(documentiActions.download(id, documento));
    };

    handleClickActionForceRefuse = (documento) => {
        const { user } = this.props;
        this.setState({
            confirm: (
                <SweetAlert
                    input
                    inputType="textarea"
                    showCancel
                    allowEscape
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Prego inserire una nota per il rifiuto del documento."
                    onConfirm={(e) => {
                        let rifiutaDocumento = {
                            id_azione: 5,
                            id_documento: [documento.id],
                            nota_rifiuto: e,
                        };
                        this.hideConfirm();
                        this.props.closeFileViewer();
                        this.props.dispatch(documentiActions.rifiutaDocumento(rifiutaDocumento, user.ruoli));
                    }}
                    onCancel={() => this.hideConfirm()}
                    validationMsg="Digitare il commento nel campo di testo prima di confermare."
                    confirmBtnText="Salva"
                    cancelBtnText="Chiudi"
                    confirmBtnCssClass={this.props.classes.tc_sign_btn_confirm}
                    cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
                />
            ),
        });
    };

    hideConfirm = () => {
        this.setState({
            confirm: undefined,
        });
    };
    render() {
        const { selected, alert, confirm, sweetAlertTitle, sweetAlertConfirm } = this.state;
        const { classes, documenti, search, readOnly, handleClickOpenFile, temaColor, user, validatedDocumentsMsg } =
            this.props;
        const { handleClickOpenDocumento, handleClickOpenRaccoglitore } = this.props;
        const { inBozza, rifiutato, sospeso } = Constants.documento.stato;
        const { ruoli } = user;
        const { ambito } = search;

        let listaDocumenti = documenti.items ?? documenti.tasks ?? [];
        let validatoreRule = false;
        let operatoreRaiRule = false;

        if (ruoli.filter((rl) => rl.cod_ruolo === "VALID").length > 0) validatoreRule = true;
        else if (ruoli.filter((rl) => rl.cod_ruolo === "OPERAI").length > 0) operatoreRaiRule = true;

        const btnList = [
            {
                onClick: handleClickOpenDocumento,
                icon: <Document style={{ margin: "0px" }} />,
                title: "Nuovo documento",
                show: Authorize.checkAuthorizeFunc("FNCCMD"),
            },
            {
                onClick: handleClickOpenRaccoglitore,
                icon: <Folder style={{ margin: "0px" }} />,
                title: "Nuovo Percorso Firma",
                show: Authorize.checkAuthorizeFunc("FNCCMP"),
            },
        ];

        let columns = [
            {
                Header: "",
                accessor: "spesa_icon",
                maxWidth: 30,
                sortable: false,
                filterable: false,
            },
            {
                Header: "",
                accessor: "note_icon",
                maxWidth: 30,
                sortable: false,
                filterable: false,
            },
            {
                Header: "",
                accessor: "proposta_icon",
                maxWidth: 30,
                sortable: false,
                filterable: false,
            },
            {
                Header: "Oggetto",
                accessor: "descrizione",
                filterMethod: (filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase()),
            },
            {
                Header: "Origine",
                accessor: "soggetto_originatore",
                maxWidth: 170,
                filterMethod: (filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase()),
            },
            {
                Header: "Tipologia",
                accessor: "desc_tipo_documento",
                maxWidth: 170,
                filterMethod: (filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase()),
            },
            {
                Header: "Data documento",
                accessor: "data_documento",
                sortMethod: (a, b) => {
                    // force null and undefined to the bottom
                    a = a === null || a === undefined ? -Infinity : a;
                    b = b === null || b === undefined ? -Infinity : b;

                    let aMoment = moment(a.props.children);

                    // Return either 1 or -1 to indicate a sort priority
                    if (aMoment.isAfter(b.props.children)) {
                        return 1;
                    }
                    if (aMoment.isBefore(b.props.children)) {
                        return -1;
                    }
                    // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
                    return 0;
                },
                maxWidth: 200,
            },
            {
                Header: "Azioni",
                accessor: "actions",
                sortable: false,
                filterable: false,
                maxWidth: 220,
            },
        ];

        if (!operatoreRaiRule) {
            const lastIndex = columns.length - 1;
            columns.splice(lastIndex, 0, {
                Header: "Stato",
                accessor: "desc_stato_documento",
                maxWidth: 130,
                filterMethod: (filter, row) => {
                    if (filter.value === "all") return true;

                    return row[filter.id] === filter.value;
                },
                Cell: (row) => (
                    <span
                        style={{
                            color: row.value === "Rifiutato" ? "#ff0000" : "rgba(0, 0, 0, 0.87)",
                            fontWeight: row.value === "Rifiutato" ? "bold" : "300",
                            transition: "all .3s ease",
                        }}
                    >
                        {row.value}
                    </span>
                ),
                Filter: ({ filter, onChange }) => (
                    <select
                        onChange={(event) => onChange(event.target.value)}
                        style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBottom: "2px",
                            color: "#555",
                        }}
                        value={filter ? filter.value : "all"}
                    >
                        <option value="all">Tutti</option>
                        <option value="In bozza">In bozza</option>
                        <option value="In lavorazione">In lavorazione</option>
                        <option value="Rifiutato">Rifiutato</option>
                        <option value="Completato">Completato</option>
                        <option value="Cancellato">Cancellato</option>
                    </select>
                ),
            });
            columns.splice(lastIndex, 0, {
                Header: "Data creazione",
                accessor: "data_creazione",
                sortMethod: (a, b) => {
                    // force null and undefined to the bottom
                    a = a === null || a === undefined ? -Infinity : a;
                    b = b === null || b === undefined ? -Infinity : b;

                    let aMoment = moment(a.props.children);

                    // Return either 1 or -1 to indicate a sort priority
                    if (aMoment.isAfter(b.props.children)) {
                        return 1;
                    }
                    if (aMoment.isBefore(b.props.children)) {
                        return -1;
                    }
                    // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
                    return 0;
                },
                maxWidth: 200,
            });
        }

        return (
            <GridItem xs={12}>
                {confirm}
                {alert && (
                    <SweetAlert
                        info
                        showCancel
                        confirmBtnText="Ok"
                        confirmBtnCssClass={this.props.classes.tc_sign_btn_confirm}
                        cancelBtnText="Annulla"
                        cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
                        title={sweetAlertTitle}
                        onConfirm={sweetAlertConfirm}
                        onCancel={() => {
                            this.hideAlert();
                        }}
                    ></SweetAlert>
                )}
                <Card>
                    <CardHeader color={temaColor} icon>
                        <CardIcon color={temaColor}>
                            <Document />
                        </CardIcon>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <h4 style={{ color: "#3C4858", fontWeight: 300 }}>Documenti</h4>
                            {validatoreRule && ambito.name === "Signed" && (
                                <p style={{ color: "#3C4858", margin: 0, fontWeight: "bold" }}>
                                    {validatedDocumentsMsg}
                                </p>
                            )}
                        </div>

                        {btnList.map((btn) => {
                            const { show, onClick, title, icon } = btn;
                            return (
                                show && (
                                    <Button
                                        color={temaColor}
                                        size="sm"
                                        style={{ float: "right" }}
                                        onClick={() => onClick()}
                                        key={title}
                                    >
                                        {icon} {title}
                                    </Button>
                                )
                            );
                        })}
                    </CardHeader>
                    <CardBody>
                        <ReactTable
                            getTheadThProps={(state, rowInfo) => {
                                return {
                                    style: {
                                        fontWeight: "bold",
                                        fontSize: "1.23rem",
                                    },
                                };
                            }}
                            getTrProps={(state, rowInfo) => {
                                if (rowInfo?.row) {
                                    return {
                                        onClick: (e) => this.selectRow(e, rowInfo.index),
                                        style: {
                                            background: rowInfo.index === selected ? "#e8ffdf" : null,
                                            border: rowInfo.index === selected ? "1px solid #59ff59" : null,
                                        },
                                    };
                                } else return {};
                            }}
                            data={listaDocumenti.map((doc) => {
                                let wReturn = ModelViewHelper.document_ModelToView(doc);
                                const { tag_posizionato_est, tag_posizionato_int, file_name } = wReturn;

                                //checks per attivare il button Avvia Processo
                                let wButtonAvviaDisabled = true;

                                //Avvia processo = true - cases:

                                // estANDint => percorso misto con tags int e est tutti posizionati
                                const estANDint = tag_posizionato_est && tag_posizionato_int;

                                // estANDintNULL => percorso esterno con tags esterni posizionati
                                const estANDintNULL = tag_posizionato_est && tag_posizionato_int === null;

                                // intANDestNULL => percorso interno con tutti gli interni posizionati
                                const intANDestNULL = tag_posizionato_int && tag_posizionato_est === null;

                                // intNULLestNULL => percorso interno e nessun tag posizionato
                                // il percorso interno è l'unico ad avere due casi per attivare un processo
                                const intNULLestNULL = tag_posizionato_int === null && tag_posizionato_est === null;

                                const tagPosizionatiCheck =
                                    estANDint || intNULLestNULL || estANDintNULL || intANDestNULL;

                                if (
                                    ([inBozza, sospeso].includes(doc.id_stato_documento) ||
                                        ([rifiutato].includes(doc.id_stato_documento) &&
                                            user?.riavvioRifiuto === "true")) &&
                                    file_name &&
                                    tagPosizionatiCheck
                                ) {
                                    wButtonAvviaDisabled = false;
                                }

                                const otherPropsBtn = [
                                    {
                                        prop: "spesa_icon",
                                        show: wReturn.impegno_spesa,
                                        title: "Presenza impegno di spesa",
                                        btnColor: "rose",
                                        btnDisabled: false,
                                        onClick: null,
                                        icon: <Euro />,
                                    },
                                    {
                                        prop: "proposta_icon",
                                        show: wReturn.proposta_inviata === "inviata",
                                        title: "Proposta inviata a concilium",
                                        btnColor: "info",
                                        btnDisabled: false,
                                        onClick: null,
                                        icon: <AccountBalance />,
                                    },
                                    {
                                        prop: "note_icon",
                                        show: wReturn?.note?.length > 0,
                                        title: "Note del documento",
                                        btnColor: "success",
                                        btnDisabled: false,
                                        onClick: () => this.handleClickOpenFileNote(wReturn.note),
                                        icon: <InfoOutlined />,
                                    },
                                ];

                                const actionsBtnList = [
                                    {
                                        show: readOnly && Authorize.checkAuthorizeFunc("FNCAFF"),
                                        title: "Avvia il processo di validazione",
                                        btnColor: "info",
                                        btnDisabled: wButtonAvviaDisabled,
                                        onClick: () => this.handleClickStartProcess(wReturn.id),
                                        icon: <Play />,
                                    },
                                    {
                                        show: Authorize.checkAuthorizeFunc("FNCVWD"),
                                        title: "Visualizza il documento",
                                        btnColor: "success",
                                        btnDisabled: file_name ? false : true,
                                        onClick: () => handleClickOpenFile(wReturn),
                                        icon: <File />,
                                    },
                                    {
                                        show: Authorize.checkAuthorizeFunc("FNCDWL"),
                                        title: "Scarica il documento",
                                        btnColor: "rose",
                                        btnDisabled: file_name ? false : true,
                                        onClick: () => this.handleFileDownload(wReturn.uuid, wReturn),
                                        icon: <Download />,
                                    },
                                    {
                                        show: Authorize.checkAuthorizeFunc("FNCDDC"),
                                        title: "Apri la scheda del documento",
                                        btnColor: "success",
                                        btnDisabled: [inBozza, sospeso].includes(doc.id_stato_documento) ? false : true,
                                        onClick: () => handleClickOpenDocumento(wReturn.id, wReturn.uuid),
                                        icon: <Search />,
                                    },
                                    {
                                        show: readOnly && Authorize.checkAuthorizeFunc("FNCCDC"),
                                        title: "Copia documento",
                                        btnColor: "primary",
                                        btnDisabled: false,
                                        onClick: () => this.handleClickCopiaDocumento(wReturn.id),
                                        icon: <FileCopy />,
                                    },
                                    {
                                        show: Authorize.checkAuthorizeFunc("FNCAPF"),
                                        title: "Apri il perc. firma del documento",
                                        btnColor: "warning",
                                        btnDisabled: false,
                                        onClick: () => handleClickOpenRaccoglitore(wReturn.id_raccoglitore),
                                        icon: <FolderOpen />,
                                    },
                                    {
                                        show: readOnly && Authorize.checkAuthorizeFunc("FNCEPR"),
                                        title: "Annulla processo",
                                        btnColor: "danger",
                                        btnDisabled: doc.id_stato_documento === rifiutato && file_name ? false : true,
                                        onClick: () => this.handleClickActionRefuse(wReturn),
                                        icon: <Abort />,
                                    },
                                    {
                                        show: readOnly && Authorize.checkAuthorizeFunc("FNCEDC"),
                                        title: "Elimina il documento",
                                        btnColor: "danger",
                                        btnDisabled: [inBozza, sospeso].includes(doc.id_stato_documento) ? false : true,
                                        onClick: () => this.handleClickDeleteDocumento(wReturn.id),
                                        icon: <Delete />,
                                    },
                                ];

                                wReturn.actions = (
                                    <div className="actions-center">
                                        {actionsBtnList.map((btn) => {
                                            const { title, show, btnColor, btnDisabled, onClick, icon } = btn;
                                            return (
                                                show && (
                                                    <Tooltip
                                                        key={uuidv4()}
                                                        id="tooltip-top"
                                                        title={title}
                                                        placement="bottom"
                                                        classes={{ tooltip: classes.tooltip }}
                                                    >
                                                        <Button
                                                            justIcon
                                                            round
                                                            simple
                                                            disabled={btnDisabled}
                                                            onClick={onClick}
                                                            color={btnColor}
                                                            className={classes.tcsign_action}
                                                        >
                                                            {icon}
                                                        </Button>
                                                    </Tooltip>
                                                )
                                            );
                                        })}
                                    </div>
                                );

                                {
                                    otherPropsBtn.map((btn) => {
                                        const { title, show, btnColor, btnDisabled, onClick, icon, prop } = btn;
                                        wReturn[prop] = show ? (
                                            <Tooltip
                                                id="tooltip-top"
                                                title={title}
                                                placement="bottom"
                                                classes={{ tooltip: classes.tooltip }}
                                            >
                                                <Button
                                                    justIcon
                                                    round
                                                    simple
                                                    disabled={btnDisabled}
                                                    onClick={onClick}
                                                    color={btnColor}
                                                    className={classes[onClick ? "tcsign_action" : "tcsign_spesa"]}
                                                >
                                                    {icon}
                                                </Button>
                                            </Tooltip>
                                        ) : (
                                            ""
                                        );
                                    });
                                }

                                return wReturn;
                            })}
                            filterable
                            columns={columns}
                            defaultPageSize={50}
                            showPaginationTop={false}
                            showPaginationBottom
                            className="-striped -highlight"
                            striped
                            hover
                            condensed
                            pagination
                            insertRow
                            deleteRow
                            search
                            previousText="Precedente"
                            nextText="Successiva"
                            loadingText="In caricamento..."
                            noDataText="Nessun documento trovato"
                            pageText="Pagina"
                            ofText="di"
                            rowsText="righe"
                        />
                    </CardBody>
                </Card>
            </GridItem>
        );
    }
}

function mapStateToProps(state) {
    const { documenti, authentication, search } = state;
    return {
        user: authentication?.user ?? {},
        documenti,
        search,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(ListaDocumenti));
