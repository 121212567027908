import React from 'react';
import { connect } from 'react-redux';
import { Document, Page } from 'react-pdf';
import TagPositioning from './TagPositioning';
import DocumentNavigator from './DocumentNavigator';
import _ from 'lodash';

//material core
import { Tabs, Tab, Tooltip, withStyles, FormControl } from '@material-ui/core';

//components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import SelectSearch from 'Custom/SelectSearch.jsx';

//assets
import { primaryColor } from 'assets/jss/material-dashboard-pro-react';
import { posFirmaActions } from '../../Actions';
import { areArraysOfObjectsEqual, adaptCoordToCurrentPDF } from '../../Helpers';

const styles = {
    pdfWrapper: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        marginTop: '1em',
    },
    selezioneEModificaContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
    },
    selectTab: {
        borderRadius: '5px',
        minHeight: '30px',
        minWidth: '150px',
        fontSize: '0.6rem',
    },
    indicator: {
        backgroundColor: primaryColor,
    },
};

const initialState = {
    activeTab: 0,
    numPages: null,
    pageNumber: 1,
    showNav: false,
    firmatario: {},
    tags: [],
    tagsFetched: false,
    dimensions: {},
};

class PosizionamentoFirma extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.pdfCanvasRef = React.createRef();
    }

    componentDidMount = () => {
        const { currentTab, listaFirmatariInterni } = this.props;
        if (['Firma', 'ViewerFirma', 'Percorsi di firma'].includes(currentTab)) {
            let currentFirm = listaFirmatariInterni[0];

            let firmatario = {
                label: currentFirm.label,
            };

            currentFirm.tipo_firmatario === 'Interno'
                ? (firmatario.id_utente = currentFirm.id)
                : (firmatario.id_uo = currentFirm.id);

            this.setState({
                firmatario,
            });
        }
    };

    componentDidUpdate(prevProps, prevState) {
        const { tagsFetched, tags, dimensions } = this.state;
        const { documenti, raccoglitori } = this.props;
        const prevCoord = prevProps.documenti?.current?.coordinate;
        const currentCoord = documenti?.current?.coordinate;
        const prevFlusso = prevProps.documenti?.current?.flusso_operativo;
        const currentFlusso = documenti?.current?.flusso_operativo;

        //reset tags when raccoglitore changes
        if (prevProps.raccoglitori?.current?.id_raccoglitore !== raccoglitori?.current?.id_raccoglitore)
            this.setState({ tags: [] });

        //update tagsFetched value for parents
        if (prevState.tagsFetched !== tagsFetched)
            this.props.dispatch(posFirmaActions.setPosizionamentoFirmaTagsFetched(tagsFetched));

        //percorso modificato in fase di lavorazione --> aggiorna coord
        if (documenti.current && tagsFetched) {
            if (prevFlusso?.length !== 0 && prevFlusso?.length !== currentFlusso?.length) {
                const coordinate = adaptCoordToCurrentPDF(documenti, dimensions);
                this.setState({
                    tags: coordinate,
                });
            }
        }

        //update state when props change
        const coordsChanged = prevCoord && currentCoord && !areArraysOfObjectsEqual(prevCoord, currentCoord);

        if (coordsChanged && tagsFetched) {
            const coordinate = adaptCoordToCurrentPDF(documenti, dimensions);
            this.setState({
                tags: coordinate,
            });
        }

        //update props to pass to parent when state changes
        if (!areArraysOfObjectsEqual(prevState.tags, tags)) {
            this.props.dispatch(posFirmaActions.setPosizionamentoFirmaTags(tags));
        }
    }

    static getDerivedStateFromProps = (props, state) => {
        const { documenti, raccoglitori } = props;

        //PDF FILE REGEX
        let fileUrlReg = '[^/]+$';
        let uuid = props?.file?.url.match(fileUrlReg)[0];

        //if main pdf has changed reset tags
        if (documenti?.current?.files.length > 0 && documenti?.current?.files[0].uuid !== uuid) {
            state.tags = [];
            state.tagsFetched = false;
        }

        if (
            documenti.current &&
            raccoglitori.current &&
            state.dimensions.CANVAS_WIDTH &&
            state.tags.length === 0 &&
            !state.tagsFetched
        ) {
            const coordinate = adaptCoordToCurrentPDF(documenti, state.dimensions);
            props.dispatch(posFirmaActions.setPosizionamentoFirmaTags(coordinate));
            return { ...state, tags: coordinate, tagsFetched: true };
        }
        return state;
    };

    shouldComponentUpdate = (propsSucc, stateSucc) => {
        if (_.isEqual(this.state, stateSucc) && _.isEqual(this.props, propsSucc)) return false;

        return true;
    };

    setListaFirmatariTab = (event, value) => {
        this.setState({
            activeTab: value,
            firmatario: {},
        });
    };

    onDocumentLoadSuccess(pdf) {
        this.setState({ numPages: pdf.numPages, showNav: true });
    }

    updateTags = (tags) => {
        const newTags = [...tags];
        this.setState({ tags: newTags });
    };

    eliminaFirme = () => {
        this.setState({ tags: [] });
    };

    getSize = (page) => {
        const PDFView = page._pageInfo.view;
        const PDF_WIDTH = PDFView[2];
        const PDF_HEIGHT = PDFView[3];
        const canvas = document.getElementsByTagName('canvas')[0];
        const CANVAS_WIDTH = canvas.getBoundingClientRect().width;
        const CANVAS_HEIGHT = canvas.getBoundingClientRect().height;

        this.setState({
            dimensions: { CANVAS_WIDTH, CANVAS_HEIGHT, PDF_WIDTH, PDF_HEIGHT },
        });
        const dimensions = {
            CANVAS_WIDTH,
            CANVAS_HEIGHT,
            PDF_WIDTH,
            PDF_HEIGHT,
        };

        this.props.dispatch(posFirmaActions.setPosizionamentoFirmaDimensions(dimensions));
    };

    handleSelect = (event) => {
        let { label, id, tipo_firmatario } = event.target;

        let firmatario = {};

        if (label) {
            firmatario = { label };
            if (tipo_firmatario === 'Esterno') firmatario.id_contatto = id;
            else if (tipo_firmatario === 'Interno') firmatario.id_utente = id;
            else if (tipo_firmatario === 'InternoUO') firmatario.id_uo = id;
        }

        this.setState({
            firmatario,
        });
    };

    goToPrevPage = (pageNumber) => {
        if (pageNumber && pageNumber > 1) this.setState((state) => ({ pageNumber: state.pageNumber - 1 }));
    };

    goToNextPage = (pageNumber, numPages) => {
        if (numPages && numPages !== pageNumber) this.setState((state) => ({ pageNumber: state.pageNumber + 1 }));
    };

    setPage = (pageNumber) => {
        this.setState({ pageNumber });
    };

    navigateDocs = (index, listaDoc, prevOrNext) => {
        this.props.handleClickOpenOtherFile(index, listaDoc, prevOrNext);
        this.props.dispatch(posFirmaActions.resetPosizionamentoFirma());
        this.setState({ pageNumber: 1, numPages: null });
    };

    render() {
        const { classes, documenti, listaFirmatariEsterni, listaFirmatariInterni, file, currentTab } = this.props;
        const { activeTab, pageNumber, numPages, tags, showNav, firmatario, dimensions, tagsFetched } = this.state;
        const { CANVAS_WIDTH, CANVAS_HEIGHT } = dimensions;

        const currentFlusso = documenti?.current?.flusso_operativo.filter((flusso) => flusso.current_step === 'S')[0];

        const firmatariOptions = [];
        let tagPagina = null;

        /* lista stati documento in cui è possibile effettuare una modifica
        /* 1 = In bozza
        /* 3 = rifiutato (disabilitare temporanemanete)
        /* 6 = sostituito */
        const idStatoModificaAllowed = [1, 6]; //3

        const showTabInterni = listaFirmatariInterni.length > 0 ? true : false;
        const showTabEsterni = listaFirmatariEsterni.length > 0 ? true : false;

        if (showTabInterni) firmatariOptions.push({ name: 'Firmatari interni', value: listaFirmatariInterni });
        if (showTabEsterni) firmatariOptions.push({ name: 'Firmatari Esterni', value: listaFirmatariEsterni });

        const statoDocumento = documenti?.current?.id_stato_documento;

        if (statoDocumento === 2 && ['Firma', 'ViewerFirma'].includes(currentTab) && firmatario && tagsFetched) {
            const { id_utente, id_uo } = firmatario;
            tagPagina = id_utente
                ? tags.filter((tag) => tag.id_utente === id_utente)[0]?.pagina
                : tags.filter((tag) => !tag.id_utente && tag.id_uo === id_uo)[0]?.pagina;
        }

        const showModificationComponent =
            idStatoModificaAllowed.includes(statoDocumento) ||
            (statoDocumento === 2 && ['Firma', 'ViewerFirma', 'Percorsi di firma'].includes(currentTab))
                ? true
                : false;

        let btnReset = statoDocumento === 1 && (
            <Tooltip id="tooltip-top" title="Elimina firme" placement="bottom">
                <Button color="danger" size="sm" onClick={this.eliminaFirme} style={{ marginLeft: '10px' }}>
                    Elimina firme
                </Button>
            </Tooltip>
        );

        let numDocs = null;
        let index = null;
        let listaDoc = null;

        if (documenti?.current?.id_documento && currentTab === 'ViewerFirma') {
            let idLocal = documenti.current.id_documento;

            listaDoc = documenti.tasks;
            numDocs = documenti.tasks.length;
            index = documenti.tasks.map((e) => e.id_documento).indexOf(idLocal);
        }

        return (
            <GridContainer style={{ width: '100%', margin: 'auto' }} justify="center">
                {showModificationComponent && (
                    <GridContainer className={classes.selezioneEModificaContainer}>
                        <GridItem xs={3} sm={3} md={3}>
                            <FormControl
                                fullWidth
                                className={`${classes.selectFormControl} ${classes.tcSignSelectControl}`}>
                                <Tabs
                                    value={activeTab}
                                    onChange={this.setListaFirmatariTab}
                                    classes={{ indicator: classes.indicator }}>
                                    {firmatariOptions.map(
                                        (firmatari, index) =>
                                            firmatari.value && (
                                                <Tab
                                                    key={index}
                                                    classes={{ root: classes.selectTab }}
                                                    label={firmatari.name}
                                                />
                                            )
                                    )}
                                </Tabs>
                                {firmatariOptions.map((listaFirmatari, index) => {
                                    return (
                                        <TabPanel key={index} value={activeTab} index={index}>
                                            <SelectSearch
                                                id="firmatario"
                                                placeholder="Selezionare un firmatario"
                                                options={listaFirmatari.value}
                                                isClearable={true}
                                                isSearchable={true}
                                                value={firmatario.label}
                                                onChange={this.handleSelect}
                                                currentTab={currentTab}
                                            />
                                        </TabPanel>
                                    );
                                })}
                            </FormControl>
                        </GridItem>
                        <GridItem xs={9} sm={9} md={9} style={{ marginTop: '25px' }}>
                            {btnReset}
                        </GridItem>
                    </GridContainer>
                )}
                <GridContainer style={{ width: '100%' }} direction="column">
                    <GridItem xs={12} sm={12} md={12}>
                        {showNav && (
                            <DocumentNavigator
                                pageNumber={pageNumber}
                                numPages={numPages}
                                goToPrevPage={this.goToPrevPage}
                                goToNextPage={this.goToNextPage}
                                tagPagina={tagPagina}
                                setTagPagina={(page) => this.setPage(page)}
                                listaDoc={listaDoc}
                                numDocs={numDocs}
                                index={index}
                                navigateDocs={this.navigateDocs}
                            />
                        )}
                        <div className={classes.pdfWrapper}>
                            <div
                                ref={this.pdfCanvasRef}
                                style={{
                                    position: 'relative',
                                    width: CANVAS_WIDTH ?? '100%',
                                    height: CANVAS_HEIGHT ?? '100%',
                                }}>
                                <Document
                                    file={file}
                                    loading={<div>Caricamento PDF in corso...</div>}
                                    onLoadSuccess={(pdf) => {
                                        this.onDocumentLoadSuccess(pdf);
                                    }}>
                                    <Page
                                        className="pdf_viewer"
                                        pageNumber={pageNumber}
                                        onLoadSuccess={(page) => this.getSize(page)}
                                        width={1024}
                                    />
                                </Document>
                                {CANVAS_WIDTH && CANVAS_HEIGHT ? (
                                    <TagPositioning
                                        tags={tags}
                                        pagina={pageNumber}
                                        currentTab={currentTab}
                                        currentFlusso={currentFlusso}
                                        CANVAS_WIDTH={CANVAS_WIDTH}
                                        CANVAS_HEIGHT={CANVAS_HEIGHT}
                                        firmatario={firmatario}
                                        updateTags={this.updateTags}
                                        statoDocumento={statoDocumento}
                                        listaFirmatariInterni={listaFirmatariInterni}
                                        canvasRef={this.pdfCanvasRef}
                                    />
                                ) : null}
                            </div>
                        </div>
                        {showNav && (
                            <DocumentNavigator
                                pageNumber={pageNumber}
                                numPages={numPages}
                                navPosition="bottom"
                                goToPrevPage={this.goToPrevPage}
                                goToNextPage={this.goToNextPage}
                                listaDoc={listaDoc}
                                numDocs={numDocs}
                                index={index}
                                navigateDocs={this.navigateDocs}
                            />
                        )}
                    </GridItem>
                </GridContainer>
            </GridContainer>
        );
    }
}

function mapStateToProps(state) {
    const { alert, documenti, raccoglitori, error } = state;

    return {
        alert,
        documenti,
        raccoglitori,
        error,
    };
}

const connectedPosizionamentoFirma = connect(mapStateToProps)(withStyles(styles)(PosizionamentoFirma));

export default connectedPosizionamentoFirma;

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} {...other} style={{ width: '100%' }}>
            {value === index && children}
        </div>
    );
}
