import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/TCSignHeader.jsx";
import Footer from "components/Footer/TCSignFooter.jsx";
import Sidebar from "components/Sidebar/TCSignSidebar.jsx";

import dashboardRoutes from "routes/TCSignDashboard.jsx";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx";

import { compose } from "recompose";
import withUnderMaintenance from "../Helpers/withUnderMaintenance";
import { ThemeUtils } from "Helpers/themeUtils.js";
import { temaActions } from "Actions";

const switchRoutes = (
    <Switch>
        {dashboardRoutes.map((prop, key) => {
            if (prop.redirect) return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
            if (prop.collapse)
                return prop.views.map((prop, key) => {
                    return <Route path={prop.path} component={prop.component} key={key} />;
                });
            return <Route path={prop.path} component={prop.component} key={key} />;
        })}
    </Switch>
);

var ps;

class Dashboard extends React.Component {
    state = {
        mobileOpen: false,
        miniActive: false,
    };
    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    };
    getRoute() {
        return this.props.location.pathname !== "/maps/full-screen-maps";
    }

    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.refs.mainPanel, {
                suppressScrollX: true,
                suppressScrollY: false,
            });
            document.body.style.overflow = "hidden";
        }
        let user = JSON.parse(localStorage.getItem("user"));
        if (user) {
            this.props.dispatch(temaActions.getTemaTenant(user.company.tenant_code));
        }
    }
    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
    }
    componentDidUpdate(e) {
        if (e.history.location.pathname !== e.location.pathname) {
            this.refs.mainPanel.scrollTop = 0;
            if (this.state.mobileOpen) {
                this.setState({ mobileOpen: false });
            }
        }
    }
    sidebarMinimize() {
        this.setState({ miniActive: !this.state.miniActive });
    }
    render() {
        const { classes, tema, ...rest } = this.props;
        const mainPanel =
            classes.mainPanel +
            " " +
            cx({
                [classes.mainPanelSidebarMini]: this.state.miniActive,
                [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1,
            });
        let tenantTheme = tema ? tema.tenant_theme : null;
        let logo = undefined;
        let image;
        if (tenantTheme) {
            logo = ThemeUtils.getPngSrc(ThemeUtils.getThemeImage(tenantTheme, "logo_png_1", "img"));
            image = ThemeUtils.getJpegSrc(ThemeUtils.getThemeImage(tenantTheme, "background_sidebar_jpg", "img"));
            image = image.replace(/(\r\n|\n|\r)/gm, "");
        }
        let user = JSON.parse(localStorage.getItem("user"));
        let ruolo = user.ruoli.filter((rl) => rl.cod_ruolo === 'VALID');
        let switchRoutesDef = switchRoutes;
        if(ruolo.length > 0)
        switchRoutesDef = (
                <Switch>
                    {dashboardRoutes.map((prop, key) => {
                        if (prop.redirect) return <Redirect from={prop.path} to={'/auth/dashboard'} key={key} />;
                        if (prop.collapse)
                            return prop.views.map((prop, key) => {
                                return <Route path={prop.path} component={prop.component} key={key} />;
                            });
                        return <Route path={prop.path} component={prop.component} key={key} />;
                    })}
                </Switch>
            );

        return (
            <div className={classes.wrapper}>
                <Sidebar
                    routes={dashboardRoutes}
                    logoText={"Libro Firma"}
                    logo={logo}
                    image={image}
                    handleDrawerToggle={this.handleDrawerToggle}
                    open={this.state.mobileOpen}
                    color="white"
                    bgColor="black"
                    miniActive={this.state.miniActive}
                    {...rest}
                />
                <div className={mainPanel} ref="mainPanel">
                    <Header
                        sidebarMinimize={this.sidebarMinimize.bind(this)}
                        miniActive={this.state.miniActive}
                        routes={dashboardRoutes}
                        handleDrawerToggle={this.handleDrawerToggle}
                        {...rest}
                    />
                    {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
                    {this.getRoute() ? (
                        <div className={classes.content}>
                            <div className={classes.container}>{switchRoutesDef}</div>
                        </div>
                    ) : (
                        <div className={classes.map}>{switchRoutesDef}</div>
                    )}
                    {this.getRoute() ? <Footer fluid /> : null}
                </div>
            </div>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(withUnderMaintenance, withStyles(appStyle))(Dashboard);
